import React, { useRef, useEffect, useCallback, useState } from 'react';
import styles from './index.module.scss';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import Icon from 'components/Icon';
import resizeEvent from 'element-resize-event';
import FabricCanvas from 'components/Canvas/FabricCanvas';
import BookContainer from 'components/BookContainer';
import DiscussionPanel from 'components/SideToolContents/DiscussionPanel';
import SideToolContainer from 'components/SideToolContainer';
import TableContentsMenu from 'components/TableContentsMenu';
import PageSearcher from 'components/PageSearcher';
import Bookmarks from 'components/Bookmarks';
import Sidebar from 'components/Sidebar';
import MusicModal from 'components/common/MusicPlayer/MusicModal';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { BookFlipType } from 'constants/flipTypes';
import { SideBarType } from 'constants/ReaderTools';
import { SideToolContent, SideToolDirection } from 'constants/sideToolContents';
import { useReadAnnotations } from 'customHooks/db';
import { useReaderStrategyDecider } from 'customHooks/Strategies/ReaderStrategies';

import { EventBus } from 'events/EventBus';
import { ReaderEvent, ReaderToolsEvent } from 'events/EventTypes';
import DrawArea from 'components/DrawArea';
import MarkTools from 'components/MarkTools';
import ContextMenu from 'components/ContextMenu';
import useContextMenu from 'customHooks/contextMenu';
import toolsEventMap from 'components/ReaderTools/toolsEventMap';
import { ReaderToolType } from 'constants/ReaderTools';

import Repository from 'repositories/Repository';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GA);
ReactGA.pageview(window.location.pathname + window.location.search);

const { ExtendedResourceRepository } = Repository;

// const bookmarks = ['課本', '段落講解', '詞語選單', '生字表', '習作', '隨堂演練', '教學資源', '數位強打'];

const contentMenuOption = [
  ReaderToolType.Drag,
  ReaderToolType.Select,
  ReaderToolType.Painting,
  ReaderToolType.Highlighter,
  ReaderToolType.DeleteAll,
  ReaderToolType.Delete
];

const ReaderView = props => {
  const bookId = props.match.params.bookId;

  const [
    {
      isDoublePageMode,
      isPageSearcherShow,
      isLeftBarShow,
      isRightBarShow,
      isBookmarkShow,
      isDrawArea,
      fullWidthInfo: { scale: fullWidthScale },
      isMarkModeShow,
      readerToolType
    },
    readerDispatch
  ] = useStore(StoreTypes.reader);
  const [{ annotationId }, annotationDispatch] = useStore(
    StoreTypes.annotation
  );
  const [{ style, books }, bookDispatch] = useStore(StoreTypes.books);
  const [{ sideToolContent, sideToolDirection }] = useStore(
    StoreTypes.sideTool
  );

  const { readAnnotationById } = useReadAnnotations();
  const decider = useReaderStrategyDecider();
  const strategy = decider.getReaderStrategy();

  const containerEl = useRef();
  const resizeTimerRef = useRef();
  const contextMenuRef = useRef();

  const [isMenuVisible] = useContextMenu(contextMenuRef);

  const book = books.find(book => book.bookId === bookId);
  const { pageInfos, LRFlip } = book || {};

  const [bookmarkData, setBookmarkData] = useState([]);

  useEffect(() => {
    const fetchBookmarkData = async () => {
      const bookmarkJson = await ExtendedResourceRepository.getJSONContent({
        bookId,
        pathName: 'bookmark'
      });
      setBookmarkData(bookmarkJson ? bookmarkJson.data : []);
    };
    fetchBookmarkData();
  }, [bookId]);

  const toggleSideBarHandler = useCallback(
    sideBarType => e => {
      EventBus.emit({
        event: ReaderToolsEvent.ToggleSideBarEvent,
        payload: { sideBarType }
      });
    },
    []
  );

  const togglePageSearcherHandler = useCallback(
    () => e => {
      EventBus.emit({
        event: ReaderToolsEvent.TogglePageSearcherEvent
      });
    },
    []
  );


  const updateBookSize = useCallback(() => {
    const container = containerEl.current;
    const payload = {
      width: container.clientWidth,
      height: container.clientHeight
    };
    bookDispatch({
      type: types.SET_BOOK_STYLES,
      payload
    });
  }, [bookDispatch]);

  useEffect(() => {
    updateBookSize();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      const { width, height } = style;
      if (!annotationId || width === null || height === null) return;
      const result = await readAnnotationById({ id: annotationId });
      if (result) {
        EventBus.emit({
          event: ReaderEvent.RefreshCanvasEvent,
          payload: {
            result: result,
            size: style
          }
        });

        readerDispatch({
          type: types.SET_BOOK_PAGE_INDEX,
          pageIndex: isDoublePageMode ? Math.floor(result.pageIndex / 2) : result.pageIndex
        });

        annotationDispatch({
          type: types.UPDATE_MARK_OBJECTS,
          marks: result.marks
        });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotationDispatch, annotationId, readAnnotationById, readerDispatch, style]);

  const handleResize = useCallback(() => {
    clearTimeout(resizeTimerRef.current);
    resizeTimerRef.current = setTimeout(updateBookSize, 1000);
  }, [updateBookSize]);

  useEffect(() => {
    const container = containerEl.current;
    resizeEvent(container, handleResize);
    return () => {
      resizeEvent.unbind(container);
    };
  }, [handleResize]);

  const prevPage = useCallback(() => {
    EventBus.emit({
      event: ReaderEvent.ClickPreviousPageEvent,
      payload: { convertToSVG: true }
    });
  }, []);

  const nextPage = useCallback(() => {
    EventBus.emit({
      event: ReaderEvent.ClickNextPageEvent,
      payload: { pageInfos, convertToSVG: true }
    });
  }, [pageInfos]);

  const selectText = e => {
    var txt = '';
    if (window.getSelection) {
      txt = window.getSelection();
      if (txt.rangeCount > 0) {
        if (txt.toString().length > 0) {
          var range = txt.getRangeAt(0);
          var props = range.getBoundingClientRect();
          var markObject = { text: txt.getRangeAt(0).toString() };
          for (var prop in props) {
            markObject[prop] = props[prop];
          }
          EventBus.emit({
            event: ReaderToolsEvent.SelectMarkEvent,
            payload: { markObject }
          });
          document.execCommand('Copy');
        }
      }
    } else if (document.getSelection) {
      txt = document.getSelection();
    } else if (document.selection) {
      txt = document.selection.createRange().text;
    } else {
      return;
    }
  };

  return (
    <div
      className={classnames(
        styles.readerView,
        styles[props.className],
        styles[`cursor${readerToolType}`]
      )}
      onMouseUp={e => isMarkModeShow && selectText(e)}
    >
      {isDrawArea && <DrawArea />}
      <Sidebar
        horizontal
        isShow={isPageSearcherShow}
        floatDirection="bottom"
        isWithMask={true}
        onMaskClick={togglePageSearcherHandler()}
      >
        {isPageSearcherShow && <PageSearcher />}
      </Sidebar>
      <Sidebar
        vertical
        isShow={isLeftBarShow}
        floatDirection="left"
        isWithMask={true}
        onMaskClick={toggleSideBarHandler(SideBarType.LEFT)}
      >
        {isLeftBarShow && <TableContentsMenu />}
      </Sidebar>
      <div
        className={styles.bookContainer}
        ref={containerEl}
        style={{ transform: `scaleX(${fullWidthScale})` }}
      // {...bindTrigger}
      >
        <BookContainer book={book} />
        <FabricCanvas
          id="FabricCanvas"
          width={style.width}
          height={style.height}
          isDrawingMode={false}
          className={styles.canvasWrapper}
        />
        <MusicModal />
      </div>

      {strategy && strategy.isReaderSwitchPageAvailable() && (
        <div>
          <span
            className={classnames(styles.ctrlButton, styles.left)}
            onClick={
              LRFlip === BookFlipType.LEFT_TO_RIGHT ? prevPage : nextPage
            }
          >
            <Icon type="text" name="angleLeft" />
          </span>
          <span
            className={classnames(styles.ctrlButton, styles.right)}
            onClick={
              LRFlip === BookFlipType.LEFT_TO_RIGHT ? nextPage : prevPage
            }
          >
            <Icon type="text" name="angleRight" />
          </span>
        </div>
      )}

      <Bookmarks show={isBookmarkShow} data={bookmarkData} />
      {isMarkModeShow && <MarkTools />}
      <Sidebar
        vertical
        isShow={sideToolContent !== SideToolContent.None}
        floatDirection={sideToolDirection}
      >
        {SideToolDirection[sideToolDirection]}
        <SideToolContainer />
      </Sidebar>

      <Sidebar vertical isShow={isRightBarShow} isFloat={false}>
        <DiscussionPanel />
      </Sidebar>
      <ContextMenu.Box ref={contextMenuRef} isVisible={isMenuVisible}>
        {contentMenuOption.map(item => (
          <ContextMenu.Item
            onClick={() => {
              toolsEventMap[item].clickHandler();
            }}
          >
            {toolsEventMap[item].title}
          </ContextMenu.Item>
        ))}
      </ContextMenu.Box>
    </div>
  );
};

export default withRouter(ReaderView);
