import React, { useCallback } from 'react';
import Input from 'components/common/Input';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import { InteractiveObjectContentType, InteractiveObjectControlStageType } from 'constants/interactiveObjectContentTypes';
import { LayoutType, LayoutDisplayName } from 'components/ExtendedContent/ExtendedContentContainer/layout';
import { VideoComponent } from 'components/Tools/PropertyForm/VideoPropertyForm';
import { AreaZoomComponent } from 'components/Tools/PropertyForm/AreaZoomPropertyForm';
import { InternalHtmlWindowComponent } from 'components/Tools/PropertyForm/InternalHtmlWindowComponent';
import { WordGridComponent } from 'components/Tools/PropertyForm/WordGridPropertyForm';
import { SubmenuPropertyForm } from 'components/Tools/PropertyForm/SubmenuPropertyForm';
import { WordExampleComponent } from 'components/Tools/PropertyForm/WordExamplePropertyForm';
import { VocabularyComponent } from 'components/Tools/PropertyForm/VocabularyPropertyForm';
import { SynopsisComponent } from 'components/Tools/PropertyForm/SynopsisPropertyForm';

export const ContentTypeDisplayMap = {
    [InteractiveObjectContentType.ExternalWindow]: '外開連結',
    [InteractiveObjectContentType.InternalWindow]: '內開視窗',
    [InteractiveObjectContentType.ToggleDisplay]: '蓋答案',
    [InteractiveObjectContentType.ControlDisplay]: '全解',
    [InteractiveObjectContentType.Stage]: '步驟編號',
    [InteractiveObjectContentType.ControlStage]: '步驟解',
    [InteractiveObjectContentType.Image]: '外開連結',
    [InteractiveObjectContentType.Image]: '圖片',
    [InteractiveObjectContentType.ControlImage]: '換圖',
    [InteractiveObjectContentType.GoPage]: '跳頁',
    [InteractiveObjectContentType.Video]: '影片',
    [InteractiveObjectContentType.Audio]: '音檔',
    [InteractiveObjectContentType.AreaZoom]: '區域放大',
    [InteractiveObjectContentType.InternalHtmlWindow]: 'HTML內開視窗',
    [InteractiveObjectContentType.Vocabulary]: '單字例句',
    [InteractiveObjectContentType.WordGrid]: '習字格',
    [InteractiveObjectContentType.Submenu]: '次選單',
    [InteractiveObjectContentType.WordExample]: '語詞選單',
    [InteractiveObjectContentType.Synopsis]: '文章大意',
};

const StyleComponent = ({ object, inputTextHandler }) => {
    return (<div>
        <label htmlFor='style'>Style</label>
        <select id='style' value={object.style || ''} onChange={(e) => inputTextHandler('style', e.target.value)}>
            {!object.controlStage && <option key={-1} value=''>---</option>}
            {Object.values(LayoutType).map((type, i) => <option key={i} value={type}>{LayoutDisplayName[type]}</option>)}
        </select>
    </div>);
};

const ControlStageComponent = ({ object, inputTextHandler }) => {
    return (<div>
        <label htmlFor='controlStage'>ControlStage</label>
        <select id='controlStage' value={object.controlStage || ''} onChange={(e) => inputTextHandler('controlStage', e.target.value)}>
            {!object.controlStage && <option key={-1} value=''>---</option>}
            {Object.values(InteractiveObjectControlStageType).map((type, i) => <option key={i} value={type}>{type}</option>)}
        </select>
        <br />
        <label htmlFor='revealStage'>逐步顯示</label>
        <input id='revealStage' type='checkbox' checked={object.revealStage || false} onChange={e => inputTextHandler('revealStage', e.target.checked)} />
    </div>);
};

const OpacityComponent = ({ object, inputTextHandler }) => {
    return (<div>
        <label htmlFor='opacity'>Opacity</label>
        <select id='opacity' value={object.opacity} onChange={(e) => inputTextHandler('opacity', parseInt(e.target.value))}>
            <option key={0} value={0}>Hide</option>
            <option key={1} value={1}>Show</option>
        </select>
    </div>);
};

const AdjustLineAngleComponent = ({ object }) => {
    return (<div>
        <label>線段角度</label>
        <br />
        <input type='button' value='垂直' onClick={e => EventBus.emit({ event: CanvasEvent.AdjustCanvasLineAngleEvent, payload: { object, angle: 180 } })} />
        <input type='button' value='水平' onClick={e => EventBus.emit({ event: CanvasEvent.AdjustCanvasLineAngleEvent, payload: { object, angle: 90 } })} />
        <br />
    </div>)
};


const ControlObjectWhiteList = [
    InteractiveObjectContentType.ControlDisplay, 
    InteractiveObjectContentType.ControlStage, 
    InteractiveObjectContentType.ControlImage, 
    InteractiveObjectContentType.AreaZoom
];

const sourceBlackList = [
    InteractiveObjectContentType.Video, 
    InteractiveObjectContentType.InternalHtmlWindow,
    InteractiveObjectContentType.WordGrid,
    InteractiveObjectContentType.WordExample,
    InteractiveObjectContentType.Synopsis
];

const PropertyForm = ({ className }) => {
    const [{ activeCanvasObject: object }] = useStore(StoreTypes.canvas);
    const inputTextHandler = useCallback((property, value) => {
        EventBus.emit({ event: CanvasEvent.ModifyCanvasObjectPropertyEvent, payload: { object, property, value } });
    }, [object]);

    return (
        <div className={classnames(styles.propertyForm, className)}>
            <Input label="Id" value={object.id} disabled />
            <StyleComponent object={object} inputTextHandler={inputTextHandler} />
            {!sourceBlackList.includes(object.contentType) && <Input label="Src" value={object.src} onChange={(e) => inputTextHandler('src', e.target.value.trim())} />}
            <label htmlFor='contentType'>ContentType</label>
            <select
                id='contentType'
                value={object.contentType || ''}
                onChange={(e) => inputTextHandler('contentType', e.target.value)}
            >
                {!object.contentType && <option key={-1} value=''>---</option>}
                {Object.values(InteractiveObjectContentType).map((type, i) => <option key={i} value={type}>{ContentTypeDisplayMap[type]}</option>)}
            </select>
            {ControlObjectWhiteList.includes(object.contentType) && <Input label="ControlObject" value={object.controlObject} onChange={(e) => inputTextHandler('controlObject', e.target.value)} />}
            {object.contentType === InteractiveObjectContentType.ControlStage && <ControlStageComponent object={object} inputTextHandler={inputTextHandler} />}
            {object.contentType === InteractiveObjectContentType.Stage && <Input label="Stage" value={object.stage} onChange={(e) => inputTextHandler('stage', e.target.value)} />}
            {<OpacityComponent object={object} inputTextHandler={inputTextHandler} />}
            {object.contentType === InteractiveObjectContentType.GoPage && <Input type="number" label="頁數" value={object.pageIndex} min="1" onChange={(e) => inputTextHandler('pageIndex', e.target.value)} />}
            {object.contentType === InteractiveObjectContentType.Video && <VideoComponent object={object} />}
            {object.contentType === InteractiveObjectContentType.AreaZoom && <AreaZoomComponent object={object} />}
            {object.contentType === InteractiveObjectContentType.InternalHtmlWindow && <InternalHtmlWindowComponent object={object} />}
            {object.contentType === InteractiveObjectContentType.WordGrid && <WordGridComponent object={object} />}
            {object.contentType === InteractiveObjectContentType.WordExample && <WordExampleComponent  object={object} />}
            {object.contentType === InteractiveObjectContentType.Submenu && <SubmenuPropertyForm object={object} />}
            {object.contentType === InteractiveObjectContentType.Vocabulary && <VocabularyComponent object={object} />}
            {object.type === 'line' && <AdjustLineAngleComponent object={object} />}
            {object.contentType === InteractiveObjectContentType.Synopsis && <SynopsisComponent object={object} />}
            {object.contentType === InteractiveObjectContentType.Audio && <Input label="字幕" value={object.audioInfo} onChange={(e) => inputTextHandler('audioInfo', e.target.value)} />}
        </div>
    )
};


export default PropertyForm;
