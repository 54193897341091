import React from 'react';
import styles from "./index.module.scss";
import FillColorSelector from 'components/Tools/FillColorSelector';

export const ExtendedContentButton = props => {
    const html = props.el.props.html ? props.el.props.html : false
    const synopsisInfo = props.el.props.synopsisInfo ? props.el.props.synopsisInfo : false
    let template = ""

    if(html){
        template = props.el.props.html;
    }

    const hasClass = (el, cls) => {
        return (' ' + el.className + ' ').indexOf(' ' + cls + ' ') > -1;
    }

    const toggleShow = id => () => {
        const el = document.getElementById(id);
        if (el) {
            el.classList.toggle("show");
        }
    }

    const classToggleShow = () => {
        // 取得DOM上所有的ans class
        var cls = document.querySelectorAll('.fill .ans');
       
        // 所有被取得的class同一做事
        for (let i = 0; i < cls.length; ++i) {
            let hasNext = hasClass(cls[i], "next")
            if(hasNext === true){
                cls[i].classList.remove("show")
                cls[i].classList.remove("next")
            }else{
                cls[i].classList.add("show")
                cls[i].classList.add("next")
            }
        }
    }

    const fontSizeChange = size => () => {
        let el = [];
        const topic = document.getElementsByClassName("topic");
        const wordSection = document.getElementsByClassName("word_section");
        const plainText = document.getElementsByClassName("plain_text");
        const fill = document.getElementsByClassName("fill");

        // 判斷從哪個題型進來
        if (topic.length > 0) {
            el = topic;
        } else if (wordSection.length > 0) {
            el = wordSection;
        } else if (plainText.length > 0) {
            el = plainText;
        } else if (fill.length > 0) {
            el = fill;
        }

        for (let i = 0; i < el.length; i++) {
            if (el) {
                // 移除 class
                if (hasClass(el[i], "fontLarge")) {
                    el[i].classList.remove("fontLarge");
                } else if (hasClass(el[i], "fontMedium")) {
                    el[i].classList.remove("fontMedium");
                } else if (hasClass(el[i], "fontSmall")) {
                    el[i].classList.remove("fontSmall");
                }

                // 根據 size 加入class
                // eslint-disable-next-line default-case
                switch (size) {
                    case "L":
                        el[i].classList.add("fontLarge");
                        break;
                    case "M":
                        el[i].classList.add("fontMedium");
                        break;
                    case "S":
                        el[i].classList.add("fontSmall");
                        break;
                }

            }
        }


    }

    return (
        <>
            {
                html || synopsisInfo ? (
                    <div className={styles.extendedContentButton}>
                        <div className={styles.left}>
                            <div className={styles.button} onClick={fontSizeChange("L")}>大</div>
                            <div className={styles.button} onClick={fontSizeChange("M")}>中</div>
                            <div className={styles.button} onClick={fontSizeChange("S")}>小</div>
                        </div>
                        <div className={styles.right}>
                            {template.indexOf("='fill'") > -1 ? <div className={styles.button} onClick={classToggleShow}>解答</div> : ""}
                            {template.indexOf("='answer'") > -1 ? <div className={styles.button} onClick={toggleShow("answer")}>答案</div> : ""}
                            {template.indexOf("analyze") > -1 ? <div className={styles.button} onClick={toggleShow("analyze")}>解析</div> : ""}
                        </div> 
                    </div>
                )
                : <></>
            }
        </>
    )
}
