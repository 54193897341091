import { useState, useCallback, useRef } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import Repository from 'repositories/Repository';
import { Roles } from 'constants/role';

const { BookContentRepository } = Repository;

function FetchBookCommand(book, pages) {
    this.book = book;
    this.pages = pages;

    this.execute = async function(includingInteractiveObjects) {
        const result = {};
        if (includingInteractiveObjects && this.book.interactiveObjectId) {
            result.interactiveObject = await BookContentRepository.getInteractiveObjects({ interactiveObjectId: this.book.interactiveObjectId, pages: this.pages });
        }
        result.bookContent = await BookContentRepository.getBookContent({ book: this.book, pages: this.pages });
        return result;
    }
}

export const useFetchBookCommandDispatcher = () => {
    const [{ role }] = useStore(StoreTypes.user);
    const [{ books, bookId }, bookDispatch] = useStore(StoreTypes.books);
    const queue = useRef([]);
    const fetching = useRef(false);
    const book = books.find(book => book.bookId === bookId);
    const [includingInteractiveObjects] = useState([Roles.TEACHER, Roles.STUDENT, Roles.GUEST].includes(role));

    const composeCommand = useCallback(() => {
        if (queue.current.length > 0) {
            return new FetchBookCommand(book, queue.current.slice(0, 10));
        } else {
            return null;
        }
    }, [book]);

    const dispatch = useCallback(async () => {
        const command = composeCommand();
        if (command) {
            fetching.current = true;
            const result = await command.execute(includingInteractiveObjects);
            bookDispatch({ type: types.SET_INTERACTIVE_OBJECTS, ...result.interactiveObject });
            bookDispatch({
                type: types.SET_BOOK_CONTENT,
                bookContent: result.bookContent.reduce((acc, page) => {
                    acc[page.pageIndex] = page;
                    return acc;
                }, {})
            });
            queue.current = queue.current.filter(page => !command.pages.includes(page));
            dispatch();
        } else {
            fetching.current = false;
        }
    }, [bookDispatch, composeCommand, includingInteractiveObjects]);

    const fetch = useCallback((pages) => {
        if (book) {
            queue.current = [...new Set(queue.current.concat(pages.filter(page => page < book.pageInfos.length) || []))];
            if (!fetching.current && queue.current.length > 0) {
                dispatch();
            }
        }
    }, [book, dispatch]);

    return { fetch };
};
