import React, { useRef, useEffect } from 'react';
import MathJax from 'react-mathjax2';
import { ExtendedContentButton } from 'components/ExtendedContent/ExtendedContentButton';
import { ExtendedContentCanvas } from 'components/ExtendedContent/ExtendedContentCanvas';
import {
  ExtendedContentTools,
  ExtendedContentContextMenu
} from 'components/ExtendedContent/ExtendedContentTools';
import { EventBusType } from 'events/EventBus';
import { ExtendedContentEvent } from '../ExtendedContentEvent';
import './content.scss';
import styles from './index.module.scss';
import { Layout } from './layout';

import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

const eventBusType = EventBusType.ExtendedContent;

export const ExtendedContentContainer = ({
  children,
  extendedResourceStyle
}) => {
  const contentView = useRef();
  const [
    { canvasState, canvasHeight, canvasWidth, isContentInit },
    canvasDispatch
  ] = ExtendedContentEvent(contentView);

  const direction = useRef(null);
  const popWinStyle = children.props.extendedResourceStyle;

  useEffect(() => {
    if (popWinStyle === 'WordSection') {
      direction.current.style.direction = 'rtl';
    }
  }, [direction, popWinStyle]);

  // 取得作業系統
  const getOSAndBrowser = function() {
    var os = navigator.platform;
    var userAgent = navigator.userAgent;
    var info = '';
    //判斷作業系統
    if (os.indexOf('Win') > -1) {
      if (userAgent.indexOf('Windows NT 5.0') > -1) {
        info += 'Win2000';
      } else if (userAgent.indexOf('Windows NT 5.1') > -1) {
        info += 'WinXP';
      } else if (userAgent.indexOf('Windows NT 5.2') > -1) {
        info += 'Win2003';
      } else if (userAgent.indexOf('Windows NT 6.0') > -1) {
        info += 'WindowsVista';
      } else if (
        userAgent.indexOf('Windows NT 6.1') > -1 ||
        userAgent.indexOf('Windows 7') > -1
      ) {
        info += 'Win7';
      } else if (
        userAgent.indexOf('Windows NT 6.2') > -1 ||
        userAgent.indexOf('Windows 8') > -1
      ) {
        info += 'Win8';
      } else if (
        userAgent.indexOf('Windows NT 6.3') > -1 ||
        userAgent.indexOf('Windows 8.1') > -1
      ) {
        info += 'Win8.1';
      } else if (
        userAgent.indexOf('Windows NT 10.0') > -1 ||
        userAgent.indexOf('Windows 10') > -1
      ) {
        info += 'Win10';
      } else {
        info += 'Other';
      }
    } else if (os.indexOf('Mac') > -1) {
      info += 'Mac';
    } else if (os.indexOf('X11') > -1) {
      info += 'Unix';
    } else if (os.indexOf('Linux') > -1) {
      info += 'Linux';
    } else {
      info += 'Other';
    }

    return info;
  };

  return (
    <div
      id="exContainer"
      className={styles.extendedContentContainer}
      ref={direction}
    >
      <link
        rel="stylesheet"
        type="text/css"
        href={Layout[extendedResourceStyle]}
      />
      {/* 判斷選用樣式“直書”並且作業系統為ＭＡＣ載入此樣式 */}
      {extendedResourceStyle === 'WordSection' &&
        getOSAndBrowser() === 'Mac' ? (
          <link
            rel="stylesheet"
            type="text/css"
            href="layout/wordSection/wordSectionForMac.css"
          />
        ) : (
          ''
        )}
      <div ref={contentView} className={styles.contentView}>
        <MathJax.Context script={`${ExtendedResourceRepository.getMathJaxPath()}?config=TeX-MML-AM_CHTML`}>
          <MathJax.Text text={children} />
        </MathJax.Context>
        {isContentInit && (
          <ExtendedContentCanvas
            {...{ canvasState, canvasDispatch, canvasWidth, canvasHeight }}
          />
        )}
      </div>
      <ExtendedContentButton el={children} />
      <ExtendedContentTools {...{ eventBusType, canvasState }} />
      <ExtendedContentContextMenu {...{ eventBusType, canvasState }} />
    </div>
  );
};
