export const IExtendedResourceRepository = {
  getVideoSrc: ({ bookId, filename }) => {
    console.log(`getVideoSrc is not implemented`);
  },
  getAudioSrc: ({ bookId, filename }) => {
    console.log(`getAudioSrc is not implemented`);
  },
  getSubtitleSrc: ({ bookId, filename }) => {
    console.log(`getSubtitleSrc is not implemented`);
  },
  getInternalHtmlSrc: ({ bookId, filename }) => {
    console.log(`getInternalHtmlSrc is not implemented`);
  },
  getDataSrc: ({ bookId, pathName }) => {
    console.log(`getDataSrc is not implemented`);
  },
  getInternalWindowHtml: async ({ bookId, filename }) => {
    console.log(`getInternalWindowHtml is not implemented`);
  },
  getJSONContent: async ({ bookId, pathName }) => {
    console.log(`getJSONContent is not implemented`);
  },
  getMathJaxPath: () => {
    console.log(`getMathJaxPath is not implemented`);
  },
};
