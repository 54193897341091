import React from 'react';
import PlaybackRateControl from '../PlaybackRateControl';
import classnames from 'classnames';
import styles from './index.module.scss';

const WordsVideoControl = ({
  videoState,
  playButtonClick,
  previousButtonClick,
  previousButtonDiabled,
  nextButtonClick,
  nextButtonDiabled,
  repeatButtonClick,
  repeatDisabled,
  playbackRate,
  onChangePlaybackRate
}) => {
  return (
    <>
      <PlaybackRateControl
        onChange={onChangePlaybackRate}
        value={playbackRate}
      />
      <div className={styles.buttonGroup}>
        <button onClick={playButtonClick}>
          {videoState && videoState.isPlaying ? '暫停' : '播放'}
        </button>
        <button onClick={previousButtonClick} disabled={previousButtonDiabled}>
          上一筆
        </button>
        <button onClick={repeatButtonClick} disabled={repeatDisabled}>
          單筆重複
        </button>
        <button onClick={nextButtonClick} disabled={nextButtonDiabled}>
          下一筆
        </button>
      </div>
    </>
  );
};

export default WordsVideoControl;
