import React from 'react';
import { useStore, StoreTypes } from 'context';
import Icon from 'components/Icon';
import * as types from 'constants/actionTypes';
import MusicPlayer from '../index';
import styles from './index.module.scss';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

export const useMusicModal = () => {
  const [{ musicModal }, globalDispatch] = useStore(StoreTypes.global);

  const openModal = ({ src, audioInfo, fixed, x, y }) => {
    globalDispatch({
      type: types.OPEN_MUSIC_MODAL,
      payload: { src, audioInfo, fixed, x, y }
    });
  };

  const closeModal = () => {
    globalDispatch({
      type: types.CLOSE_MUSIC_MODAL
    });
  };

  return [musicModal, { openModal, closeModal }];
};

const MusicModal = () => {
  const [{ isShowing, src, audioInfo }, { closeModal }] = useMusicModal();
  const [{ bookId }] = useStore(StoreTypes.books);
  return isShowing ? (
    <div className={styles.musicModal}>
      <div className={styles.close} onClick={closeModal}>
        <Icon type="text" name="timesCircle" />
      </div>
      <MusicPlayer
        src={ExtendedResourceRepository.getAudioSrc({ bookId, filename: src })}
        subtitles={[{ language: "en", src: ExtendedResourceRepository.getAudioVTTSrc({ bookId, audioInfo }) }]}
      />
    </div>
  ) : null;
};

export default MusicModal;
