import React, { useEffect } from 'react';
import useSetState from 'customHooks/setState';
import { useStore, StoreTypes } from 'context';
import Repository from 'repositories/Repository';
import styles from './index.module.scss';
import classnames from 'classnames';
import { Script } from 'components/ExtendedContent/ExtendedContentContainer/layout';
import { scriptLoader } from 'util/scriptLoader'

const { ExtendedResourceRepository } = Repository;
const SynopsisWindow = ({ synopsisInfo, extendedResourceStyle }) => {
    const { tab, list } = synopsisInfo;

    const [{ bookId }] = useStore(StoreTypes.books);

    const [{ synopsisList, tabIndex }, setState] = useSetState({
        synopsisList: [],
        tabIndex: tab - 1
    });

    useEffect(() => {
        Promise.all(list.map(lists => ExtendedResourceRepository.getJSONContent({ bookId, pathName: lists.src }))).then((obj) => {
            setState({ synopsisList: obj.filter(item => item) });
        })
    }, [bookId, list, setState]);

    useEffect(()=>{
        if(synopsisList[tabIndex]){
            scriptLoader(Script[extendedResourceStyle])
        }
    },[extendedResourceStyle,synopsisList,tabIndex])

    return (
        <div className={styles.synopsisWindow}>
            <div className={styles.tab_box}>
                { list && list.map((obj, i) => <div className={classnames(styles.tab,{
                    [styles.active]:tabIndex === i
                })} key={i} onClick={()=>{setState({ tabIndex: i })}}>{obj.name}</div>) }
            </div>
            <div className={styles.list}>
                {
                    // synopsisList.map((obj, index) => <div key={index} dangerouslySetInnerHTML={{ __html: obj.html }} />)
                    synopsisList[tabIndex] && <div dangerouslySetInnerHTML={{ __html: synopsisList[tabIndex].html }} />
                }
            </div>
        </div>
    )
}

export default SynopsisWindow;