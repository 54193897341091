import React, { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Button from 'components/Button';
import { useStore, StoreTypes } from 'context';
import styles from './index.module.scss';
import * as types from 'constants/actionTypes';
import { useCreateMarkRect } from 'customHooks/canvas';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { PainterMode } from 'constants/painterModes';
import { short_uuid } from 'util/uuid';
import { Roles } from 'constants/role';

const MarkTools = () => {
    const [{ role }] = useStore(StoreTypes.user);
    const [{ isMarkToolsShow, pageIndex }, readerDispatch] = useStore(StoreTypes.reader);
    const [{ markObject }] = useStore(StoreTypes.annotation);
    const [{ painterToolType }] = useStore(StoreTypes.canvas);
    const markTools = useRef(null);

    const createMarkRect = useCreateMarkRect();
    const toolBtnClick = useCallback((type) => {
        switch (type) {
            case "搜尋":
                window.open("http://www.google.com/search?q=" + markObject.text)
                break;
            case "複製":

                break;
            case "標記":
                const markId = short_uuid();
                EventBus.emit({ event: ReaderToolsEvent.ClickSelectEvent, payload: { painterMode: PainterMode.Selection, painterToolType } });
                EventBus.emit({
                    event: ReaderToolsEvent.SaveMarkEvent,
                    payload: { markObject, markId, pageIndex }
                })
                createMarkRect(markObject, markId);
                break;
            case "發音":
                //window.speak(marks.text)
                break;
            case "翻譯":
                var event = document.createEvent("Event");
                event.initEvent("TRANSLATE_WORDS", false, true);
                document.dispatchEvent(event);
                break;
            default:
                break;
        }
        readerDispatch({
            type: types.SET_MARK_TOOLS_SHOW,
            isMarkToolsShow: false
        })
        readerDispatch({
            type: types.SET_MARK_MODE,
            isMarkModeShow: false
        })
    }, [createMarkRect, markObject, pageIndex, painterToolType, readerDispatch])

    useEffect(() => {
        const markToolsDom = ReactDOM.findDOMNode(markTools.current)
        if (markToolsDom) {
            markToolsDom.style.top = (markObject.y + markObject.height) + "px";
            markToolsDom.style.left = (markObject.x) + "px";
            markToolsDom.style.opacity = 1;
        }
    }, [markObject.height, markObject.width, markObject.x, markObject.y])

    return (
        <div>
            {
                isMarkToolsShow &&
                <div>
                    <ul ref={markTools} className={styles.markTools} >
                        <li>
                            <Button
                                onClick={(e) => {
                                    toolBtnClick("搜尋")
                                }}
                                title="搜尋"
                            >
                                搜尋
                            </Button>
                        </li>
                        <li>
                            <Button
                                onClick={(e) => {
                                    toolBtnClick("複製")
                                }}
                                title="複製"
                            >
                                複製
                            </Button>
                        </li>
                        {
                        (role === Roles.TEACHER) &&<li>
                            <Button
                                onClick={(e) => {
                                    toolBtnClick("標記")
                                }}
                                title="標記"
                            >
                                標記
                            </Button>
                        </li>
                        }
                    </ul>
                </div>
            }
        </div>
    );
};

export default MarkTools;
