import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

const floatDirectionType = {
  right: 'right',
  left: 'left',
  top: 'top',
  bottom: 'bottom'
};

const Sidebar = ({
  children,
  isShow,
  isFloat = true,
  floatDirection = '',
  isWithMask = false,
  vertical,
  horizontal,
  onMaskClick = () => { }
}) => {
  const direction =
    floatDirectionType[floatDirection] || floatDirectionType.right;
  return (
    <>
      <div
        className={classnames(styles.sidebar, {
          [styles.vertical]: vertical,
          [styles.horizontal]: horizontal,
          [styles.show]: isShow,
          [styles.covered]: !isFloat,
          [styles.float]: isFloat,
          [styles[direction]]: isFloat,
          [styles.isWithMask]: isWithMask
        })}
      >
        {children}
      </div>
      {isWithMask && isShow && (
        <div className={styles.mask} onClick={onMaskClick}></div>
      )}
    </>
  );
};

export default Sidebar;
