import React, { createContext, useContext } from 'react';
import useCanvasReducer from '../reducers/canvasReducer';
import useReaderReducer from '../reducers/readerReducer';
import useBooksReducer from 'reducers/booksReducer';
import useDBReducer from 'reducers/dbReducer';
import useGlobalReducer from 'reducers/globalReducer';
import useUserReducer from 'reducers/userReducer';
import useSideToolReducer from 'reducers/sideToolReducer';
import useAnnotationReducer from 'reducers/annotationReducer';

const Context = createContext();

export const StoreTypes = {
  global: 'global',
  books: 'books',
  canvas: 'canvas',
  toolbar: 'toolbar',
  reader: 'reader',
  user: 'user',
  db: 'db',
  sideTool: 'sideTool',
  router: 'router',
  annotation: 'annotation',
};

export const useStore = store => {
  const reducers = useContext(Context);
  if (!store) return reducers;
  if (!Object.keys(StoreTypes).includes(store)) {
    throw new Error(`Store doesn't contain ${store}`);
  }
  return reducers[store];
};

const Provider = ({ children, router }) => {
  const reducers = {
    [StoreTypes.global]: useGlobalReducer(),
    [StoreTypes.books]: useBooksReducer(),
    [StoreTypes.canvas]: useCanvasReducer(),
    [StoreTypes.reader]: useReaderReducer(),
    [StoreTypes.user]: useUserReducer(),
    [StoreTypes.db]: useDBReducer(),
    [StoreTypes.sideTool]: useSideToolReducer(),
    [StoreTypes.annotation]: useAnnotationReducer(),
    [StoreTypes.router]: router,
  };

  return (
    <Context.Provider value={{ ...reducers }}>{children}</Context.Provider>
  );
};

export default Provider;
