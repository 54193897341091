export const InteractiveObjectContentType = {
    ExternalWindow: 'ExternalWindow',
    InternalWindow: 'InternalWindow',
    InternalHtmlWindow: 'InternalHtmlWindow',
    ToggleDisplay: 'ToggleDisplay',
    ControlDisplay: 'ControlDisplay',
    Stage: 'Stage',
    ControlStage: 'ControlStage',
    Image: 'Image',
    ControlImage: 'ControlImage',
    GoPage: 'GoPage',
    Video: 'Video',
    Audio: 'Audio',
    AreaZoom: 'AreaZoom',
    Vocabulary: 'Vocabulary',
    WordGrid: 'WordGrid',
    Submenu: 'Submenu',
    WordExample: 'WordExample',
    NumberPicker: 'NumberPicker',
    Synopsis: 'Synopsis',
    Whiteboard: 'Whiteboard',
};

export const InteractiveObjectControlStageType = {
    Previous: 'Previous',
    Next: 'Next',
    All: 'All',
};
