import React from 'react';
import { useStore, StoreTypes } from 'context';
import classnames from 'classnames';
import styles from './index.module.scss';

import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

const RadicalInfoBar = ({
  radical,
  radicalNumber,
  allNumber,
  restNumber,
  onRadicalClick
}) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  return (
    <div className={styles.radicalInfoBar}>
      <div className={classnames(styles.wrapper, styles.left)}>
        <div className={classnames(styles.radicalBox, styles.orange)}>
          <div className={styles.label}>部首</div>
          <div
            className={styles.value}
            onClick={() => onRadicalClick(radical.Pinyin)}
          >
            {radical.Path ? (
              <img
                src={ExtendedResourceRepository.getDataSrc({
                  bookId,
                  pathName: radical.Path
                })}
                alt=""
              />
            ) : (
              radical.Name
            )}
          </div>
        </div>
      </div>
      <div className={classnames(styles.wrapper, styles.right)}>
        <div className={classnames(styles.radicalBox, styles.title)}>
          <div className={styles.label}>筆畫</div>
        </div>
        <div className={classnames(styles.radicalBox, styles.green)}>
          <div className={styles.label}>部首</div>
          <div className={styles.value}>{radicalNumber}</div>
        </div>
        <div className={classnames(styles.radicalBox, styles.blue)}>
          <div className={styles.label}>部首外</div>
          <div className={styles.value}>{restNumber}</div>
        </div>
        <div className={classnames(styles.radicalBox, styles.red)}>
          <div className={styles.label}>總筆畫</div>
          <div className={styles.value}>{allNumber}</div>
        </div>
      </div>
    </div>
  );
};

export default RadicalInfoBar;
