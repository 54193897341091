const paddZero = num => {
  const str = num.toString();
  if(str.length < 2) return `0${str}`;
  return str
}

export const timeFormatTommss = time => {
  const minutes = Math.floor(time/60);
  const second = Math.floor(time % 60);
  return `${paddZero(minutes)}:${paddZero(second)}`
}
