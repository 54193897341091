export const hexToRgba = (hex, alpha = 1) => {
  let c = hex;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '#' + c.join('') + Number(parseInt(alpha * 255)).toString(16);
  }
  return c;
};
