import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import { EventBus } from 'events/EventBus';
import { ReaderEvent } from 'events/EventTypes';

const Bookmarks = ({ data = [], show }) => {

  const clickBookmarkHandler = ({ id, page }) => e => {
    EventBus.emit({
      event: ReaderEvent.ClickBookmarkEvent,
      payload: { pageIndex: page, bookId: id }
    });
  };

  return (
    <div
      className={classnames(styles.bookmarkContainer, { [styles.show]: show })}
    >
      {data.map((item, index) => (
        <div
          className={styles.bookmark}
          key={index}
          onClick={clickBookmarkHandler({
            id: item.id,
            page: item.page
          })}
        >
          {' '}
          {item.name}{' '}
        </div>
      ))}
    </div>
  );
};

export default Bookmarks;
