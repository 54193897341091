import React from 'react';
import styles from './index.module.scss';
import classnames from 'classnames'

const ColorSelector = ({ value, colors, onChange = () => { } }) => {
  return (
      <div className={styles.colorSelector}>
        {
          colors.map(color => <div key={color} className={classnames(styles.color, { [styles.active]: ~value.indexOf(color) })} style={{ backgroundColor: color }} onClick={() => onChange(color)}></div>)
        }
        <div className={classnames(styles.color, styles.customerColor)}>
          <label>
            <input type="color" onChange={(e) => onChange(e.target.value)} />
          </label>
        </div>
      </div>
  )
}

export default ColorSelector;
