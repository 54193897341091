import { useCallback } from 'react';
import { fabric } from 'fabric';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { CanvasGroupAlignType, CanvasGroupSplitGapType } from 'constants/alignTypes';
import { DefaultExtraFields, ExtraFieldsForEditor } from 'constants/canvas';
import { CanvasFlipType } from 'constants/flipTypes';
import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';
import { CanvasLevelType } from 'constants/levelTypes';
import { PainterMode } from 'constants/painterModes';
import { SideToolContent } from 'constants/sideToolContents';
import { useAssignCanvasObjectId, useConvertJSONToSVG, useSaveCanvasJSON } from 'customHooks/canvas';
import { useEvent } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import { short_uuid } from 'util/uuid';

export const useBookCanvasEventHandler = ({ canvasState, canvasDispatch, imageFileHandlerRef }) => {
    const { canvas } = canvasState;
    const [, sideToolDispatch] = useStore(StoreTypes.sideTool);
    const saveCanvasJSON = useSaveCanvasJSON();
    const convertJSONToSVG = useConvertJSONToSVG();
    const assignCanvasObjectId = useAssignCanvasObjectId();

    const canvasTextCreatedEventHandler = useCallback(({ object }) => {
        canvasDispatch({ type: types.CANVAS_ACTIVATE });
        canvasDispatch({ type: types.ADD_OBJECT, object });
    }, [canvasDispatch]);

    const canvasTextChangedEventHandler = useCallback(async () => {
        await saveCanvasJSON();
    }, [saveCanvasJSON]);

    const canvasChooseImageSourceEventHandler = useCallback(() => {
        imageFileHandlerRef.current.click();
    }, [imageFileHandlerRef]);

    const canvasFinishPaintingEventHandler = useCallback(async () => {
        assignCanvasObjectId();
        await saveCanvasJSON();
    }, [assignCanvasObjectId, saveCanvasJSON]);

    const canvasObjectSelectedEventHandler = useCallback(({ activeCanvasObject, sideToolDirection }) => {
        canvasDispatch({ type: types.SET_CANVAS_ACTIVE_OBJECT, activeCanvasObject });
        sideToolDispatch({
            type: types.SET_SIDE_TOOL_CONTENT,
            sideToolContent: SideToolContent.CanvasObjectPropertyViewer,
            sideToolDirection
        });
    }, [canvasDispatch, sideToolDispatch]);

    const canvasSelectionClearEventHandler = useCallback(() => {
        sideToolDispatch({
            type: types.SET_SIDE_TOOL_CONTENT,
            sideToolContent: SideToolContent.None
        });
        canvasDispatch({ type: types.SET_CANVAS_ACTIVE_OBJECT, activeCanvasObject: null });
    }, [canvasDispatch, sideToolDispatch]);

    const canvasAddObject = useCallback(async ({ object, activateObject = true }) => {
        canvasDispatch({ type: types.CANVAS_ACTIVATE });
        canvasDispatch({ type: types.ADD_OBJECT, object, activateObject });
        await saveCanvasJSON();
    }, [canvasDispatch, saveCanvasJSON]);

    const canvasImageCreatedEventHandler = useCallback(async ({ object }) => {
        await canvasAddObject({ object });
        canvasDispatch({ type: types.CANVAS_CHANGE_PAINTER_MODE, painterMode: PainterMode.Selection });
    }, [canvasAddObject, canvasDispatch]);

    const canvasObjectCopyEventHandler = useCallback(({ object }) => {
        object.clone(clonedObj => {
            clonedObj.set({
                id: short_uuid(),
                left: clonedObj.left + 10,
                top: clonedObj.top + 10,
                evented: true,
            });
            clonedObj.forEachObject && clonedObj.forEachObject(obj => {
                obj.set({ id: short_uuid() });
            });
            if (clonedObj.type === 'activeSelection') {
                clonedObj.canvas = canvas;
                clonedObj.forEachObject(function(obj) {
                    canvas.add(obj);
                });
                clonedObj.setCoords();
            } else {
                canvas.add(clonedObj);
            }
            canvas.setActiveObject(clonedObj);
            canvas.requestRenderAll();
        }, [...new Set([...Object.values(DefaultExtraFields), ...Object.values(ExtraFieldsForEditor)])]);
    }, [canvas]);

    const canvasStampCreatedEventHandler = useCallback(async ({ object }) => {
        await canvasAddObject({ object, activateObject: false });
    }, [canvasAddObject]);

    const setCanvasShadowEventHandler = useCallback(async ({ object, isShowShadow }) => {
        if (isShowShadow) {
            const shadow = {
                color: 'rgba(0,0,0,0.6)',
                blur: 20,
                offsetX: 10,
                offsetY: 10,
                opacity: 0.6,
                fillShadow: true,
                strokeShadow: true
            };
            object.setShadow(shadow);
        } else {
            object.setShadow(null);
        }
        canvas.renderAll();
        await saveCanvasJSON();
    }, [canvas, saveCanvasJSON]);

    const setCanvasLevelEventHandler = useCallback(async ({ object, levelType }) => {
        switch (levelType) {
            case CanvasLevelType.FRONT:
                object.bringToFront();
                break;
            default:
                object.sendToBack();
                break;
        }
        canvas.renderAll();
        await saveCanvasJSON();
    }, [canvas, saveCanvasJSON]);

    const flipCanvasEventHandler = useCallback(async ({ object, flipType }) => {
        switch (flipType) {
            case CanvasFlipType.HORIZONTAL:
                object.set('flipX', !object.flipX);
                break;
            case CanvasFlipType.VERTICAL:
                object.set('flipY', !object.flipY);
                break;
            default:
                break;
        }
        canvas.renderAll();
        await saveCanvasJSON();
    }, [canvas, saveCanvasJSON])

    const alignCanvasGroupEventHandler = useCallback(async ({ canvasObject, alignType }) => {
        const objects = canvasObject.getObjects();
        const newWidth = objects.reduce((acc, obj) => Math.max(obj.getBoundingRect().width, acc), 0);
        const newHeight = objects.reduce((acc, obj) => Math.max(obj.getBoundingRect().height, acc), 0);
        switch (alignType) {
            case CanvasGroupAlignType.VerticalTop:
                canvasObject.height = newHeight;
                canvasObject.setCoords();
                objects.forEach(obj => {
                    obj.setPositionByOrigin(new fabric.Point(obj.left, -newHeight * 0.5), obj.originX, 'top');
                    obj.setCoords();
                });
                break;
            case CanvasGroupAlignType.VerticalBottom:
                canvasObject.top = canvasObject.top + canvasObject.height - newHeight;
                canvasObject.height = newHeight;
                canvasObject.setCoords();
                objects.forEach(obj => {
                    obj.setPositionByOrigin(new fabric.Point(obj.left, newHeight * 0.5 - obj.getBoundingRect().height), obj.originX, 'top');
                    obj.setCoords();
                });
                break;
            case CanvasGroupAlignType.HorizontalLeft:
                canvasObject.width = newWidth;
                canvasObject.setCoords();
                objects.forEach(obj => {
                    obj.setPositionByOrigin(new fabric.Point(-newWidth * 0.5, obj.top), 'left', obj.originY);
                    obj.left = -newWidth * 0.5 + (obj.originX === 'center' ? obj.getBoundingRect().width * 0.5 : 0);
                    obj.setCoords();
                });
                break;
            case CanvasGroupAlignType.HorizontalRight:
                canvasObject.left = canvasObject.left + canvasObject.width - newWidth;
                canvasObject.width = newWidth;
                canvasObject.setCoords();
                objects.forEach(obj => {
                    obj.setPositionByOrigin(new fabric.Point(newWidth * 0.5 - obj.getBoundingRect().width, obj.top), 'left', obj.originY);
                    obj.setCoords();
                });
                break;
            case CanvasGroupAlignType.HorizontalCenter:
                canvasObject.left = canvasObject.left + (canvasObject.width - newWidth) * 0.5;
                canvasObject.width = newWidth;
                canvasObject.setCoords();
                objects.forEach(obj => {
                    obj.setPositionByOrigin(new fabric.Point(0, obj.top), 'center', obj.originY);
                    obj.setCoords();
                });
                break;
            case CanvasGroupAlignType.VerticalCenter:
                canvasObject.top = canvasObject.top + (canvasObject.height - newHeight) * 0.5;
                canvasObject.height = newHeight;
                canvasObject.setCoords();
                objects.forEach(obj => {
                    obj.setPositionByOrigin(new fabric.Point(obj.left, 0), obj.originX, 'center');
                    obj.setCoords();
                });
                break;
            case CanvasGroupSplitGapType.HorizontallySplitGap:
                if (objects.length > 2) {
                    let splitRatio = objects.length - 2 + 1, objectOrder = [], gapWidth = canvasObject.width;
                    objects.forEach((obj, i) => {
                        const boundingRect = obj.getBoundingRect(true);
                        objectOrder.push({ index: i, x: obj.getPointByOrigin('center', 'center').x, width: boundingRect.width });
                        gapWidth -= boundingRect.width;
                    });
                    objectOrder.sort((a, b) => a.x < b.x ? -1 : 1);
                    if (splitRatio > 1) {
                        gapWidth /= splitRatio;
                        let anchorX = -canvasObject.width * 0.5;
                        for (let orderIndex in objectOrder) {
                            let order = objectOrder[orderIndex];
                            const object = objects[order.index];
                            object.setPositionByOrigin(new fabric.Point(anchorX, object.top), 'left', object.originY);
                            object.setCoords();
                            anchorX += order.width + gapWidth;
                        }
                    }
                }
                break;
            case CanvasGroupSplitGapType.VerticallySplitGap:
                if (objects.length > 2) {
                    let splitRatio = objects.length - 2 + 1, objectOrder = [], gapHeight = canvasObject.height;
                    objects.forEach((obj, i) => {
                        const boundingRect = obj.getBoundingRect(true);
                        objectOrder.push({ index: i, y: obj.getPointByOrigin('center', 'center').y, height: boundingRect.height });
                        gapHeight -= boundingRect.height;
                    });
                    objectOrder.sort((a, b) => a.y < b.y ? -1 : 1);
                    if (splitRatio > 1) {
                        gapHeight /= splitRatio;
                        let anchorY = -canvasObject.height * 0.5;
                        for (let orderIndex in objectOrder) {
                            let order = objectOrder[orderIndex];
                            const object = objects[order.index];
                            object.setPositionByOrigin(new fabric.Point(object.left, anchorY), object.originX, 'top');
                            object.setCoords();
                            anchorY += order.height + gapHeight;
                        }
                    }
                }
                break;
            // case 'horizontal split':
            //     if (objects.length > 2) {
            //         let leftMostIndex = 0, rightMostIndex = 0, splitRatio = objects.length - 2 + 1, objectOrder = [];
            //         objects.forEach((obj, i) => {
            //             if (obj.getBoundingRect(true).left < objects[leftMostIndex].getBoundingRect(true).left) {
            //                 leftMostIndex = i;
            //             }
            //             if (obj.getBoundingRect(true).left + obj.getBoundingRect(true).width > objects[rightMostIndex].getBoundingRect(true).left + objects[rightMostIndex].getBoundingRect(true).width) {
            //                 rightMostIndex = i;
            //             }
            //             objectOrder.push({ index: i, x: obj.getPointByOrigin('center', 'center').x });
            //         });
            //         objectOrder.sort((a, b) => a.x < b.x ? -1 : 1);
            //         if (splitRatio > 1) {
            //             let splitCounter = 1;
            //             for (let order of objectOrder) {
            //                 if (order.index !== leftMostIndex && order.index !== rightMostIndex) {
            //                     const object = objects[order.index];
            //                     object.setPositionByOrigin(new fabric.Point((canvasObject.width * splitCounter) / splitRatio - canvasObject.width * 0.5, object.top), 'center', object.originY);
            //                     object.setCoords();
            //                     splitCounter++;
            //                 }
            //             }
            //         }
            //     }
            //     break;
            // case 'vertical split':
            //     if (objects.length > 2) {
            //         let topMostIndex = 0, bottomMostIndex = 0, splitRatio = objects.length - 2 + 1, objectOrder = [];
            //         objects.forEach((obj, i) => {
            //             if (obj.getBoundingRect(true).top < objects[topMostIndex].getBoundingRect(true).top) {
            //                 topMostIndex = i;
            //             }
            //             if (obj.getBoundingRect(true).top + obj.getBoundingRect(true).height > objects[bottomMostIndex].getBoundingRect(true).top + objects[bottomMostIndex].getBoundingRect(true).height) {
            //                 bottomMostIndex = i;
            //             }
            //             objectOrder.push({ index: i, y: obj.getPointByOrigin('center', 'center').y });
            //         });
            //         objectOrder.sort((a, b) => a.y < b.y ? -1 : 1);
            //         if (splitRatio > 1) {
            //             let splitCounter = 1;
            //             for (let order of objectOrder) {
            //                 if (order.index !== topMostIndex && order.index !== bottomMostIndex) {
            //                     const object = objects[order.index];
            //                     object.setPositionByOrigin(new fabric.Point(object.left, (canvasObject.height * splitCounter) / splitRatio - canvasObject.height * 0.5), object.originX, 'center');
            //                     object.setCoords();
            //                     splitCounter++;
            //                 }
            //             }
            //         }
            //     }
            //     break;
            default:
        }
        canvas.renderAll();

        //  save to indexDB
        await saveCanvasJSON();
    }, [canvas, saveCanvasJSON]);

    const modifyCanvasObjectPropertyHandler = useCallback(async ({ object, property, value }) => {
        if (object.type === 'group' && !object.extra && object.stamp !== true) {
            const items = object.getObjects();
            switch (property) {
                case 'fill':
                case 'stroke':
                    items.forEach(item => item.set({ [property]: value }).setCoords());
                    break;
                default:
                    break;
            }
        }
        object.set({ [property]: value }).setCoords();
        // enforce opacity to 1 if content type is not toggling display object itself
        if (property === ExtraFieldsForEditor.ContentType && value !== InteractiveObjectContentType.ToggleDisplay && object.opacity !== 1) {
            object.set({ opacity: 1 }).setCoords();
        }

        canvas.renderAll();

        //  save to indexDB
        await saveCanvasJSON();
    }, [canvas, saveCanvasJSON]);

    const adjustCanvasLineAngleHandler = useCallback(async ({ object, angle }) => {
        if (object.type !== 'line') return;
        const topLeft = [Math.min(object.x1, object.x2), Math.min(object.y1, object.y2)];
        object.dirty = true;
        const length = Math.sqrt(Math.pow((object.x1 - object.x2), 2) + Math.pow((object.y1 - object.y2), 2));
        if (angle === 90) {
            object.x1 = topLeft[0];
            object.y1 = topLeft[1];
            object.x2 = topLeft[0] + length;
            object.y2 = topLeft[1];
            object.width = length;
            object.height = 1;
        } else if (angle === 180) {
            object.x1 = topLeft[0];
            object.y1 = topLeft[1];
            object.x2 = topLeft[0];
            object.y2 = topLeft[1] + length;
            object.width = 1;
            object.height = length;
        }
        object.setCoords();
        canvas.renderAll();

        //  save to indexDB
        await saveCanvasJSON();
    }, [canvas, saveCanvasJSON]);

    const removeCanvasObjectHandler = useCallback(() => {
        canvasDispatch({ type: types.CANVAS_ERASE_OBJECT })
    }, [canvasDispatch]);

    const CanvasCloseModeHandler = useCallback(async () => {
        const canvasSVG = await convertJSONToSVG({
            keepCanvas: true,
            toSVG: true,
        });
        canvasDispatch({ type: types.CANVAS_IMPORT_SVG, canvasSVG });
        canvasDispatch({ type: types.CANVAS_INACTIVATE });
    }, [canvasDispatch, convertJSONToSVG]);

    const ToggleCanvasGroupEventHandler = useCallback(() => {
        if (!canvas.getActiveObject()) {
            return;
        }
        if (canvas.getActiveObject().type === 'group') {
            canvas.getActiveObject().toActiveSelection();
        } else {
            canvas.getActiveObject().toGroup();
        }
        canvas.renderAll();
    }, [canvas])

    useEvent({ event: CanvasEvent.CanvasTextCreatedEvent }, canvasTextCreatedEventHandler);
    useEvent({ event: CanvasEvent.CanvasTextChangedEvent }, canvasTextChangedEventHandler);
    useEvent({ event: CanvasEvent.CanvasChooseImageSourceEvent }, canvasChooseImageSourceEventHandler);
    useEvent({ event: CanvasEvent.CanvasFinishPaintingEvent }, canvasFinishPaintingEventHandler);
    useEvent({ event: CanvasEvent.CanvasObjectSelectedEvent }, canvasObjectSelectedEventHandler);
    useEvent({ event: CanvasEvent.CanvasSelectionClearEvent }, canvasSelectionClearEventHandler);
    useEvent({ event: CanvasEvent.CanvasImageCreatedEvent }, canvasImageCreatedEventHandler);
    useEvent({ event: CanvasEvent.CanvasObjectCopyEvent }, canvasObjectCopyEventHandler);
    useEvent({ event: CanvasEvent.CanvasStampCreatedEventEvent }, canvasStampCreatedEventHandler);
    useEvent({ event: CanvasEvent.SetCanvasLevelEvent }, setCanvasLevelEventHandler);
    useEvent({ event: CanvasEvent.SetCanvasShadowEvent }, setCanvasShadowEventHandler);
    useEvent({ event: CanvasEvent.FlipCanvasEvent }, flipCanvasEventHandler);
    useEvent({ event: CanvasEvent.AlignCanvasGroupEvent }, alignCanvasGroupEventHandler);
    useEvent({ event: CanvasEvent.ModifyCanvasObjectPropertyEvent }, modifyCanvasObjectPropertyHandler);
    useEvent({ event: CanvasEvent.RemoveCanvasObjectEvent }, removeCanvasObjectHandler);
    useEvent({ event: CanvasEvent.CanvasCloseMode }, CanvasCloseModeHandler);
    useEvent({ event: CanvasEvent.ToggleCanvasGroupEvent }, ToggleCanvasGroupEventHandler);
    useEvent({ event: CanvasEvent.AdjustCanvasLineAngleEvent }, adjustCanvasLineAngleHandler);
};
