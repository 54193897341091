import React from 'react';
import { ExtendedContentContainer } from 'components/ExtendedContent/ExtendedContentContainer';
import SynopsisWindow from 'components/SynopsisWindow';

export const SynopsisModalComponent = ({ synopsisInfo, extendedResourceStyle }) => {
    return (
        <ExtendedContentContainer extendedResourceStyle={extendedResourceStyle}>
            <SynopsisWindow synopsisInfo={synopsisInfo} extendedResourceStyle={extendedResourceStyle}/>
        </ExtendedContentContainer>
    )
};
