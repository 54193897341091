export const ReaderEvent = {
  SetPageIndexEvent: 'SetPageIndexEvent',
  ClickNextPageEvent: 'ClickNextPageEvent',
  ClickPreviousPageEvent: 'ClickPreviousPageEvent',
  ClickBookThumbnailEvent: 'ClickBookThumbnailEvent',
  RefreshCanvasEvent: 'RefreshCanvasEvent',
  CreateAndEnterAnnotationEvent: 'CreateAndEnterAnnotationEvent',
  ClickBookmarkEvent: 'ClickBookmarkEvent',
  AreaZoomEvent: 'AreaZoomEvent',
  AreaZoomForPageButtonEvent: 'AreaZoomForPageButtonEvent',
  DrawAreaEvent: 'DrawAreaEvent',
  GobackCatalogEvent: 'GobackCatalogEvent',
  SaveAnnotationNameEvent: 'SaveAnnotationNameEvent',
  DeleteAnnotationEvent: 'DeleteAnnotationEvent',
  CopyAnnotationEvent: 'CopyAnnotationEvent',
};

export const ReaderToolsEvent = {
  TogglePageModeEvent: 'TogglePageModeEvent',
  ToggleBookmarkEvent: 'ToggleBookmarkEvent',
  ClickDragEvent: 'ClickDragEvent',
  ClickSelectEvent: 'ClickSelectEvent',
  ClickPainterEvent: 'ClickPainterEvent',
  ClickEraserEvent: 'ClickEraserEvent',
  ClickInsertImageEvent: 'ClickInsertImageEvent',
  ClickInsertTextEvent: 'ClickInsertTextEvent',
  ToggleSideBarEvent: 'ToggleSideBarEvent',
  TogglePageSearcherEvent: 'TogglePageSearcherEvent',
  ClickReaderOptionPanelEvent: 'ClickReaderOptionPanelEvent',
  ClickStampPanelEvent: 'ClickStampPanelEvent',
  ClickOpenActivityEvent: 'ClickOpenActivityEvent',
  SetObjectPropertyEvent: 'SetObjectPropertyEvent',
  ChangeReaderToolStyleEvent: 'ChangeReaderToolStyleEvent',
  ChangeReaderToolDirectionEvent: 'ChangeReaderToolDirectionEvent',
  ChangeStampStatusEvent: 'ChangeStampStatusEvent',
  OnMarkModeEvent: 'OnMarkModeEvent',
  SelectMarkEvent: 'SelectMarkEvent',
  SaveMarkEvent: 'SaveMarkEvent',
  SetMarkToolsEnableEvent: 'SetMarkToolsEnableEvent',
  ClickEraseAllEvent: 'ClickEraseAllEvent',
  SetReaderZoomEvent: 'SetReaderZoomEvent',
  ClickFullWidthEvent: 'ClickFullWidthEvent',
  SaveReaderToolSettingsEvent: 'SaveReaderToolSettingsEvent',
  BackToCatalogEvent: 'BackToCatalogEvent',
  SetReaderToolTypeEvent: 'SetReaderToolTypeEvent'
};

export const PainterEvent = {
  ChangeBrushColorEvent: 'ChangeBrushColorEvent',
  ChangeBrushWidthEvent: 'ChangeBrushWidthEvent',
  ChangeBrushTypeEvent: 'ChangeBrushTypeEvent',
  ChangePainterTypeEvent: 'ChangePainterTypeEvent',
  ChangeShapeFillTypeEvent: 'ChangeShapeFillTypeEvent'
};

export const CanvasEvent = {
  CanvasTextCreatedEvent: 'CanvasTextCreatedEvent',
  CanvasTextChangedEvent: 'CanvasTextChangedEvent',
  CanvasChooseImageSourceEvent: 'CanvasChooseImageSourceEvent',
  CanvasFinishPaintingEvent: 'CanvasFinishPaintingEvent',
  CanvasObjectSelectedEvent: 'CanvasObjectSelectedEvent',
  CanvasSelectionClearEvent: 'CanvasSelectionClearEvent',
  CanvasImageCreatedEvent: 'CanvasImageCreatedEvent',
  CanvasStampCreatedEventEvent: 'CanvasStampCreatedEventEvent',
  ModifyCanvasObjectPropertyEvent: 'ModifyCanvasObjectPropertyEvent',
  RemoveCanvasObjectEvent: 'RemoveCanvasObjectEvent',
  SetCanvasLevelEvent: 'SetCanvasLevelEvent',
  SetCanvasShadowEvent: 'SetCanvasShadowEvent',
  FlipCanvasEvent: 'FlipCanvasEvent',
  AlignCanvasGroupEvent: 'AlignCanvasGroupEvent',
  CanvasSavedEvent: 'CanvasSavedEvent',
  CanvasCloseMode: 'CanvasCloseMode',
  CanvasObjectCopyEvent: 'CanvasObjectCopyEvent',
  ToggleCanvasGroupEvent: 'ToggleCanvasGroupEvent',
  AdjustCanvasLineAngleEvent: 'AdjustCanvasLineAngleEvent',
};

export const UserEvent = {
  LoginEvent: 'LoginEvent',
  GetUserIdentityEvent: 'GetUserIdentityEvent'
};

export const ActivityEvent = {
  CreatActivityEvent: 'CreatActivityEvent',
  EnterActivityEvent: 'EnterActivityEvent',
  ChangeActivityTargetEvent: 'ChangeActivityTargetEvent',
  JoinActivityEvent: 'JoinActivityEvent',
  SendActivityInfoToFirebaseEvent: 'SendActivityInfoToFirebaseEvent',
  // GetActivityEvent: 'GetActivityEvent',
  OnAnnotationUpdateEvent: 'OnAnnotationUpdateEvent',
  UpdateActivityInfo: 'UpdateActivityInfo',
  LeaveActivityEvent: 'LeaveActivityEvent',
  ToggleActivityPanelEvent: 'ToggleActivityPanelEvent',
};

export const InteractiveObjectEvent = {
  ClickInteractiveObjectEvent: 'ClickInteractiveObjectEvent',
  ExportInteractiveObjectEvent: 'ExportInteractiveObjectEvent',
};
