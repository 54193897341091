import { useCallback } from 'react';
import { ReaderToolType } from 'constants/ReaderTools';
import { BaseReaderStrategy } from './BaseReaderStrategy';

const Whitelist = [
  ReaderToolType.Menu,
  ReaderToolType.PageMode,
  ReaderToolType.Mark,
  ReaderToolType.Drag,
  ReaderToolType.Select,
  ReaderToolType.Painter,
  ReaderToolType.Painting,
  ReaderToolType.Highlighter,
  ReaderToolType.Shape,
  ReaderToolType.Line,
  ReaderToolType.DeleteAll,
  ReaderToolType.Delete,
  ReaderToolType.ArrowLeft,
  ReaderToolType.ArrowRight,
  ReaderToolType.PageSercher,
  ReaderToolType.FourZoomIn,
  ReaderToolType.AllScreen,
  ReaderToolType.IntervalZoomIn,
  ReaderToolType.Whiteboard,
  ReaderToolType.NumberPicker,
  ReaderToolType.Img,
  ReaderToolType.Text,
  //ReaderToolType.SVG,
  ReaderToolType.Bookmark,
  ReaderToolType.ReaderOption,
  ReaderToolType.RightSideBar,
  ReaderToolType.FullWidth,
];

export const useGuestStrategy = () => {
  const getReaderToolComponents = useCallback((readerToolComponents) => {
    return BaseReaderStrategy.getAcceptedReaderToolComponents(readerToolComponents, Whitelist);
  }, []);

  const isReaderSwipeable = useCallback(() => {
    return true;
  }, []);

  const isReaderSwitchPageAvailable = useCallback(() => {
    return true;
  }, []);

  return { name: 'GuestStrategy', getReaderToolComponents, isReaderSwipeable, isReaderSwitchPageAvailable };
};
