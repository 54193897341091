import React, { useEffect } from 'react';
import { useAudio } from 'customHooks/mediaElement';
import Icon from 'components/Icon';
import VolumeBar from 'components/common/Player/VolumeBar';
import TimeInfo from 'components/common/Player/TimeInfo';
import ProcessBar from 'components/common/Player/ProcessBar';
import SubtitleControl from 'components/common/Player/SubtitleControl';
import ControlButton from 'components/common/Player/ControlButton';
import styles from './index.module.scss';

const MusicPlayer = ({
  src,
  playlist = [],
  seekList = [],
  subtitles = [],
  autoPlay = true,
  onLoad = () => { }
}) => {
  const [audio, state, controls, ref] = useAudio(
    <audio id="audio" src={src} playsInline autoPlay={autoPlay} crossOrigin="anonymous">
      {
        subtitles.map((subtitle, index) => (
          <>
            <track
              key={subtitle}
              default kind="subtitles"
              label={subtitle.language}
              srcLang={subtitle.language}
              src={subtitle.src}
            />
          </>
        ))}
    </audio>
  );

  useEffect(() => {
    onLoad({
      audio, state, controls, ref
    })
  }, [audio, controls, onLoad, ref, state])

  return (
    <div className={styles.musicPlayer}>
      <div className={styles.music}>
        {audio}
        <div className={styles.controlBar}>
          <ProcessBar
            className={styles.processBar}
            currentTime={state.time}
            endTime={state.duration}
            onChange={controls.seek}
          />
          <div className={styles.top}>
            <ControlButton
              onClick={state.isPlaying ? controls.pause : controls.play}
            >
              {state.isPlaying ? (
                <Icon type="text" name="pause" />
              ) : (
                  <Icon type="text" name="play" />
                )}
            </ControlButton>

            <VolumeBar
              state={state}
              controls={controls}
              onChange={val => controls.volume(val)}
            />

            <ControlButton onClick={() => controls.seek(state.time - 10)}>
              <Icon type="text" name="backward" />
            </ControlButton>
            <ControlButton onClick={() => controls.seek(state.time + 10)}>
              <Icon type="text" name="forward" />
            </ControlButton>
          </div>
          <div className={styles.bottom}>
            <TimeInfo currentTime={state.time} endTime={state.duration} />
          </div>
          <div className={styles.right}>
            {subtitles.length > 0 && (
              <SubtitleControl
                value={state.subtitles}
                subtitles={subtitles}
                onChange={controls.setSubtitlesLanguage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MusicPlayer;
