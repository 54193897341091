import React, { useEffect, useRef } from 'react';

import * as types from 'constants/actionTypes'

import classnames from 'classnames';
import styles from './index.module.scss';

export const ExtendedContentCanvas = ({ canvasState, canvasDispatch, canvasWidth, canvasHeight }) => {
    const canvasRef = useRef(null);
    const { isActive } = canvasState;

    useEffect(() => {
        canvasDispatch({
            type: types.CANVAS_INITIALIZE,
            el: canvasRef.current,
            width: canvasWidth,
            height: canvasHeight,
            isDrawingMode: true
        });
        // eslint-disable-next-line
    }, []);

    return (
        <div
            className={classnames(styles.canvasWrapper, {
                [styles.show]: isActive
            })}
        >
            <canvas ref={canvasRef} id='whiteboard-canvas' />
        </div>
    );
};
