import { useCallback } from 'react';
import { ReaderToolType } from 'constants/ReaderTools';
import { BaseReaderStrategy } from './BaseReaderStrategy';

const Whitelist = [ReaderToolType.AttendeeNumber];

export const useInactiveStudentStrategy = () => {
    const getReaderToolComponents = useCallback((readerToolComponents) => {
        return BaseReaderStrategy.getAcceptedReaderToolComponents(readerToolComponents, Whitelist);
    }, []);

    const isReaderSwipeable = useCallback(() => {
        return false;
    }, []);

    const isReaderSwitchPageAvailable = useCallback(() => {
        return false;
    }, []);

    return { name: 'InactiveStudentStrategy', getReaderToolComponents, isReaderSwipeable, isReaderSwitchPageAvailable };
};
