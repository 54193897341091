import React, { useCallback } from 'react';
import Input from 'components/common/Input';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import styles from './index.module.scss';

export const InternalHtmlWindowComponent = ({ object }) => {
    const internalHtmlInfo = object.internalHtmlInfo ? JSON.parse(object.internalHtmlInfo) : { src: "", toolbarOpen: false }

    const changeSelect = useCallback((value) => {
        internalHtmlInfo.toolbarOpen = value;
        EventBus.emit({ event: CanvasEvent.ModifyCanvasObjectPropertyEvent, payload: { object, property: "internalHtmlInfo", value: JSON.stringify(internalHtmlInfo) } });
    }, [internalHtmlInfo, object]);

    const srcChangeHandler = useCallback((value) => {
        internalHtmlInfo.src = value;
        console.log("-----srcChangeHandler-----",internalHtmlInfo)
        EventBus.emit({ event: CanvasEvent.ModifyCanvasObjectPropertyEvent, payload: { object, property: "internalHtmlInfo", value: JSON.stringify(internalHtmlInfo) } });
    }, [internalHtmlInfo, object]);

    return (<div>
        <hr></hr>
        <Input className={styles.internalHtmlInput} label={"Src"} value={internalHtmlInfo.src} onChange={(e) => srcChangeHandler(e.target.value)} />
        <Input type="checkbox" label="是否開啟工具列" value={object} checked={internalHtmlInfo.toolbarOpen} onChange={(e) => changeSelect(e.target.value)} />
    </div>);
}