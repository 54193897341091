import React, { useState, useRef } from 'react';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { ReaderEvent } from 'events/EventTypes';
import * as types from 'constants/actionTypes';
import { SideToolContent } from 'constants/sideToolContents';

const DrawArea = ({ props }) => {
    const [{ style, bookContent }] = useStore(StoreTypes.books);
    const [, readerDispatch] = useStore(StoreTypes.reader);
    const [{ sideToolContent }] = useStore(StoreTypes.sideTool);
    const [isMove, setIsMove] = useState(false)
    const [rect, setRect] = useState({ x: 0, y: 0, width: 0, heght: 0 })
    const rectRef = useRef();

    const getDeviceXY = (e) => {
        let deviceX;
        let deviceY;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
            deviceX = e.nativeEvent.touches[0].pageX
            deviceY = e.nativeEvent.touches[0].pageY;
        } else {
            if (e.nativeEvent + "" === "[object TouchEvent]") {
                deviceX = e.nativeEvent.touches[0].pageX
                deviceY = e.nativeEvent.touches[0].pageY;
            } else {
                deviceX = e.pageX
                deviceY = e.pageY;
            }
        }
        return { deviceX, deviceY }
    }

    const mouseDownHandler = (e) => {
        const {deviceX,deviceY}=getDeviceXY(e)
        console.log(deviceX,deviceY)
        setIsMove(true)
        const { width, height } = rect;
        setRect({ x:deviceX, y:deviceY, width, height })
        rectRef.current.style.top = `${(deviceY)}px`
        rectRef.current.style.left = `${(deviceX)}px`
    }

    const mouseMoveHandler = (e) => {
        if (!isMove) { return; }
        const {deviceX,deviceY}=getDeviceXY(e)
        const { x, y } = rect;
        rectRef.current.style.position = "absolute"
        rectRef.current.style.backgroundColor = "aqua"
        rectRef.current.style.width = `${(deviceX - x)}px`
        rectRef.current.style.height = `${(deviceY - y)}px`
        setRect({ x, y, width: (deviceX - x), height: (deviceY - y) })
    }

    const mouseUpHandler = (e) => {
        if (!isMove) { return; }

        EventBus.emit({ event: ReaderEvent.DrawAreaEvent, payload: { isDrawArea: false } });

        const { x, y, width, height } = rect;
        const pageContentWidth = bookContent[0].width * 2
        const pageContentHeight = bookContent[0].height
        const scale = (style.height / pageContentHeight)
        const originScale = (pageContentHeight / style.height)
        const offsetX = ((style.width - pageContentWidth * scale) / 2)
        const offsetY = ((style.height - pageContentHeight * scale) / 2)

        const areaZoomInfo = {
            originScale,
            originX: x,
            originY: y,
            originWidth: width,
            originHeight: height,
            offsetX,
            offsetY,
            x: (x - offsetX) * originScale,
            y: (y - offsetY) * originScale,
            width: (width * originScale),
            height: ((height) * originScale)
        }

        readerDispatch({ type: types.SET_DRAW_AREA_INFO, drawAreaInfo: areaZoomInfo })

        setIsMove(false)
        sideToolContent === SideToolContent.None && EventBus.emit({ event: ReaderEvent.AreaZoomEvent, payload: { rect: areaZoomInfo } })
        //EventBus.emit({ event: CanvasEvent.ModifyCanvasObjectPropertyEvent, payload: { object, property: "areaZoomInfo", value: JSON.stringify(areaZoomInfo) } });
    }

    return (
        <div
            id="drawArea"
            className={styles.drawArea}
            onMouseDown={(e) => { mouseDownHandler(e) }}
            onMouseMove={(e) => { mouseMoveHandler(e) }}
            onMouseUp={(e) => { mouseUpHandler(e) }}
            onTouchStart={(e) => { mouseDownHandler(e) }}
            onTouchMove={(e) => { mouseMoveHandler(e) }}
            onTouchEnd={(e) => { mouseUpHandler(e) }}
        >
            <div ref={rectRef}></div>
        </div>
    );
};

export default DrawArea;
