import React, { useRef } from 'react';

import { fabric } from 'fabric';
import { ExtendedContentTools } from 'components/ExtendedContent/ExtendedContentTools';
import { ExtendedContentCanvas } from 'components/ExtendedContent/ExtendedContentCanvas';
import { ExtendedContentEvent } from '../ExtendedContentEvent';
import { EventBusType } from 'events/EventBus';
import styles from './index.module.scss';

const eventBusType = EventBusType.ExtendedContent;

export const ExtendedHtmlContentContainer = ({ children }) => {
    const contentView = useRef();
    const [{ 
        canvasState,
        canvasHeight,
        canvasWidth,
        isContentInit
    },canvasDispatch] = ExtendedContentEvent(contentView);


    return (
        <div id="exHtmlContainer" className={styles.extendedContentContainer}>
            <div ref={contentView} className={styles.contentView}>
                {children}
                {isContentInit && <ExtendedContentCanvas {
                    ...{ canvasState, canvasDispatch, canvasWidth, canvasHeight }
                } />}
            </div>
            <ExtendedContentTools {...{ eventBusType, canvasState }} />
        </div>
    );
};
