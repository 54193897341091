import React, { useCallback } from 'react';
import { useBoolean } from 'customHooks/boolean';
import classnames from 'classnames';
import styles from './index.module.scss';


const WORDING_MAP = ['筆順教學','筆畫教學','綜合教學','書寫提醒'];
const WordsMovieButtonGroup = ({ 
  data, 
  activeIndex, 
  onChange = () => {},
  onPlayAllWordsClick = () => {}
}) => {
  const [isPlaying, { toggle } ] = useBoolean(false);

  const onPlayAllWordsClickHandler = useCallback(e => {
    onPlayAllWordsClick(!isPlaying);
    toggle();
  },[onPlayAllWordsClick,isPlaying,toggle]);

  return (
    <div className={styles.buttonGroup}>
      {
        data.map((item,index) => (
        WORDING_MAP[index] && 
        <button 
          key={item.Path}
          className={classnames({[styles.active]: index === activeIndex})} 
          onClick={()=>onChange({
            index,
            value: item.Path
          })}>
          {WORDING_MAP[index]}
        </button>))
      }
      <button
        className={classnames({[styles.active]: isPlaying})}
        onClick={onPlayAllWordsClickHandler}>
        生字連播
      </button>
    </div>
  )
};

export default WordsMovieButtonGroup;