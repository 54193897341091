import { useCallback } from 'react';
import { EventBus } from 'events/EventBus';
import { ReaderEvent } from 'events/EventTypes';

export const useAreaZoomCommand = () => {
    const execute = useCallback(async ({ json: { areaZoomInfo } }) => {
        EventBus.emit({ event: ReaderEvent.AreaZoomForPageButtonEvent, payload: { rect: JSON.parse(areaZoomInfo)} })
    }, []);

    return { execute };
};
