import React from 'react' ;
import useSetState from 'customHooks/setState';
import ControlButton from 'components/common/Player/ControlButton';
import classnames from 'classnames';
import styles from './index.module.scss';

const PlaybackRateList = {
  1:'1x',
  0.75:'0.75x',
  1.25:'1.25x'
};
const PlaybackRateControl = ({value,onChange}) => {

  const [state, setState] = useSetState({
    isShow:false,
    rate: value
  });

  const handler = (rate) => {
    setState({rate})
    onChange(rate);
  }

  return (
      <ControlButton className={styles.playbackRateButton} onClick={()=>setState({isShow:!state.isShow})}> 
        <div className={classnames(styles.playbackRateList,{
          [styles.active]:state.isShow
        })} >
        {
          Object.keys(PlaybackRateList).map(item=><div key={PlaybackRateList[item]} className={styles.item} onClick={()=>handler(item)}>{PlaybackRateList[item]}</div>)
        }
        </div>
        {PlaybackRateList[state.rate]}
      </ControlButton>
  )
}

export default PlaybackRateControl;