import { API } from 'api';
import { encodeURIString } from 'util/helper';

import { IExtendedResourceRepository } from './IExtendedResourceRepository';

function CloudExtendedResourceRepository() {}
CloudExtendedResourceRepository.prototype = Object.create(
  IExtendedResourceRepository
);

CloudExtendedResourceRepository.prototype.getVideoSrc = ({
  bookId,
  filename
}) => {
  return `${process.env.REACT_APP_RESOURCE_CDN_DOMAIN}/media/${bookId}/video/${filename}.mp4`;
};

CloudExtendedResourceRepository.prototype.getAudioSrc = ({
  bookId,
  filename
}) => {
  return `${process.env.REACT_APP_RESOURCE_CDN_DOMAIN}/media/${bookId}/audio/${filename}.mp3`;
};

CloudExtendedResourceRepository.prototype.getAudioVTTSrc = ({
  bookId,
  audioInfo
}) => {
  return `${process.env.REACT_APP_RESOURCE_CDN_DOMAIN}/media/${bookId}/audio/${audioInfo}.vtt`;
};

CloudExtendedResourceRepository.prototype.getSubtitleSrc = ({
  bookId,
  filename
}) => {
  return `${process.env.REACT_APP_RESOURCE_CDN_DOMAIN}/media/${bookId}/video/${filename}.vtt`;
};

CloudExtendedResourceRepository.prototype.getInternalHtmlSrc = ({
  bookId,
  filename
}) => {
  return `${process.env.REACT_APP_RESOURCE_CDN_DOMAIN}/data/${bookId}/${filename}.html`;
};

CloudExtendedResourceRepository.prototype.getDataSrc = ({
  bookId,
  pathName
}) => {
  return `${
    process.env.REACT_APP_RESOURCE_CDN_DOMAIN
  }/data/${bookId}/${encodeURIString(pathName)}`;
};

CloudExtendedResourceRepository.prototype.getInternalWindowHtml = async ({
  bookId,
  filename
}) => {
  const response = await API.getJSON(
    `${process.env.REACT_APP_RESOURCE_CDN_DOMAIN}/data/${bookId}/${filename}.json`
  );
  return response.html;
};

CloudExtendedResourceRepository.prototype.getJSONContent = async ({
  bookId,
  pathName
}) => {
  const response = await API.getJSON(
    `${process.env.REACT_APP_RESOURCE_CDN_DOMAIN}/data/${bookId}/${pathName}.json`
  );
  return response;
};

CloudExtendedResourceRepository.prototype.getMathJaxPath = () => {
  return 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js';
};

export default new CloudExtendedResourceRepository();
