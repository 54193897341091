import React from 'react';
import Button from 'components/Button/index'; 
import Icon from 'components/Icon';
import { PainterType } from 'constants/painterTypes';
import styles from './index.module.scss';

const LineTypeSelector = ({ onChange }) => {

  return (
      <div className={styles.lineTypeSelector}>
        <Button onClick={onChange(PainterType.Line)}>
            <Icon name="Straight" />
        </Button>
        <Button onClick={onChange(PainterType.Arrow)}>
            <Icon name="Arrow" />
        </Button>
        <Button onClick={onChange(PainterType.DottedLine)}>
            <Icon name="Dotted" />
        </Button>
      </div>
  );
}
export default LineTypeSelector;
