import { EventBus } from 'events/EventBus';
import { PainterMode } from 'constants/painterModes';
import { PainterToolType } from 'constants/painterTypes';
import {
  ActivityEvent,
  InteractiveObjectEvent,
  ReaderEvent,
  ReaderToolsEvent,
  PainterEvent
} from 'events/EventTypes';
import {
  ReaderToolType,
  ReaderZoomType,
  SideBarType
} from 'constants/ReaderTools';
import { BookFlipType } from 'constants/flipTypes';

const changePainterMode = ({ event, painterMode, painterToolType }) => {
  EventBus.emit({ event, payload: { painterMode, painterToolType } });
};

const addImageClickHandler = e => {
  EventBus.emit({
    event: ReaderToolsEvent.ClickInsertImageEvent,
    payload: { painterMode: PainterMode.InsertImage }
  });
  setReaderToolType(ReaderToolType.Image);
};

const addTextClickHandler = e => {
  EventBus.emit({
    event: ReaderToolsEvent.ClickInsertTextEvent,
    payload: { painterMode: PainterMode.InsertText }
  });
  setReaderToolType(ReaderToolType.Text);
};

const toggleBookmarkHandler = e => {
  EventBus.emit({
    event: ReaderToolsEvent.ToggleBookmarkEvent
  });
};

const toggleActivityPanel = () => {
  EventBus.emit({
    event: ActivityEvent.ToggleActivityPanelEvent,
    payload: {}
  });
};

const setReaderToolType = type => {
  EventBus.emit({
    event: ReaderToolsEvent.SetReaderToolTypeEvent,
    payload: {
      readerToolType: type
    }
  });
}

export default {
  [ReaderToolType.Menu]: {
    title: '回目次',
    clickHandler: () => {
      EventBus.emit({ event: ReaderEvent.GobackCatalogEvent });
    }
  },
  [ReaderToolType.Mark]: {
    title: '標記文字',
    clickHandler: () => {
      EventBus.emit({
        event: ReaderToolsEvent.OnMarkModeEvent
      });
      setReaderToolType(ReaderToolType.Mark)
    }
  },
  [ReaderToolType.Drag]: {
    title: '滑鼠',
    clickHandler: () => {
      EventBus.emit({
        event: ReaderToolsEvent.ClickDragEvent
      });
      setReaderToolType(ReaderToolType.Drag);
    }
  },
  [ReaderToolType.Select]: {
    title: '選取',
    clickHandler: () => {

      changePainterMode({
        event: ReaderToolsEvent.ClickSelectEvent,
        painterMode: PainterMode.Selection
      });
      setReaderToolType(ReaderToolType.Select);
    }

  },
  [ReaderToolType.Painting]: {
    title: '畫筆',
    clickHandler: () => {
      changePainterMode({
        event: ReaderToolsEvent.ClickPainterEvent,
        painterMode: PainterMode.Painting,
        painterToolType: PainterToolType.Pen
      });
      setReaderToolType(ReaderToolType.Painting);
    }
  },
  [ReaderToolType.Highlighter]: {
    title: '螢光筆',
    clickHandler: () => {
      changePainterMode({
        event: ReaderToolsEvent.ClickPainterEvent,
        painterMode: PainterMode.Painting,
        painterToolType: PainterToolType.Highlighter
      });
      setReaderToolType(ReaderToolType.Highlighter);
    }
  },
  [ReaderToolType.Shape]: {
    title: '形狀',
    clickHandler: () => {
      changePainterMode({
        event: ReaderToolsEvent.ClickPainterEvent,
        painterMode: PainterMode.Painting,
        painterToolType: PainterToolType.Shape
      });
      setReaderToolType(ReaderToolType.Shape);
    }
  },
  [ReaderToolType.Line]: {
    title: '線段',
    clickHandler: () => {
      changePainterMode({
        event: ReaderToolsEvent.ClickPainterEvent,
        painterMode: PainterMode.Painting,
        painterToolType: PainterToolType.Line
      });
      setReaderToolType(ReaderToolType.Line);
    }
  },
  [ReaderToolType.DeleteAll]: {
    title: '全部刪除',
    clickHandler: () => {
      EventBus.emit({ event: ReaderToolsEvent.ClickEraseAllEvent });
    }
  },
  [ReaderToolType.Delete]: {
    title: '部分刪除',
    clickHandler: () => {
      changePainterMode({
        event: ReaderToolsEvent.ClickEraserEvent,
        painterMode: PainterMode.Eraser
      })
      setReaderToolType(ReaderToolType.Delete);
    }
  },
  [ReaderToolType.ArrowLeft]: {
    title: '左翻',
    clickHandler: (book, LRFlip) => {
      if (LRFlip === BookFlipType.LEFT_TO_RIGHT) {
        EventBus.emit({
          event: ReaderEvent.ClickPreviousPageEvent,
          payload: { convertToSVG: true }
        });
      } else {
        EventBus.emit({
          event: ReaderEvent.ClickNextPageEvent,
          payload: { pageInfos: book.pageInfos, convertToSVG: true }
        });
      }
    }
  },
  [ReaderToolType.ArrowRight]: {
    title: '右翻',
    clickHandler: (book, LRFlip) => {
      if (LRFlip === BookFlipType.LEFT_TO_RIGHT) {
        EventBus.emit({
          event: ReaderEvent.ClickNextPageEvent,
          payload: { pageInfos: book.pageInfos, convertToSVG: true }
        });
      } else {
        EventBus.emit({
          event: ReaderEvent.ClickPreviousPageEvent,
          payload: { convertToSVG: true }
        });
      }
    }
  },
  [ReaderToolType.FourZoomIn]: {
    title: '四格放大'
  },
  [ReaderToolType.AllScreen]: {
    title: '全螢幕',
    clickHandler: () => {
      EventBus.emit({
        event: ReaderToolsEvent.SetReaderZoomEvent,
        payload: { type: ReaderZoomType.OriginZoom }
      })
    }
  },
  [ReaderToolType.IntervalZoomIn]: {
    title: '區間放大',
    clickHandler: () => {
      EventBus.emit({
        event: ReaderEvent.DrawAreaEvent,
        payload: { isDrawArea: true }
      });
      setReaderToolType(ReaderToolType.IntervalZoomIn);
    }
  },
  [ReaderToolType.Whiteboard]: {
    title: '白板'
  },
  [ReaderToolType.NumberPicker]: {
    title: '選號器'
  },
  [ReaderToolType.Img]: {
    title: '新增圖片',
    clickHandler: addImageClickHandler
  },
  [ReaderToolType.Text]: {
    title: '新增文字',
    clickHandler: addTextClickHandler
  },
  [ReaderToolType.SVG]: {
    title: 'SVG物件',
    clickHandler: () => {
      EventBus.emit({ event: ReaderToolsEvent.ClickStampPanelEvent })
      setReaderToolType(ReaderToolType.Menu);
    }
  },
  [ReaderToolType.PageMode]: {
    clickHandler: isDoublePageMode => {
      EventBus.emit({
        event: ReaderToolsEvent.TogglePageModeEvent,
        payload: { isDoublePageMode: !isDoublePageMode }
      })
    }
  },
  [ReaderToolType.AttendeeNumber]: {
  },
  [ReaderToolType.ReaderOption]: {
    title: '設定',
    clickHandler: () => {
      EventBus.emit({ event: ReaderToolsEvent.ClickReaderOptionPanelEvent })
    }
  },
  [ReaderToolType.Bookmark]: {
    title: '頁籤',
    clickHandler: () => toggleBookmarkHandler()
  },
  [ReaderToolType.Activity]: {
    title: '活動',
    clickHandler: () => toggleActivityPanel()
  },
  [ReaderToolType.ExportInteractiveObject]: {
    title: '匯出',
    clickHandler: e => {
      EventBus.emit({
        event: InteractiveObjectEvent.ExportInteractiveObjectEvent
      });
    }
  }
};
