import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import * as types from 'constants/actionTypes';
import {
  ToolBarDirection,
  ReaderZoomType,
  ReaderToolType
} from 'constants/ReaderTools';
export const initState = {
  pageIndex: 0,
  readerToolBarHeight: 0,
  isDoublePageMode: window.outerWidth > window.outerHeight,
  scale: 1,
  offsetX: 0,
  offsetY: 0,
  isLeftBarShow: false,
  isRightBarShow: false,
  isBookmarkShow: false,
  isMarkModeShow: false,
  isMarkToolsShow: false,
  isPageSearcherShow: false,
  isDrawArea: false,
  drawAreaInfo: null,
  submenuInfo: null,
  fullWidthInfo: {
    offset: 0,
    scale: 1,
    mode: true
  },
  zoomType: ReaderZoomType.OriginZoom,
  markObject: {},
  readerToolType: ReaderToolType.Drag,
  readerToolColor: '#fff',
  readerToolDirection: ToolBarDirection.BOTTOM,
  indicatorInfo: {
    isActive: false,
    message: 'Now loading...'
  }
};

const ReaderReducer = (state, action) => {
  switch (action.type) {
    case types.SWITCH_BOOK_PAGES_SHOW:
      return { ...state, isDoublePageMode: action.isDoublePageMode };
    case types.SET_MARK_MODE:
      return {
        ...state,
        isMarkModeShow:
          action.isMarkModeShow !== undefined
            ? action.isMarkModeShow
            : state.isMarkModeShow
      };
    case types.SET_MARK_TOOLS_SHOW:
      return {
        ...state,
        isMarkToolsShow:
          action.isMarkToolsShow !== undefined
            ? action.isMarkToolsShow
            : state.isMarkToolsShow
      };
    case types.SET_MARK_OBJECT:
      return {
        ...state,
        markObject:
          action.markObject !== undefined ? action.markObject : state.markObject
      };
    case types.SET_BOOK_PAGE_INDEX:
      return {
        ...state,
        pageIndex:
          action.pageIndex !== undefined ? action.pageIndex : state.pageIndex
      }; //TODO: 在activity創建時pageIndex預設為0
    case types.TOGGLE_LEFT_BAR:
      return { ...state, isLeftBarShow: !state.isLeftBarShow };
    case types.TOGGLE_PAGE_SEARCHER:
      return { ...state, isPageSearcherShow: !state.isPageSearcherShow };
    case types.TOGGLE_RIGHT_BAR:
      return { ...state, isRightBarShow: !state.isRightBarShow };
    case types.TOGGLE_BOOKMARK:
      return { ...state, isBookmarkShow: !state.isBookmarkShow };
    case types.CHANGE_READER_TOOL_STYLE:
      return { ...state, readerToolColor: action.color };
    case types.CHANGE_READER_TOOL_DIRECTION:
      return { ...state, readerToolDirection: action.direction };
    case types.SET_INDICATOR_INFO:
      return {
        ...state,
        indicatorInfo: { ...state.indicatorInfo, ...action.indicatorInfo }
      };
    case types.SET_SCALE_INFO:
      return {
        ...state,
        scale: action.scale,
        offsetX: action.offsetX,
        offsetY: action.offsetY
      };
    case types.SET_FULL_WIDTH_INFO:
      const {
        offset = state.fullWidthInfo.offset,
        scale = state.fullWidthInfo.scale,
        mode = state.fullWidthInfo.mode
      } = action.fullWidthInfo;
      return { ...state, fullWidthInfo: { offset, scale, mode } };
    case types.SET_READER_ZOOM_TYPE:
      if (!ReaderZoomType[action.zoomType]) return state;
      return { ...state, zoomType: action.zoomType };
    case types.TOGGLE_DRAW_AREA:
      return { ...state, isDrawArea: action.isDrawArea };
    case types.SET_DRAW_AREA_INFO:
      return { ...state, drawAreaInfo: action.drawAreaInfo };
    case types.SET_SUBMENU_INFO:
      return { ...state, submenuInfo: action.submenuInfo };
    case types.SET_READER_TOOL_TYPE:
      return { ...state, readerToolType: action.readerToolType };
    default:
      return state;
  }
};

const useReaderReducer = () => useEnhanceReducer(ReaderReducer, initState);

export default useReaderReducer;
