import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Provider from './context';
import { AppService } from 'components/AppService';
import Reader from './components/Reader/index';
import { Bookshelf } from 'components/Bookshelf';

const HookedBrowserRouter = ({ children }) => (
  <BrowserRouter>
    <Route>
      {(routeProps) => (
        <Provider router={routeProps}>
          {children}
        </Provider>
      )}
    </Route>
  </BrowserRouter>
)

const App = () => {
  return (
    <HookedBrowserRouter>
       <AppService />
        <Switch>
          <Route exact path='/bookshelf' component={Bookshelf} />
          <Route exact path='/:bookId' component={Reader} />
          <Redirect path='*' to='/bookshelf' />
        </Switch>
    </HookedBrowserRouter>
  );
};

export default App;

