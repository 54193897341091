import React, { useState } from 'react';
import Tab from 'components/Tab';
import MessageContainer from 'components/MessageContainer';
import Dropzone from 'components/Dropzone';
import styles from './index.module.scss';

const tabData = [
    {id:0,label:'私訊'},
    {id:1,label:'討論區'},
    {id:2,label:'上傳區'},
];


const discussList = [
    {id:0,title:'討論區 標題一'},
    {id:1,title:'討論區 標題二'},
    {id:2,title:'討論區 標題三'},
];

const identityMap = ['老師','學生'];

const DiscussionPanel = () => {
    const [currentTabIndex,setTabIndex] = useState(0);
    const [identity,setIdentity] = useState(identityMap[0]);

    return <div className={styles.discussionPanel}>
        <div className={styles.radioWrapper}>
            <label>
                <input type='radio' name='identity' onClick={()=>setIdentity(identityMap[0])} checked={identity===identityMap[0]} onChange={()=>{}} />
                老師
            </label>
            <label>
                <input type='radio' name='identity' onClick={()=>setIdentity(identityMap[1])}  checked={identity===identityMap[1]} onChange={()=>{}} />
                學生
            </label>
        </div>
        <div className={styles.imgWrapper}>
            {identity === identityMap[0] && <img src='assets/img/teacher.jpg' alt=''/>}
            {identity === identityMap[1] && <img src='assets/img/student.jpg' alt=''/>}
        </div>
        <div className={styles.rangeWrapper}>
            <label>
                音量
                <input type='range' min='0' max='100' step='1' value='50' onChange={()=>{}} />
            </label>
            <label>
                音量
                <input type='range' min='0' max='100' step='1' value='50' onChange={()=>{}} />
            </label>
        </div>
        <Tab data={tabData} onChange={(index)=>setTabIndex(index)} currentIndex={currentTabIndex}/>
        {currentTabIndex === 0 && <MessageContainer />}
        {currentTabIndex=== 1 && discussList.map(item=> <div key={item.id} className={styles.discuss}>{item.title}</div>)}
        {currentTabIndex=== 2 && <Dropzone />}

    </div>
}



export default DiscussionPanel;
