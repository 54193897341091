import React from 'react';
import { withRouter } from 'react-router';
import BookView from 'components/BookView';
import styles from './index.module.scss';

const BookContainer = props => {
  const { book } = props;

  return (
    <div className={styles.bookContainer}>
      {book && <BookView book={book} />}
    </div>
  );
};

export default withRouter(BookContainer);
