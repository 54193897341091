import React, { useEffect, useState, Fragment } from 'react';
import { useVideo } from 'customHooks/mediaElement';
import useModal from 'components/common/Modal/useModal';
import Playlist from 'components/common/Player/Playlist';
import SeekList from 'components/common/Player/SeekList';
import SubtitleControl from 'components/common/Player/SubtitleControl';
import VolumeBar from 'components/common/Player/VolumeBar';
import PlaybackRateControl from 'components/common/Player/PlaybackRateControl';
import TimeInfo from 'components/common/Player/TimeInfo';
import ProcessBar from 'components/common/Player/ProcessBar';
import ControlButton from 'components/common/Player/ControlButton';
import Icon from 'components/Icon';
import classnames from 'classnames';
import styles from './index.module.scss';

const VideoPlayer = ({
  className,
  src,
  poster,
  control = false,
  playlist = [],
  seekList = [],
  subtitles = [],
  onPlaylistChange = () => {}
}) => {
  const [currentLanguage, setLanguage] = useState(['en']);
  const [video, state, controls] = useVideo(
    <video src={src} playsInline crossOrigin="anonymous" poster={poster}>
      {subtitles.map(subtitle => (
        <Fragment key={subtitle.language}>
          {currentLanguage.includes(subtitle.language) && (
            <track
              kind="subtitles"
              label={subtitle.language}
              srcLang={subtitle.language}
              src={subtitle.src}
            />
          )}
        </Fragment>
      ))}
    </video>
  );

  useEffect(() => {
    setLanguage(state.language);
  }, [state.language]);

  const [, { closeModal }] = useModal();

  useEffect(() => {
    controls.play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return (
    <div className={classnames(styles.videoPlayer, styles.className)}>
      <div className={styles.close} onClick={closeModal}>
        <Icon type="text" name="timesCircle" />
      </div>
      <div className={styles.video}>
        {video}
        {control && (
          <div className={styles.controlBar}>
            <ProcessBar
              className={styles.processBar}
              currentTime={state.time}
              endTime={state.duration}
              onChange={controls.seek}
            />
            <div className={styles.left}>
              <ControlButton
                onClick={state.isPlaying ? controls.pause : controls.play}
              >
                {state.isPlaying ? (
                  <Icon type="text" name="pause" />
                ) : (
                  <Icon type="text" name="play" />
                )}
              </ControlButton>

              <VolumeBar
                state={state}
                controls={controls}
                onChange={val => controls.volume(val)}
              />

              <TimeInfo currentTime={state.time} endTime={state.duration} />

              <ControlButton onClick={() => controls.seek(state.time - 10)}>
                <Icon type="text" name="backward" />
              </ControlButton>
              <ControlButton onClick={() => controls.seek(state.time + 10)}>
                <Icon type="text" name="forward" />
              </ControlButton>
              <ControlButton onClick={() => controls.seek(0)}>
                <Icon type="text" name="undo" />
              </ControlButton>
            </div>
            <div className={styles.right}>
              {subtitles.length > 0 && (
                <SubtitleControl
                  value={state.language}
                  subtitles={subtitles}
                  onChange={controls.setSubtitlesLanguage}
                />
              )}
              <PlaybackRateControl
                value={state.playbackRate}
                onChange={controls.playbackRate}
              />
              <ControlButton onClick={controls.fullscreen}>
                <Icon type="text" name="expand" />
              </ControlButton>
            </div>
          </div>
        )}
      </div>
      {playlist.length > 0 && (
        <Playlist playlist={playlist} onChange={id => onPlaylistChange(id)} />
      )}
      {seekList.length > 0 && (
        <SeekList seekList={seekList} onChange={controls.seek} />
      )}
    </div>
  );
};

export default VideoPlayer;
