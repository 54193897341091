export const SideToolContent = {
    None: 'None',
    CanvasObjectPropertyViewer: 'CanvasObjectPropertyViewer',
    ReaderOptionPanel: 'ReaderOptionPanel',
    StampPanel: 'StampPanel'
};


export const SideToolDirection = {
    LEFT:'left',
    RIGHT:'right'
};
