import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';
import { useExternalWindowCommand } from 'customHooks/InteractiveObjectContentCommands/externalWindowCommand';
import { useInternalWindowCommand } from 'customHooks/InteractiveObjectContentCommands/internalWindowCommand';
import { useToggleDisplayCommand } from 'customHooks/InteractiveObjectContentCommands/toggleDisplayCommand';
import { useControlDisplayCommand } from 'customHooks/InteractiveObjectContentCommands/controlDisplayCommand';
import { useControlStageCommand } from 'customHooks/InteractiveObjectContentCommands/controlStageCommand';
import { useControlImageCommand } from 'customHooks/InteractiveObjectContentCommands/controlImageCommand';
import { useGoPageCommand } from 'customHooks/InteractiveObjectContentCommands/goPageCommand';
import { useVideoCommand } from 'customHooks/InteractiveObjectContentCommands/videoCommand';
import { useAudioCommand } from 'customHooks/InteractiveObjectContentCommands/audioCommand';
import { useAreaZoomCommand } from 'customHooks/InteractiveObjectContentCommands/areaZoomCommand';
import { useInternalHtmlWindowCommand } from 'customHooks/InteractiveObjectContentCommands/internalHtmlWindowCommand';
import { useVocabularyCommand } from 'customHooks/InteractiveObjectContentCommands/vocabularyCommand';
import { useWordGridCommand } from 'customHooks/InteractiveObjectContentCommands/wordGridCommand';
import { useSubmenuCommand } from 'customHooks/InteractiveObjectContentCommands/submenuCommand';
import { useWordExampleCommand } from 'customHooks/InteractiveObjectContentCommands/wordExampleCommand';
import { useWhiteboardCommand } from 'customHooks/InteractiveObjectContentCommands/whiteboardCommand';
import { useNumberPickerCommand } from 'customHooks/InteractiveObjectContentCommands/numberPickerCommand';
import { useSynopsisCommand } from 'customHooks/InteractiveObjectContentCommands/synopsisCommand'

export const useInteractiveObjectContentCommandFactory = () => {
    const ExternalWindowCommand = useExternalWindowCommand();
    const InternalWindowCommand = useInternalWindowCommand();
    const ToggleDisplayCommand = useToggleDisplayCommand();
    const ControlDisplayCommand = useControlDisplayCommand();
    const ControlStageCommand = useControlStageCommand();
    const ControlImageCommand = useControlImageCommand();
    const GoPageCommand = useGoPageCommand();
    const VideoCommand = useVideoCommand();
    const AudioCommand = useAudioCommand();
    const AreaZoomCommand = useAreaZoomCommand();
    const InternalHtmlWindowCommand = useInternalHtmlWindowCommand();
    const VocabularyCommand = useVocabularyCommand();
    const WordGridCommand = useWordGridCommand();
    const SubmenuCommand = useSubmenuCommand();
    const WordExampleCommand = useWordExampleCommand();
    const WhiteboardCommand = useWhiteboardCommand();
    const NumberPickerCommand = useNumberPickerCommand();
    const SynopsisCommand = useSynopsisCommand();

    const createCommand = ({ contentType }) => {
        switch (contentType) {
            case InteractiveObjectContentType.ExternalWindow:
                return ExternalWindowCommand;
            case InteractiveObjectContentType.InternalWindow:
                return InternalWindowCommand;
            case InteractiveObjectContentType.ToggleDisplay:
                return ToggleDisplayCommand;
            case InteractiveObjectContentType.ControlDisplay:
                return ControlDisplayCommand;
            case InteractiveObjectContentType.ControlStage:
                return ControlStageCommand;
            case InteractiveObjectContentType.ControlImage:
                return ControlImageCommand;
            case InteractiveObjectContentType.GoPage:
                return GoPageCommand;
            case InteractiveObjectContentType.Video:
                return VideoCommand;
            case InteractiveObjectContentType.Audio:
                return AudioCommand;
            case InteractiveObjectContentType.AreaZoom:
                return AreaZoomCommand;
            case InteractiveObjectContentType.InternalHtmlWindow:
                return InternalHtmlWindowCommand;
            case InteractiveObjectContentType.Vocabulary:
                return VocabularyCommand;
            case InteractiveObjectContentType.WordGrid:
                return WordGridCommand;
            case InteractiveObjectContentType.Submenu:
                return SubmenuCommand;
            case InteractiveObjectContentType.WordExample:
                return WordExampleCommand;
            case InteractiveObjectContentType.Whiteboard:
                return WhiteboardCommand;
            case InteractiveObjectContentType.NumberPicker:
                return NumberPickerCommand;
            case InteractiveObjectContentType.Synopsis:
                return SynopsisCommand;
            default:
                console.error(`ContentType ${contentType} is not supported`);
                return ;
        }
    };

    const CommandFactory = { createCommand };

    return CommandFactory;
};
