import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import * as types from 'constants/actionTypes';

export const initState = {
  style: {
    width: null,
    height: null,
    offsetX: null
  },
  books: [],
  bookId: '',
  catalog: [],
  bookContent: {},
  interactiveObjectJSON: {},
  interactiveObjectSVG: {}
};

const booksReducer = (state, action) => {
  switch (action.type) {
    case types.SET_BOOKS:
      return { ...state, books: action.payload };
    case types.SET_BOOK_ID:
      return { ...state, bookId: action.payload };
    case types.SET_BOOK_STYLES:
      return {
        ...state,
        style: { ...action.payload }
      };
    case types.SET_BOOK_CATALOG:
      return { ...state, catalog: action.catalog };
    case types.SET_BOOK_CONTENT:
      return { ...state, bookContent: { ...state.bookContent, ...action.bookContent } };
    case types.SET_INTERACTIVE_OBJECTS:
      return { ...state, interactiveObjectJSON: { ...state.interactiveObjectJSON, ...action.interactiveObjectJSON }, interactiveObjectSVG: { ...state.interactiveObjectSVG, ...action.interactiveObjectSVG } };
    case types.RESET_BOOK_CONTENT:
      return {
        ...state,
        bookId: initState.bookId,
        catalog: initState.catalog,
        bookContent: initState.bookContent,
        interactiveObjectJSON: initState.interactiveObjectJSON,
        interactiveObjectSVG: initState.interactiveObjectSVG
      };
    default:
      return state;
  }
};

const useBooksReducer = () => useEnhanceReducer(booksReducer, initState);

export default useBooksReducer;
