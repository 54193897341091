import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import { ReaderToolType } from 'constants/ReaderTools';
import { BaseReaderStrategy } from './BaseReaderStrategy';

const Blacklist = [
    ReaderToolType.OpenActivity,
    ReaderToolType.Bookshelf,
    ReaderToolType.Group,
    ReaderToolType.Activity,
    ReaderToolType.ExportInteractiveObject,
    ReaderToolType.SVG
];

export const useActiveStudentStrategy = () => {
    const [{ isActive }] = useStore(StoreTypes.canvas);

    const getReaderToolComponents = useCallback((readerToolComponents) => {
        return BaseReaderStrategy.getFilteredReaderToolComponents(readerToolComponents, Blacklist);
    }, []);

    const isReaderSwipeable = useCallback(() => {
        return !isActive;
    }, [isActive]);

    const isReaderSwitchPageAvailable = useCallback(() => {
        return true;
    }, []);

    return { name: 'ActiveStudentStrategy', getReaderToolComponents, isReaderSwipeable, isReaderSwitchPageAvailable };
};
