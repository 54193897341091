import React from 'react';
import useSetState from 'customHooks/setState';
import Icon from 'components/Icon';
import classnames from 'classnames';
import styles from './index.module.scss';

const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

const MAXIMUM = 99;
const MINIMUM = 0;

const NumberPicker = () => {
  const [{ min, max, number, isRandoming }, setState] = useSetState({
    min: 1,
    max: 30,
    number: 1,
    isRandoming: false
  });

  const generateNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const changeHandler = property => e => {
    const value = parseInt(e.target.value) ? parseInt(e.target.value) : 0;
    setState({ [property]: value });
  };

  const getInputs = () => {
    setState({ isRandoming: true });
    if (min > max) {
      const minTemp = max;
      const maxTemp = min;
      setState({
        min: minTemp,
        max: maxTemp,
        number: generateNumber(minTemp, maxTemp)
      });
    } else {
      setState({
        number: generateNumber(min, max)
      });
    }

    setTimeout(() => {
      setState({ isRandoming: false });
    }, 1000);
  };

  const maxChangeHandler = val => {
    const value = Math.max(MINIMUM, Math.min(val, MAXIMUM));
    setState({ max: value });
  };

  const minChangeHandler = val => {
    const value = Math.max(MINIMUM, Math.min(val, MAXIMUM));
    setState({ min: value });
  };

  return (
    <div className={styles.numberPicker}>
      <div className={styles.generator}>
        <div className={styles.title}>選號器</div>
        <div className={styles.numWrapper}>
          {isRandoming ? (
            <div className={classnames(styles.randoming, styles.animated)}>
              <ul className={classnames(styles.digits, styles.luckie)}>
                {digits.map(digit => (
                  <li key={digit}>{digit}</li>
                ))}
              </ul>
              <ul className={classnames(styles.digits, styles.luckie)}>
                {digits.reverse().map(digit => (
                  <li key={digit}>{digit}</li>
                ))}
              </ul>
            </div>
          ) : (
            <div className={styles.rNum}>{number}</div>
          )}
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.headers}>
            <p>最小</p>
            <p>最大</p>
          </div>
          <div className={styles.inputs}>
            <div className={styles.minInput}>
              <span
                onClick={() => {
                  minChangeHandler(min - 1);
                }}
              >
                <Icon type="text" name="angleLeft" />
              </span>
              <input
                className={styles.min}
                min={MINIMUM}
                max={MAXIMUM}
                type="number"
                value={min}
                onChange={changeHandler('min')}
              />
              <span
                onClick={() => {
                  minChangeHandler(min + 1);
                }}
              >
                <Icon type="text" name="angleRight" />
              </span>
            </div>
            <div className={styles.maxInput}>
              <span
                onClick={() => {
                  maxChangeHandler(max - 1);
                }}
              >
                <Icon type="text" name="angleLeft" />
              </span>
              <input
                className={styles.max}
                min={MINIMUM}
                max={MAXIMUM}
                type="number"
                value={max}
                onChange={changeHandler('max')}
              />
              <span
                onClick={() => {
                  maxChangeHandler(max + 1);
                }}
              >
                <Icon type="text" name="angleRight" />
              </span>
            </div>
            <input
              className={styles.generate}
              disabled={isRandoming}
              type="submit"
              value="開始選號"
              onClick={() => getInputs()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberPicker;
