import React, { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import styles from './index.module.scss';
import Button from 'components/Button';
import useModal from 'components/common/Modal/useModal';
import Icon from 'components/Icon';
import FourZoomIn from "components/Icon/FourZoomIn";
import PainterPanel from 'components/Painter/PainterPanel';
// import Painter from 'components/Painter/index';
import { ReaderToolType, SideBarType } from 'constants/ReaderTools';
import { useStore, StoreTypes } from 'context';
import { useMedia } from 'customHooks/media';
import { useReaderStrategyDecider } from 'customHooks/Strategies/ReaderStrategies';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent, PainterEvent } from 'events/EventTypes';
import { useInteractiveObjectContentCommandFactory } from 'customHooks/InteractiveObjectContentCommands/commandFactory';
import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';
import toolsEventMap from 'components/ReaderTools/toolsEventMap';



// import { html } from 'assets/popWinData/popWin.json'

// object style test
// import chtVertical1 from "components/ExtendedContent/ExtendedContentContainer/mockData/chtVertical1.json";
// import chtVertical2 from "components/ExtendedContent/ExtendedContentContainer/mockData/chtVertical2.json";
// import chtImage from "components/ExtendedContent/ExtendedContentContainer/mockData/chtImage.json";
// import math from "components/ExtendedContent/ExtendedContentContainer/mockData/math.json";
// import topic1 from "components/ExtendedContent/ExtendedContentContainer/mockData/topic1.json";
// import topic2 from "components/ExtendedContent/ExtendedContentContainer/mockData/topic2.json";
// import topic3 from "components/ExtendedContent/ExtendedContentContainer/mockData/topic3.json";
// import topic4 from "components/ExtendedContent/ExtendedContentContainer/mockData/topic4.json";

// const MobileToolsModal = () => <div></div>;
const MobileTools = () => {
  const [, { openModal }] = useModal();
  return <div className={styles.mobileReaderTools} onClick={openModal} />;
};
const PanelState = {
  None: 'None',
  Pen: 'Pen',
  Highlighter: 'Highlighter',
  Shape: 'Shape',
  Line: 'Line'
};

const ReaderTools = props => {
  const reducers = useStore();
  const [{ isDoublePageMode }] = reducers[StoreTypes.reader];
  const [{ books, bookId }] = useStore(StoreTypes.books);
  const [{ annotationId, activityInfo: { attendeeNumber } }] = reducers[StoreTypes.annotation];
  const device = useMedia();
  const [{ readerToolColor }] = reducers[StoreTypes.reader];
  const decider = useReaderStrategyDecider();
  const strategy = decider.getReaderStrategy();
  const [{ painterToolType, painterTool, fillType }] = reducers[StoreTypes.canvas];
  const book = books.find(book => book.bookId === bookId);
  const { LRFlip } = book || {};

  const [panelState, setPanelState] = useState(PanelState.None);

  const togglePageSearcherHandler = useCallback(
    sideBarType => e => {
      EventBus.emit({
        event: ReaderToolsEvent.TogglePageSearcherEvent
      });
    },
    []
  );

  const toggleSideBarHandler = useCallback(
    sideBarType => e => {
      EventBus.emit({
        event: ReaderToolsEvent.ToggleSideBarEvent,
        payload: { sideBarType }
      });
    },
    []
  );

  const panelCloseHandler = useCallback(() => setPanelState(PanelState.None), []);

  useEffect(() => {
    (async () => {
      if (bookId) {
        EventBus.emit({
          event: PainterEvent.LoadReaderToolSettingsEvent
        });
      }
    })();
  }, [bookId])

  const onColorChange = (color) => {
    EventBus.emit({ event: PainterEvent.ChangeBrushColorEvent, payload: { color } });
  };

  const onLineWidthChange = lineWidth => {
    EventBus.emit({ event: PainterEvent.ChangeBrushWidthEvent, payload: { lineWidth } });
  };

  const onPainterChangeHandler = useCallback(painterType => e => {
    EventBus.emit({ event: PainterEvent.ChangePainterTypeEvent, payload: { painterType } });
  }, []);


  const onBrushChange = useCallback(e => {
    EventBus.emit({ event: PainterEvent.ChangeBrushTypeEvent, payload: { brushType: e.target.value } });
  }, []);

  const onFillTypeChangeHandler = useCallback(fillType => {
    EventBus.emit({ event: PainterEvent.ChangeShapeFillTypeEvent, payload: { fillType } });
  }, []);

  const CommandFactory = useInteractiveObjectContentCommandFactory();

  const openWhiteboardHandler = useCallback(() => {
    const command = CommandFactory.createCommand({ contentType: InteractiveObjectContentType.Whiteboard });
    command && command.execute();
  }, [CommandFactory]);

  const openNumberPickerHandler = useCallback(() => {
    const command = CommandFactory.createCommand({ contentType: InteractiveObjectContentType.NumberPicker });
    command && command.execute();
  }, [CommandFactory])

  return (
    <div id="Reader-Tools" style={{ backgroundColor: readerToolColor }}>
      <div
        className={classnames(
          styles.readerTools,
          styles[props.className]
        )}
      >

        {panelState === PanelState.Pen &&
          <PainterPanel.Pen
            painterTool={painterTool[painterToolType]}
            onPanelClose={panelCloseHandler}
            onLineWidthChange={onLineWidthChange}
            onColorChange={onColorChange}
            onPainterHandler={onPainterChangeHandler}
            onBrushChange={onBrushChange}
          />
        }
        {panelState === PanelState.Highlighter &&
          <PainterPanel.Pen
            painterTool={painterTool[painterToolType]}
            onPanelClose={panelCloseHandler}
            onLineWidthChange={onLineWidthChange}
            onColorChange={onColorChange}
            onPainterHandler={onPainterChangeHandler}
            onBrushChange={onBrushChange}
          />}
        {panelState === PanelState.Shape &&
          <PainterPanel.Shape
            painterTool={painterTool[painterToolType]}
            onPanelClose={panelCloseHandler}
            onLineWidthChange={onLineWidthChange}
            onColorChange={onColorChange}
            onShapeChange={onPainterChangeHandler}
            onFillTypeChange={onFillTypeChangeHandler}
            shapeFillTypeValue={fillType}
          />}
        {panelState === PanelState.Line &&
          <PainterPanel.Line
            painterTool={painterTool[painterToolType]}
            onPanelClose={panelCloseHandler}
            onLineWidthChange={onLineWidthChange}
            onColorChange={onColorChange}
            onLineTypeChange={onPainterChangeHandler}
          />}
        <div className={styles.left}>
          <Button key={ReaderToolType.Menu}
            onClick={toolsEventMap[ReaderToolType.Menu].clickHandler}
            title={toolsEventMap[ReaderToolType.Menu].title}
          >
            <Icon name="ContentBack" />
            <div className={styles.text}>{toolsEventMap[ReaderToolType.Menu].title}</div>
          </Button>
          {strategy && strategy.getReaderToolComponents({
            [ReaderToolType.Menu]:
              <Button
                key={ReaderToolType.Menu}
                onClick={toggleSideBarHandler(SideBarType.LEFT)}
                title="目次"
              >
                <Icon name="Content" />
                <div className={styles.text}>目次</div>
              </Button>,
            [ReaderToolType.Bookshelf]:
              <Button key={ReaderToolType.Bookshelf}>
                <Link to={`/bookshelf`}>
                  <Icon name="Bookcase" />
                  <div className={styles.text}>書櫃</div>
                </Link>
              </Button>
          }).map(component => component)}
        </div>
        <div className={styles.middle}>
          {strategy && strategy.getReaderToolComponents({
            [ReaderToolType.Mark]:
              <Button key={ReaderToolType.Mark}
                onClick={toolsEventMap[ReaderToolType.Mark].clickHandler}
                title={toolsEventMap[ReaderToolType.Mark].title}
              >
                <Icon name="Mark" />
                <div className={styles.text}>{toolsEventMap[ReaderToolType.Mark].title}</div>
              </Button>,
            [ReaderToolType.Drag]:
              <Button key={ReaderToolType.Drag}
                onClick={toolsEventMap[ReaderToolType.Drag].clickHandler}
                title={toolsEventMap[ReaderToolType.Drag].title}
              >
                <Icon name="MouseHand" />
                <div className={styles.text}>{toolsEventMap[ReaderToolType.Drag].title}</div>
              </Button>,
            // [ReaderToolType.Painter]:
            //     < Painter key={ReaderToolType.Painter}
            //         props={props.className}
            //     />,
            [ReaderToolType.Select]:
              <Button
                key={ReaderToolType.Select}
                onClick={toolsEventMap[ReaderToolType.Select].clickHandler}
                title={toolsEventMap[ReaderToolType.Select].title}
              >
                <Icon name='Select' />
                <div className={styles.text}>{toolsEventMap[ReaderToolType.Select].title}</div>
              </Button>,
            [ReaderToolType.Painting]:
              <div key={ReaderToolType.Painting} className={styles.toolBtn}>
                <Button
                  onClick={() => {
                    toolsEventMap[ReaderToolType.Painting].clickHandler();
                    setPanelState(PanelState.Pen);
                  }}
                  title={toolsEventMap[ReaderToolType.Painting].title}
                >
                  <Icon name='Painting' />
                  <div className={styles.text}>{toolsEventMap[ReaderToolType.Painting].title}</div>
                </Button>
              </div>,
            [ReaderToolType.Highlighter]:
              <div key={ReaderToolType.Highlighter} className={styles.toolBtn}>
                <Button
                  onClick={() => {
                    toolsEventMap[ReaderToolType.Highlighter].clickHandler()
                    setPanelState(PanelState.Highlighter);
                  }}
                  title={toolsEventMap[ReaderToolType.Highlighter].title}
                >
                  <Icon name='Highlighter' />
                  <div className={styles.text}>{toolsEventMap[ReaderToolType.Highlighter].title}</div>
                </Button>
              </div>,
            [ReaderToolType.Shape]:
              <div key={ReaderToolType.Shape} className={styles.toolBtn}>
                <Button
                  onClick={() => {
                    toolsEventMap[ReaderToolType.Shape].clickHandler();
                    setPanelState(PanelState.Shape);
                  }}
                  title='形狀'
                >
                  <Icon name='Shape' />
                  <div className={styles.text}>{toolsEventMap[ReaderToolType.Shape].title}</div>
                </Button>
              </div>,
            [ReaderToolType.Line]:
              <div key={ReaderToolType.Line} className={styles.toolBtn}>
                <Button
                  onClick={() => {
                    toolsEventMap[ReaderToolType.Line].clickHandler();
                    setPanelState(PanelState.Line);
                  }}
                  title={toolsEventMap[ReaderToolType.Line].title}
                >
                  <Icon name='Line' />
                  <div className={styles.text}>{toolsEventMap[ReaderToolType.Line].title}</div>
                </Button>
              </div>,
            [ReaderToolType.DeleteAll]:
              <Button
                key={ReaderToolType.DeleteAll}
                onClick={toolsEventMap[ReaderToolType.DeleteAll].clickHandler}
                title={toolsEventMap[ReaderToolType.DeleteAll].title}
              >
                <Icon name='DeleteAll' />
                <div className={styles.text}>{toolsEventMap[ReaderToolType.DeleteAll].title}</div>
              </Button>,
            [ReaderToolType.Delete]:
              <Button
                key={ReaderToolType.Delete}
                onClick={toolsEventMap[ReaderToolType.Delete].clickHandler}
                title={toolsEventMap[ReaderToolType.Delete].title}
              >
                <Icon name='Delete' />
                <div className={styles.text}>{toolsEventMap[ReaderToolType.Delete].title}</div>
              </Button>,
            [ReaderToolType.ArrowLeft]:
              <Button
                key={ReaderToolType.ArrowLeft}
                onClick={() => toolsEventMap[ReaderToolType.ArrowLeft].clickHandler(book, LRFlip)}
                title={toolsEventMap[ReaderToolType.ArrowLeft].title}
              >
                <Icon name='ArrowLeft' />
                <div className={styles.text}>{toolsEventMap[ReaderToolType.ArrowLeft].title}</div>
              </Button>,
            [ReaderToolType.PageSercher]:
              <Button
                key={ReaderToolType.PageSercher}
                onClick={togglePageSearcherHandler(SideBarType.LEFT)}
                title='頁數選擇'
              >
                <Icon name='PageSercher' />
                <div className={styles.text}>頁數選擇</div>
              </Button>,
            [ReaderToolType.ArrowRight]:
              <Button
                key={ReaderToolType.ArrowRight}
                onClick={() => toolsEventMap[ReaderToolType.ArrowRight].clickHandler(book, LRFlip)}
                title={toolsEventMap[ReaderToolType.ArrowRight].title}
              >
                <Icon name='ArrowRight' />
                <div className={styles.text}>{toolsEventMap[ReaderToolType.ArrowRight].title}</div>
              </Button>,
            [ReaderToolType.FourZoomIn]:
              <Button
                key={ReaderToolType.FourZoomIn}
                title={toolsEventMap[ReaderToolType.FourZoomIn].title}
                width="7vh"
              >
                <FourZoomIn />
                <div className={styles.text}>{toolsEventMap[ReaderToolType.FourZoomIn].title}</div>
              </Button>,
            [ReaderToolType.AllScreen]:
              <Button
                key={ReaderToolType.AllScreen}
                onClick={toolsEventMap[ReaderToolType.AllScreen].clickHandler}
                title={toolsEventMap[ReaderToolType.AllScreen].title}
              >
                <Icon name='AllScreen' />
                <div className={styles.text}>{toolsEventMap[ReaderToolType.AllScreen].title}</div>
              </Button>,
            /*[ReaderToolType.FullWidth]:
                <Button
                    key={ReaderToolType.FullWidth}
                    onClick={() => EventBus.emit({ event: ReaderToolsEvent.ClickFullWidthEvent })}
                    title='延伸'
                >
                    <Icon name='Extend' />
                    <div className={styles.text}>延伸</div>
                </Button>,*/

            [ReaderToolType.IntervalZoomIn]:
              <Button
                key={ReaderToolType.IntervalZoomIn}
                onClick={toolsEventMap[ReaderToolType.IntervalZoomIn].clickHandler}
                title={toolsEventMap[ReaderToolType.IntervalZoomIn].title}
              >
                <Icon name='IntervalZoomIn' />
                <div className={styles.text}>{toolsEventMap[ReaderToolType.IntervalZoomIn].title}</div>
              </Button>,
            [ReaderToolType.Whiteboard]:
              <Button
                key={ReaderToolType.Whiteboard}
                onClick={() => openWhiteboardHandler()}
                title={toolsEventMap[ReaderToolType.Whiteboard].title}
              >
                <Icon name='Whiteboard' />
                <div className={styles.text}>{toolsEventMap[ReaderToolType.Whiteboard].title}</div>
              </Button>,
            [ReaderToolType.NumberPicker]:
              < Button
                key={ReaderToolType.NumberPicker}
                onClick={() => openNumberPickerHandler()}
                title={toolsEventMap[ReaderToolType.NumberPicker].title}
              >
                <Icon name='NumberPicker' />
                <div className={styles.text}>{toolsEventMap[ReaderToolType.NumberPicker].title}</div>
              </Button>,
            [ReaderToolType.Img]:
              <Button key={ReaderToolType.Img}>
                <label className={styles.addBtn}>
                  <Icon name='Img' />
                  <div className={styles.text}>{toolsEventMap[ReaderToolType.Img].title}</div>
                  <input
                    className={styles.addFileChange}
                    type='button'
                    onClick={toolsEventMap[ReaderToolType.Img].clickHandler}
                    title={toolsEventMap[ReaderToolType.Img].title}
                  />
                </label>
              </Button>,
            [ReaderToolType.Text]:
              <Button key={ReaderToolType.Text}>
                <label className={styles.addBtn}>
                  <Icon name='Text' />
                  <div className={styles.text}>{toolsEventMap[ReaderToolType.Text].title}</div>
                  <input
                    className={styles.addFileChange}
                    type='button'
                    onClick={toolsEventMap[ReaderToolType.Text].clickHandler}
                    title={toolsEventMap[ReaderToolType.Text].title}
                  />
                </label>
              </Button>,
            [ReaderToolType.SVG]:
              <Button key={ReaderToolType.SVG} onClick={toolsEventMap[ReaderToolType.SVG].clickHandler}>
                <Icon name="SVG" />
                <div className={styles.text}>{toolsEventMap[ReaderToolType.SVG].clickHandler}</div>
              </Button>,
            [ReaderToolType.PageMode]:
              <Button key={ReaderToolType.PageMode}
                onClick={() => toolsEventMap[ReaderToolType.PageMode].clickHandler(isDoublePageMode)}
                title={isDoublePageMode ? '單頁' : '雙頁'}
              >
                {isDoublePageMode ? <Icon name="OnePage" /> : <Icon name="DoublePage" />}
                <div className={styles.text}>{isDoublePageMode ? '單頁' : '雙頁'}</div>
              </Button>,
          }).map(component => component)}
        </div>
        <div className={styles.right}>
          {strategy && strategy.getReaderToolComponents({
            [ReaderToolType.AttendeeNumber]:
              <div key={ReaderToolType.AttendeeNumber} title="座號">
                {(attendeeNumber === "admin") ? "老師" : attendeeNumber}
              </div>,
            [ReaderToolType.ReaderOption]:
              <Button key={ReaderToolType.ReaderOption}
                onClick={toolsEventMap[ReaderToolType.ReaderOption].clickHandler}
                title={toolsEventMap[ReaderToolType.ReaderOption].clickHandler}
              >
                <Icon name="Setup" />
                <div className={styles.text}>{toolsEventMap[ReaderToolType.ReaderOption].clickHandler}</div>
              </Button>,
            [ReaderToolType.Bookmark]:
              <Button key={ReaderToolType.Bookmark} onClick={toolsEventMap[ReaderToolType.Bookmark].clickHandler} title={toolsEventMap[ReaderToolType.Bookmark].clickHandler}>
                <Icon name="Bookmark" />
                <div className={styles.text}>{toolsEventMap[ReaderToolType.Bookmark].clickHandler}</div>
              </Button>,
            [ReaderToolType.Activity]:
              <Button key={ReaderToolType.Activity} onClick={toolsEventMap[ReaderToolType.Activity].clickHandler}>
                <div className={styles.text}>{toolsEventMap[ReaderToolType.Activity].title}</div>
              </Button>,
            /*[ReaderToolType.OpenActivity]:
                <Button key={ReaderToolType.OpenActivity} onClick={() => openActivity()}>
                    <div className={styles.text}>開啟活動</div>
                </Button>,*/
            [ReaderToolType.ExportInteractiveObject]:
              <Button key={ReaderToolType.ExportInteractiveObject}
                title={toolsEventMap[ReaderToolType.ExportInteractiveObject].title}
                onClick={toolsEventMap[ReaderToolType.ExportInteractiveObject].clickHandler}>
                <Icon name="Save" />
                <div className={styles.text}>{toolsEventMap[ReaderToolType.ExportInteractiveObject].title}</div>
              </Button>,
            /*[ReaderToolType.RightSideBar]:
                <Button key={ReaderToolType.RightSideBar}
                    onClick={toggleSideBarHandler(SideBarType.RIGHT)}
                >
                    {'＜'}
                </Button>*/
          }).map(component => component)}
        </div>
      </div>

    </div>
  );
};

export default ReaderTools;
