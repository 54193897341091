import React, { useCallback, useState } from 'react';
import Input from 'components/common/Input';
import styles from './index.module.scss';
import DialogModule from 'components/Dialogs';
import Button from '@material-ui/core/Button';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import { ExtraFieldsForEditor } from 'constants/canvas';

const dialogStatusTypes = {
    add: 'add',
    editor: 'editor'
}

export const SynopsisComponent = ({ object }) => {
    const synopsisInfo = object.synopsisInfo ? JSON.parse(object.synopsisInfo) : {
        tab: 1,
        list: []
    };
    const { tab, list } = synopsisInfo;
    const [isDialog, setDialog] = useState(false);
    const [nameInput, setNameInput] = useState("");
    const [srcInput, setSrcInput] = useState("");
    const [listItemIndex, setListItemIndex] = useState(0);
    const [dialogType, setDialogType] = useState("");

    const dialogContent = <div>
        {"Name"}
        <Input className={styles.menuPropertyInput} value={nameInput} onChange={(e) => nameChangeHandler(e.target.value)} />
        {"Src"}
        <Input className={styles.menuPropertyInput} value={srcInput} onChange={(e) => linkChangeHandler(e.target.value)} />
    </div>;

    const dialogAction = <div>
        <Button
            onClick={() => {
                setDialog(false)
            }}
            color="primary"
        >
            取消
        </Button>
        <Button
            onClick={() => {
                setDialog(false)
                if (!nameInput) return;
                if (dialogType === dialogStatusTypes.add) {
                    const item = { name: nameInput, src: srcInput ? srcInput : 0 };
                    list.push(item);
                } else {
                    list[listItemIndex].name = nameInput;
                    list[listItemIndex].src = srcInput ? srcInput : 0;
                }
                setDialogType("")
                list.sort((a, b) => a.time - b.time)
                inputTextHandler("list", list);
            }}
            color="primary" autoFocus
        >
            確定
        </Button>
    </div>

    const nameChangeHandler = ((value) => {
        setNameInput(value)
    });

    const linkChangeHandler = ((value) => {
        setSrcInput((value))
    });

    const inputTextHandler = useCallback((property, value) => {
        synopsisInfo[property] = value;
        EventBus.emit({ event: CanvasEvent.ModifyCanvasObjectPropertyEvent, payload: { object, property: ExtraFieldsForEditor.SynopsisInfo, value: JSON.stringify(synopsisInfo) } });
    }, [object, synopsisInfo]);

    const addListClick = useCallback(() => {
        setNameInput("")
        setSrcInput("")
        setDialogType(dialogStatusTypes.add)
        setDialog(true)
    }, [])

    const delListItemClick = useCallback((index) => {
        list.splice(index, 1)
        list.sort((a, b) => a.time - b.time)
        inputTextHandler("list", list);
    }, [inputTextHandler, list])

    const editorListItemClick = useCallback((index) => {
        setNameInput(list[index].name)
        setSrcInput(list[index].src)
        setListItemIndex(index)
        setDialogType(dialogStatusTypes.editor)
        setDialog(true)
    }, [list])

    const listItemChangeHandler = useCallback((property, value, index) => {
        list[index][property] = (value)
        list.sort((a, b) => a.time - b.time)
        inputTextHandler("list", list);
    }, [inputTextHandler, list])

    return (<div>
        <hr></hr>
        <DialogModule
            open={isDialog}
            setOpen={setDialog}
            content={dialogContent}
            action={dialogAction}
        />
        {"頁數"}
        <Input className={styles.videoInfoInput} value={tab} onChange={(e) => inputTextHandler("tab", e.target.value)} />
        <label></label><Button size="small" variant="contained" color="primary" onClick={() => { addListClick() }}>增加</Button>
        {
            list && list.map((lists, index) =>
                <div key={index} className={styles.list}>
                    {"Name"}
                    <Input disabled className={styles.videoInfoInput} value={lists.name} onChange={(e) => listItemChangeHandler("name", e.target.value, index)} />
                    {'Src'}
                    <Input disabled className={styles.videoInfoInput} value={lists.src} onChange={(e) => listItemChangeHandler("src", e.target.value, index)} />
                    <Button size="small" variant="contained" color="default" onClick={() => { delListItemClick(index) }} >刪除</Button>{" "}
                    <Button size="small" variant="contained" color="default" onClick={() => { editorListItemClick(index) }} >修改</Button>
                </div>
            )
        }
    </div>);
}
