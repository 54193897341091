import React, { useEffect, useCallback, useLayoutEffect, useRef } from 'react';
import { useVideo } from 'customHooks/mediaElement';
import RadicalInfoBar from './RadicalInfoBar';
import WordsVideoControl from './WordsVideoControl';
import WordsMovieButtonGroup from './WordsMovieButtonGroup';
import WordsMeaningButtonGroup from './WordsMeaningButtonGroup';
import WordRadical from 'components/WordRadical';
import AudioButton from 'components/common/AudioButton';
import MusicPlayer from 'components/common/MusicPlayer';
import useSetState from 'customHooks/setState';
import { mediaType } from 'constants/mediaType';
import { isExist } from 'util/helper';
import { useStore, StoreTypes } from 'context';
import { API } from 'api';
import classnames from 'classnames';
import styles from './index.module.scss';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

const WordLearningGridComponent = ({
  data,
  wordIndex,
  goToNextWordIndex,
  onRadicalClick
}) => {
  const repeatIndexRef = useRef(0);
  const [{ bookId }] = useStore(StoreTypes.books);
  const [
    {
      RadicalWord: radical,
      RadicalNumber: radicalNumber,
      AllNumber: allNumber,
      RestNumber: restNumber,
      Phonetic: phonetic,
      WordMovieSet,
      MeaningSet,
      WordsSet,
      IdiomSet,
      GlyphSet
    },
    setDataState
  ] = useSetState(data);

  const [
    {
      videoAutoPlay,
      videoSrc,
      videoPointSet,
      currentPointSetIndex,
      currentVideoIndex,
      speed,
      isRepeat,
      wordsMeaningSet,
      currentWordsMeaningIndex,
      isPlayAllWords,
      gridValue,
      isSwitching
    },
    setState
  ] = useSetState({
    videoAutoPlay: false,
    videoSrc: WordMovieSet[0].Path || '',
    videoPointSet: WordMovieSet[0].MoviePointSet || [],
    currentPointSetIndex: 0,
    currentVideoIndex: 0,
    speed: 1,
    isRepeat: false,
    wordsMeaningSet: [],
    currentWordsMeaningIndex: null,
    isPlayAllWords: false,
    gridValue: null,
    isSwitching: false
  });

  const [video, state, controls] = useVideo(
    <video
      src={`${ExtendedResourceRepository.getDataSrc({
        bookId,
        pathName: videoSrc
      })}`}
      autoPlay={videoAutoPlay}
      playsInline
      crossOrigin="anonymous"
    />
  );

  const repeatButtonClickHanlder = useCallback(() => {
    setState({ isRepeat: true });
    repeatIndexRef.current = Math.max(currentPointSetIndex - 1, 0);
    const prevPointTime = videoPointSet[repeatIndexRef.current];
    controls.seek(prevPointTime);
    controls.play();
  }, [controls, currentPointSetIndex, setState, videoPointSet]);

  useEffect(() => {
    const endPointTime = videoPointSet[Math.max(repeatIndexRef.current + 1, 1)];
    if (isRepeat && state.time > endPointTime) {
      controls.pause();
      controls.seek(videoPointSet[repeatIndexRef.current]);
      setState({ isRepeat: false });
    }
  }, [controls, isRepeat, setState, state.time, videoPointSet]);

  useLayoutEffect(() => {
    // 需求為 延遲播放不跟音檔重疊
    setTimeout(() => {
      isPlayAllWords && controls.play();
    }, 2000);
  }, [controls, isPlayAllWords, videoSrc]);

  // 隨著播放時間 更新 point 的 index
  useEffect(() => {
    if (videoPointSet.length < 1) return;
    let pointIndex = currentPointSetIndex;
    let pointTime = videoPointSet[currentPointSetIndex];
    if (!isRepeat && state.isPlaying && state.time >= pointTime) {
      pointIndex = Math.min(pointIndex + 1, videoPointSet.length - 1);
      setState({
        currentPointSetIndex: pointIndex
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.time,
    state.isPlaying,
    currentPointSetIndex,
    videoPointSet,
    setState
  ]);

  // 自動播放下一個字
  useEffect(() => {
    if (state.buffered.length < 1) return;
    if (state.time >= state.buffered[0].end && isPlayAllWords && !isSwitching) {
      setState({ isSwitching: true });
      goToNextWordIndex();
      // TODO: shuold implement useVideo onload listener to handle this
      setTimeout(() => {
        setState({ isSwitching: false });
      }, 500);
    }
  }, [
    state.time,
    state.buffered,
    wordIndex,
    isPlayAllWords,
    goToNextWordIndex,
    isSwitching,
    setState
  ]);

  const wordMovieButtonHandler = ({ index, value }) => {
    setState({
      videoAutoPlay: true,
      videoSrc: value,
      currentVideoIndex: index,
      videoPointSet: WordMovieSet[index].MoviePointSet,
      currentPointSetIndex: 0,
      currentWordsMeaningIndex: null,
      isRepeat: false
    });
  };

  const meaningButtonHandler = ({ index, value }) => {
    setState({
      wordsMeaningSet: value,
      currentWordsMeaningIndex: index,
      currentVideoIndex: null,
      isRepeat: false
    });
  };

  const pointSetChangeHandler = useCallback(
    type => {
      let pointIndex = currentPointSetIndex;
      if (type === mediaType.PREVIOUS) {
        pointIndex = Math.max(pointIndex - 1, 0);
      } else {
        pointIndex = Math.min(pointIndex + 1, videoPointSet.length - 1);
      }

      setState({
        currentPointSetIndex: pointIndex
      });
      controls.pause();
      controls.seek(videoPointSet[pointIndex]);
    },
    [currentPointSetIndex, setState, controls, videoPointSet]
  );

  const playbackRateChangeHandler = useCallback(
    speed => {
      controls.playbackRate(speed);
      setState({ speed });
    },
    [controls, setState]
  );

  const play = useCallback(() => {
    state.isPlaying ? controls.pause() : controls.play();
    setState({ isRepeat: false });
  }, [controls, setState, state.isPlaying]);

  useEffect(() => {
    playbackRateChangeHandler(speed);
  }, [videoSrc, playbackRateChangeHandler, speed]);

  useEffect(() => {
    setDataState(data);
    setState({
      videoAutoPlay: false,
      videoSrc: isPlayAllWords
        ? WordMovieSet[currentVideoIndex].Path
        : WordMovieSet[0].Path || '',
      videoPointSet: WordMovieSet[0].MoviePointSet || [],
      currentPointSetIndex: 0,
      currentVideoIndex: isPlayAllWords ? currentVideoIndex : 0,
      isRepeat: false,
      audioSrc: '',
      wordsMeaningSet: [],
      currentWordsMeaningIndex: null
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [WordMovieSet, setState, data, setDataState, isPlayAllWords]);

  const onPlayAllWordsClick = status => {
    setState({ isPlayAllWords: status });
  };

  return (
    <>
      <div className={styles.center}>
        {isExist(currentVideoIndex) && (
          <>
            <div className={classnames(styles.video, [styles[gridValue]])}>
              {video}
              {phonetic && (
                <div className={styles.phonetic}>
                  <img
                    src={ExtendedResourceRepository.getDataSrc({
                      bookId,
                      pathName: phonetic
                    })}
                    alt=""
                  />
                </div>
              )}
            </div>
            <RadicalInfoBar
              radical={radical}
              radicalNumber={radicalNumber}
              allNumber={allNumber}
              restNumber={restNumber}
              onRadicalClick={onRadicalClick}
            />
          </>
        )}
        {isExist(currentWordsMeaningIndex) && wordsMeaningSet.length > 0 && (
          <div className={styles.image}>
            {wordsMeaningSet.map(item => (
              <div className={styles.wrapper}>
                <img
                  src={`${ExtendedResourceRepository.getDataSrc({
                    bookId,
                    pathName: item.Path
                  })}`}
                  alt=""
                />
                {item.Audio && (
                  <div>
                    <MusicPlayer
                      autoPlay={false}
                      src={`${ExtendedResourceRepository.getDataSrc({
                        bookId,
                        pathName: item.Audio
                      })}`}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        )
        // <div
        //   className={styles.image}
        //   style={{backgroundImage:`url(${CDN_DOMAIN}/data/${bookId}/${wordsMeaningSet[0].Path})`}}
        // />
        }
      </div>
      <div className={styles.right}>
        <section>
          <div className={styles.title}>
            <span>教學方式</span>
            <img src="/assets/img/wordGridTitleBg.svg" alt="" />
          </div>
          <WordsMovieButtonGroup
            data={WordMovieSet}
            activeIndex={currentVideoIndex}
            onChange={wordMovieButtonHandler}
            onPlayAllWordsClick={onPlayAllWordsClick}
          />
        </section>
        <section className={styles.videoTools}>
          <WordsVideoControl
            videoState={state}
            playButtonClick={play}
            previousButtonClick={() =>
              pointSetChangeHandler(mediaType.PREVIOUS)
            }
            previousButtonDiabled={
              currentPointSetIndex === 0 || videoPointSet.length < 1
            }
            nextButtonClick={() => pointSetChangeHandler(mediaType.NEXT)}
            nextButtonDiabled={
              currentPointSetIndex === videoPointSet.length - 1 ||
              videoPointSet.length < 1
            }
            repeatDisabled={isRepeat}
            repeatButtonClick={() => repeatButtonClickHanlder()}
            playbackRate={speed}
            onChangePlaybackRate={playbackRateChangeHandler}
          />
        </section>
        <section>
          <div className={styles.title}>
            <span>延伸教學</span>
            <img src="/assets/img/wordGridTitleBg.svg" alt="" />
          </div>
          <WordsMeaningButtonGroup
            activeIndex={currentWordsMeaningIndex}
            onChange={meaningButtonHandler}
            data={{ MeaningSet, WordsSet, IdiomSet, GlyphSet }}
          />
        </section>
        {/*<section>
            <div className={styles.title}>
              <span>十字格線</span>
              <img src="/assets/img/wordGridTitleBg.svg" alt=""/>
            </div>
            <GridButtonGroup onChange={value=> setState({ gridValue: value })} value={gridValue} />
          </section>*/}
      </div>
    </>
  );
};

const WordLearningGrid = ({ dataList = [], defaultWord }) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [
    { list, currentWordIndex, isRadicalShow, radicalData },
    setState
  ] = useSetState({
    list: [],
    currentWordIndex: 0,
    isRadicalShow: false,
    radicalData: null
  });

  useEffect(() => {
    const index = dataList.findIndex(word => {
      return word.Name === defaultWord;
    });
    setState({ currentWordIndex: index > -1 ? index : 0 });
  }, [dataList, defaultWord, setState]);

  useEffect(() => {
    Promise.all(
      dataList.map(word =>
        ExtendedResourceRepository.getJSONContent({
          bookId,
          pathName: word.Path
        })
      )
    ).then(list => {
      setState({ list: list.filter(item => item) });
    });
  }, [bookId, dataList, setState]);

  const goToNextWordIndex = useCallback(() => {
    const maxIndex = dataList.length - 1;
    const nextIndex = currentWordIndex + 1;
    if (maxIndex >= nextIndex) {
      setState({
        currentWordIndex: Math.min(nextIndex, maxIndex)
      });
    }
  }, [currentWordIndex, dataList.length, setState]);

  const onRadicalClick = useCallback(
    async radicalPinyin => {
      const url = await ExtendedResourceRepository.getDataSrc({
        bookId,
        pathName: `word/部首/${radicalPinyin}/${radicalPinyin}.json`
      });
      const data = await API.getJSON(url);
      if (data) {
        setState({ isRadicalShow: true, radicalData: data });
      }
    },
    [bookId, setState]
  );

  const onRadicalClose = () => {
    setState({ isRadicalShow: false });
  };

  return (
    <div className={styles.wordLearningGrid}>
      <div className={styles.left}>
        {dataList.map((item, index) => (
          <div
            key={item.Name}
            className={classnames(styles.word, {
              [styles.active]: index === currentWordIndex,
              [styles.optional]: !!item.Optional
            })}
            onClick={() => setState({ currentWordIndex: index })}
          >
            {list[index] && (
              <AudioButton
                autoPlay={index === currentWordIndex}
                src={`${ExtendedResourceRepository.getDataSrc({
                  bookId,
                  pathName: list[index].WordAudioPath
                })}`}
              >
                {item.Name}
              </AudioButton>
            )}
          </div>
        ))}
      </div>
      {list && list.length > 0 && (
        <WordLearningGridComponent
          data={list[currentWordIndex]}
          wordIndex={currentWordIndex}
          goToNextWordIndex={goToNextWordIndex}
          onRadicalClick={onRadicalClick}
        />
      )}
      {isRadicalShow && (
        <WordRadical data={radicalData} onClose={onRadicalClose} />
      )}
    </div>
  );
};

export default WordLearningGrid;
