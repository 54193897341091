import React, { useEffect, useCallback } from 'react';
import { groupByArrayToMap, convertArrayToMap } from 'util/array';
import useSetState from 'customHooks/setState';
import AudioButton from 'components/common/AudioButton';
import Icon from 'components/Icon';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

const COUNT = 4;

const WordExample = ({ data, defaultWord }) => {
  const [
    { currentWord, currentPage, pageMap, totalPage },
    setState
  ] = useSetState({
    currentPage: 0,
    currentWord: defaultWord || Object.values(data)[0].Word,
    totalPage: 0,
    pageMap: null
  });

  const [{ bookId }] = useStore(StoreTypes.books);

  const generatesPageMap = useCallback((contents, count) => {
    const data = contents
      .map((content, index) => ({
        ...content,
        page: Math.floor(index / count) + 1
      }))
      .reverse();
    const pageMap = groupByArrayToMap(data, 'page');
    const dataMap = convertArrayToMap(data, 'Word');
    const totalPage = Math.ceil(contents.length / count);
    return {
      dataMap,
      pageMap,
      totalPage
    };
  }, []);

  useEffect(() => {
    const { dataMap, pageMap, totalPage } = generatesPageMap(
      Object.values(data),
      COUNT
    );
    setState({
      dataMap,
      pageMap,
      totalPage,
      currentPage: dataMap[currentWord].page
    });
  }, [data, setState, generatesPageMap, currentWord]);

  const pageChangeHandler = page => {
    const max = totalPage;
    setState({ currentPage: Math.max(1, Math.min(page, max)) });
  };

  const wordsChangeHandler = word => {
    setState({ currentWord: word });
  };

  return (
    <div className={styles.WordExample}>
      <div className={styles.content}>
        {currentWord &&
          data[currentWord].WordsSet.map(item => (
            <div className={styles.item} key={item.ImagePath || item.AudioPath}>
              <div className={styles.audioWrapper}>
                {item.AudioPath && (
                  <AudioButton
                    className={styles.audio}
                    src={ExtendedResourceRepository.getDataSrc({
                      bookId,
                      pathName: item.AudioPath
                    })}
                  >
                    <Icon className={styles.icon} name="AudioPlay" />
                  </AudioButton>
                )}
              </div>
              <div className={styles.image}>
                <img
                  src={ExtendedResourceRepository.getDataSrc({
                    bookId,
                    pathName: item.ImagePath
                  })}
                  alt=""
                />
              </div>
            </div>
          ))}
      </div>
      <div className={styles.selector}>
        <div
          className={classnames(styles.ctrl, styles.left, {
            [styles.hide]: currentPage === totalPage
          })}
          onClick={() => pageChangeHandler(currentPage + 1)}
        >
          <Icon type="text" name="chevronLeft" />
        </div>
        <div className={styles.contentWrapper}>
          {pageMap &&
            pageMap[currentPage].map(item => (
              <div
                className={classnames(styles.word, {
                  [styles.active]: currentWord === item.Word
                })}
                onClick={() => wordsChangeHandler(item.Word)}
                key={item.Word}
              >
                {item.Word}
              </div>
            ))}
        </div>
        <div
          className={classnames(styles.ctrl, styles.right, {
            [styles.hide]: currentPage === 1
          })}
          onClick={() => pageChangeHandler(currentPage - 1)}
        >
          <Icon type="text" name="chevronRight" />
        </div>
      </div>
    </div>
  );
};

export default WordExample;
