import React from 'react';

import styles from './index.module.scss';


const Playlist = ({playlist,onChange}) => {

  return (
    <div className={styles.playlist}>
      {
        playlist.map(item=>(
          <div key={item.title} className={styles.playlistItem} onClick={()=>onChange(item.id)}>
            <div className={styles.poster}>
              <div className={styles.img} style={{backgroundImage:`url(${item.poster})`}} />
            </div>
            <div className={styles.title}>{item.title}</div>
          </div>
        ))
      }
    </div>
  )
};

export default Playlist;