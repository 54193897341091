export const SideBarType = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  PAGE_SEARCHER: 'PAGE_SEARCHER'
};

export const ToolBarDirection = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
};

export const ReaderToolType = {
  Menu: 'Menu',
  Bookshelf: 'Bookshelf',
  PageMode: 'PageMode',
  Drag: 'Drag',
  Painter: 'Painter',
  Select: 'Select',
  Painting: 'Painting',
  Highlighter: 'Highlighter',
  Shape: 'Shape',
  Line: 'Line',
  DeleteAll: 'DeleteAll',
  Delete: 'Delete',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  PageSercher: 'PageSercher',
  FourZoomIn: 'FourZoomIn',
  AllScreen: 'AllScreen',
  IntervalZoomIn: 'IntervalZoomIn',
  Whiteboard: 'Whiteboard',
  NumberPicker: 'NumberPicker',
  Img: 'Img',
  Text: 'Text',
  SVG: 'SVG',
  AttendeeNumber: 'AttendeeNumber',
  Bookmark: 'Bookmark',
  ReaderOption: 'ReaderOption',
  Activity: 'Activity',
  OpenActivity: 'OpenActivity',
  ExportInteractiveObject: 'ExportInteractiveObject',
  RightSideBar: 'RightSideBar',
  Mark: 'Mark',
  FullWidth: 'FullWidth'
};


export const ReaderZoomType = {
  LeftTop: "LeftTop",
  RightTop: "RightTop",
  LeftBottom: "LeftBottom",
  RightBottom: "RightBottom",
  OriginZoom: "OriginZoom",
  AreaZoom: "AreaZoom",
  WheelZoom: "WheelZoom"
}
