import React, { useState } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';

const defaultMessageList = [
    { id: 0, isMe: false, content: '要注意複數變化' },
    { id: 1, isMe: true, content: '有其他類似的複數變化嗎' },
    { id: 2, isMe: false, content: 'leaf葉子的負數是leaves' },
    { id: 3, isMe: true, content: '感謝老師解答' },
];
const MessageContainer = () => {
    const [messageList, setMessage] = useState(defaultMessageList);
    return (
        <div>
            <div className={styles.messageWrapper}>
                {
                    messageList.map(item => <div className={classnames(styles.message, {
                        [styles.right]: item.isMe
                    })} key={item.id}>
                        <div className={styles.avatar}>{item.isMe ? '我' : '小萬老師'}</div>
                        <div className={styles.content}>{item.content}</div>
                    </div>
                    )
                }
            </div>
            <div className={styles.inputWrapper}>
                <textarea name='' id='' cols='20' rows='1'></textarea>
                <button>送出</button>
            </div>
        </div>)
};


export default MessageContainer;
