import { useState, useEffect } from 'react';

export const useMedia = () => {
	const phoneMedia = window.matchMedia('(max-width: 599px)');
	const tabletMedia = window.matchMedia('(max-width: 1023px) and (min-width: 600px)');
	const desktopMedia = window.matchMedia('(min-width: 1024px)');

	let defaultMedia = 'desktop';

	if (phoneMedia.matches) {
		defaultMedia = 'phone';
	}

	if (tabletMedia.matches) {
		defaultMedia = 'tablet';
	}

	const [media, setMedia] = useState(defaultMedia);

	const handleMediaChange = mediaName => mediaHandler => {
		if (mediaHandler.matches && mediaName !== media) {
			setMedia(mediaName);
		}
	};

	useEffect(() => {
		const phoneHandler = handleMediaChange('phone');
		const tabletHandler = handleMediaChange('tablet');
		const desktopHandler = handleMediaChange('desktop');

		phoneMedia.addListener(phoneHandler);
		tabletMedia.addListener(tabletHandler);
		desktopMedia.addListener(desktopHandler);

		return () => {
			phoneMedia.removeListener(phoneHandler);
			tabletMedia.removeListener(tabletHandler);
			desktopMedia.removeListener(desktopHandler);
		};
	// eslint-disable-next-line
	}, [desktopMedia, media, phoneMedia, tabletMedia]);

	return media;
};
