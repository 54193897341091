import React, { Fragment } from 'react';
import useSetState from 'customHooks/setState';
import useInterval from 'customHooks/interval';
import Icon from 'components/Icon';
import { useStore, StoreTypes } from 'context';
import classnames from 'classnames';
import styles from './index.module.scss';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

const WordRadical = ({ data, onClose }) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const { Path, Progress } = data;
  const [
    { progressModalShow, sliderIndex, wordRadicalProgressIndex },
    setState
  ] = useSetState({
    progressModalShow: false,
    sliderIndex: 0,
    wordRadicalProgressIndex: 0
  });

  const maxItemListIndex = Progress.length - 1;

  useInterval(() => {
    let index = sliderIndex + 1;
    if (index <= 0) index = maxItemListIndex;
    if (index > maxItemListIndex) index = 0;
    setState({ sliderIndex: index });
  }, 3000);

  const progressModalHandler = status => {
    setState({ progressModalShow: status });
  };

  const wordRadicalProgressChangeHandler = index => {
    setState({
      wordRadicalProgressIndex: Math.max(0, Math.min(index, maxItemListIndex))
    });
  };

  return (
    <div className={styles.wordRadical}>
      <div className={styles.mainContent}>
        <div className={styles.content}>
          <img
            src={ExtendedResourceRepository.getDataSrc({
              bookId,
              pathName: Path
            })}
            alt=""
          />
        </div>
        <div className={styles.progress}>
          <div className={styles.progressSlider}>
            <div className={styles.slider}>
              {Progress.map((item, index) => (
                <img
                  key={item.Path}
                  src={ExtendedResourceRepository.getDataSrc({
                    bookId,
                    pathName: item.Path
                  })}
                  alt=""
                  className={classnames(styles.img, {
                    [styles.active]: index === sliderIndex
                  })}
                />
              ))}
            </div>
            <span
              className={styles.icon}
              onClick={() => progressModalHandler(true)}
            >
              <Icon type="text" name="searchPlus" />
            </span>
          </div>
        </div>
        <div className={styles.close} onClick={onClose}>
          <Icon type="text" name="home" />
        </div>
      </div>
      {progressModalShow && (
        <div className={styles.progressModal}>
          <div
            className={styles.progressModalBackground}
            onClick={() => progressModalHandler(false)}
          ></div>
          <div className={styles.progressModalContent}>
            <div className={styles.itemList}>
              {Progress.map((item, index) => (
                <Fragment key={item.Path}>
                  <div
                    className={classnames(styles.item, {
                      [styles.show]: index <= wordRadicalProgressIndex
                    })}
                  >
                    <img
                      src={ExtendedResourceRepository.getDataSrc({
                        bookId,
                        pathName: item.Path
                      })}
                      alt=""
                    />
                    <div className={styles.info}>{item.Info}</div>
                  </div>
                  {index !== maxItemListIndex && (
                    <div
                      className={classnames(styles.icon, {
                        [styles.show]: index <= wordRadicalProgressIndex
                      })}
                    >
                      <Icon type="text" name="longArrowAltRight" />
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
            <div className={styles.controls}>
              <div className={styles.center}>
                <span
                  onClick={() =>
                    wordRadicalProgressChangeHandler(
                      wordRadicalProgressIndex - 1
                    )
                  }
                >
                  <Icon type="text" name="angleLeft" />
                </span>
                <span
                  onClick={() =>
                    wordRadicalProgressChangeHandler(
                      wordRadicalProgressIndex + 1
                    )
                  }
                >
                  <Icon type="text" name="angleRight" />
                </span>
              </div>
              <div className={styles.right}>
                <span onClick={() => progressModalHandler(false)}>
                  <Icon type="text" name="times" />
                </span>
                <span onClick={onClose}>
                  <Icon type="text" name="home" />
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WordRadical;
