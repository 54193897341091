import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './index.module.scss';
import Button from '@material-ui/core/Button';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { useInteractiveObjectContentCommandFactory } from 'customHooks/InteractiveObjectContentCommands/commandFactory';

const Submenu = ({ submenuInfo }) => {
    const [, readerDispatch] = useStore(StoreTypes.reader);
    const { itemList, target } = submenuInfo;
    const submenuContent = useRef(null);
    const CommandFactory = useInteractiveObjectContentCommandFactory();

    const itemClickHandler = (e, item) => {
        const command = CommandFactory.createCommand(item);
        command && command.execute({ json: item });
    }

    const closeSubmenu = () => {
        readerDispatch({ type: types.SET_SUBMENU_INFO, submenuInfo: null })
    }

    useEffect(() => {
        const submenuContentDom = ReactDOM.findDOMNode(submenuContent.current)
        const { x, y, height } = target.getBoundingClientRect()
        if (submenuContentDom) {
            submenuContentDom.style.top = (y + height + 5) + "px";
            submenuContentDom.style.left = (x) + "px";
        }
    })

    return <div className={styles.submenuContent} onClick={closeSubmenu}>
        <div className={styles.items} ref={submenuContent}>
            <ul className={styles.itemul}>
                {
                    itemList.list.map((item) => {
                        return <li className={styles.itemli}>
                            <Button style={{
                                textTransform: "none",
                                color: "#fff",
                                minWidth: "max-content",
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                                height: "30px"
                            }} onClick={(e) => { itemClickHandler(e, item) }}>{item.name}</Button>
                        </li>
                    })
                }
            </ul>
        </div>
    </div>
};

export default Submenu;