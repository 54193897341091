import React, { useState, useRef } from 'react';
import { useStore, StoreTypes } from 'context';
import classnames from 'classnames';
import styles from './index.module.scss';
import MusicPlayer from 'components/common/MusicPlayer';
import Repository from 'repositories/Repository';

const { ExtendedResourceRepository } = Repository;

const MarkerWithSound = ({ src, audioInfo }) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [trackList, setTrackList] = useState([]);
  const [{ audio, state, controls, ref }, setMusicInfo] = useState([]);
  const [activeCue, setActiveCue] = useState({});
  const trackContentRef = useRef(null);
  const playerRef = useRef(null);
  const trackListRef = useRef(null);
  const musicPlayerOnLoadHandler = ({ audio, state, controls, ref }) => {
    const trackContentHieght =
      trackContentRef.current &&
      trackContentRef.current.clientHeight - playerRef.current.clientHeight;
    const listHieght =
      state.activeCue &&
      state.activeCue.id &&
      document.getElementById('track' + state.activeCue.id).clientHeight;

    setMusicInfo({ audio, state, controls, ref });
    setTrackList(state.cues);

    let scrollTop = 0;

    if (state.activeCue) {
      if (state.activeCue.id) {
        if (
          state.cues.length > 0 &&
          document.getElementById('track' + parseInt(state.cues[0].id))
        ) {
          for (let i = 0; i < state.cues.length; i++) {
            scrollTop += document.getElementById(
              'track' + parseInt(state.cues[i].id)
            ).clientHeight;
            if (state.activeCue.id === state.cues[i].id) {
              break;
            }
          }
        }
      }

      setActiveCue(state.activeCue);
      if (state.isPlaying) {
        trackContentRef &&
          (trackContentRef.current.scrollTop =
            scrollTop !== 0
              ? scrollTop - trackContentHieght / 2 - listHieght / 2
              : 0);
      }
    }

    /*if(audio){
            var myAudio = audio;
            var myCanvas = document.getElementById('seekRange');
            var context = myCanvas.getContext('2d');
    
            context.fillStyle = 'lightgray';
            context.fillRect(0, 0, myCanvas.width, myCanvas.height);
            context.fillStyle = 'red';
            context.strokeStyle = 'white';
    
            var inc = myCanvas.width / myAudio.duration;
    
            // display TimeRanges
    
            myAudio.addEventListener('seeked', function() {
                for (let i = 0; i < myAudio.buffered.length; i++) {
                    console.log(myAudio.buffered.start(i))
                    var startX = myAudio.buffered.start(i) * inc;
                    var endX = myAudio.buffered.end(i) * inc;
                    var width = endX - startX;
    
                    context.fillRect(startX, 0, width, myCanvas.height);
                    context.rect(startX, 0, width, myCanvas.height);
                    context.stroke();
                }
            });
        }
        */
  };

  return (
    <div
      className={styles.markerWithSound}
      ref={el => (trackContentRef.current = el)}
    >
      <div className={styles.listContent}>
        {trackList.map((track, index) => {
          return (
            <div
              ref={el => (trackListRef.current = el)}
              id={'track' + track.id}
              className={classnames(
                styles.track,
                styles[
                  track.text &&
                  activeCue.text === track.text &&
                  activeCue.id === track.id
                    ? 'show'
                    : null
                ]
              )}
              onClick={() => {
                controls.seek(track.startTime);
                !state.isPlaying && controls.play();
              }}
            >
              <span className={styles.text}>{track.text}</span>
            </div>
          );
        })}
      </div>
      <div
        className={styles.markerWithSoundPlaybar}
        ref={el => (playerRef.current = el)}
      >
        <MusicPlayer
          src={ExtendedResourceRepository.getAudioSrc({
            bookId,
            filename: src
          })}
          subtitles={[
            {
              language: 'en',
              src: ExtendedResourceRepository.getAudioVTTSrc({
                bookId,
                audioInfo
              })
            }
          ]}
          onLoad={musicPlayerOnLoadHandler}
        />
        {
          //<canvas id={"seekRange"} style={{ width: "100%", height: "50px" }} />
        }
      </div>
    </div>
  );
};

export default MarkerWithSound;
