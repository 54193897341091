import React, { useRef } from 'react';

import { DefaultColors } from 'constants/colors';
import { PainterType } from 'constants/painterTypes';
import { useStore, StoreTypes } from 'context';

import ColorSelector from 'components/Painter/ColorSelector';
import LineWidthSlider from 'components/Painter/LineWidthSlider';
import BrushSelector from 'components/Painter/BrushSelector';
import ShapeSelector from 'components/Painter/ShapeSelector';
import LineTypeSelector from 'components/Painter/LineTypeSelector';
import FormGroup from 'components/common/FormGroup';
import Button from 'components/Button';
import Icon from 'components/Icon';

import { useOutsideAlerter } from 'customHooks/outsideAlerter';

import classnames from 'classnames';
import styles from './index.module.scss';

const PainterPanel = ({ className, children, onClose }) => {
  const [{ readerToolDirection }] = useStore(StoreTypes.reader);
  const panelRef = useRef(null);

  useOutsideAlerter(panelRef, onClose);
  return (
    <div
      ref={panelRef}
      className={classnames(
        styles.popover,
        styles[readerToolDirection],
        className
      )}
    >
      {children}
    </div>
  );
};

const PenPanel = ({
  className,
  onPanelClose,
  painterTool: { lineWidth, drawingBrush, colorHex },
  onLineWidthChange = () => {},
  onColorChange = () => {},
  onBrushChange = () => {},
  onPainterHandler = () => {}
}) => {
  return (
    <PainterPanel className={className} onClose={onPanelClose}>
      <FormGroup label="顏色" vertical>
        <ColorSelector
          colors={DefaultColors}
          value={colorHex}
          onChange={onColorChange}
        />
      </FormGroup>
      <FormGroup label="線條粗細" vertical>
        <LineWidthSlider value={lineWidth} onChange={onLineWidthChange} />
      </FormGroup>
      <FormGroup label="畫筆選項" vertical>
        <div className={styles.formGroupItemWrapper}>
          {/* <div className={styles.formGroupItem}>
							<BrushSelector value={drawingBrush} onChange={onBrushChange} />
						</div> */}
          <div className={styles.formGroupItem}>
            <Button
              className={styles.penPanelBtn}
              onClick={onPainterHandler(PainterType.Line)}
            >
              <Icon name="PenStraight" />
            </Button>
            <Button
              className={styles.penPanelBtn}
              onClick={onPainterHandler(PainterType.Pen)}
            >
              <Icon name="PenCurve" />
            </Button>
          </div>
        </div>
      </FormGroup>
    </PainterPanel>
  );
};

const ShapePanel = ({
  className,
  onPanelClose,
  painterTool: { lineWidth, colorHex },
  onLineWidthChange = () => {},
  onColorChange = () => {},
  onShapeChange = () => {},
  onFillTypeChange = () => {},
  shapeFillTypeValue
}) => {
  return (
    <PainterPanel className={className} onClose={onPanelClose}>
      <FormGroup label="顏色" vertical>
        <ColorSelector
          colors={DefaultColors}
          value={colorHex}
          onChange={onColorChange}
        />
      </FormGroup>
      <FormGroup label="線條粗細" vertical>
        <LineWidthSlider value={lineWidth} onChange={onLineWidthChange} />
      </FormGroup>
      <FormGroup label="形狀選項" vertical>
        <ShapeSelector
          onChange={onShapeChange}
          onFillTypeChange={onFillTypeChange}
          fillType={shapeFillTypeValue}
        />
      </FormGroup>
    </PainterPanel>
  );
};

const LinePanel = ({
  className,
  onPanelClose,
  painterTool: { lineWidth, colorHex },
  onLineWidthChange = () => {},
  onColorChange = () => {},
  onLineTypeChange = () => {}
}) => {
  return (
    <PainterPanel className={className} onClose={onPanelClose}>
      <FormGroup label="顏色" vertical>
        <ColorSelector
          colors={DefaultColors}
          value={colorHex}
          onChange={onColorChange}
        />
      </FormGroup>
      <FormGroup label="畫筆粗細" vertical>
        <LineWidthSlider value={lineWidth} onChange={onLineWidthChange} />
      </FormGroup>
      <FormGroup label="畫筆選項" vertical>
        <LineTypeSelector onChange={onLineTypeChange} />
      </FormGroup>
    </PainterPanel>
  );
};

export default {
  Pen: PenPanel,
  Line: LinePanel,
  Shape: ShapePanel
};
