export const DefaultExtraFields = {
    Id: 'id',
    Extra: 'extra',
};

export const ExtraFieldsForEditor = {
    Id: 'id',
    Style: 'style',
    Src: 'src',
    ContentType: 'contentType',
    ControlObject: 'controlObject',
    PageIndex: 'pageIndex',
    VideoInfo: 'videoInfo',
    AudioInfo: 'audioInfo',
    AreaZoomInfo: 'areaZoomInfo',
    InternalHtmlInfo: 'internalHtmlInfo',
    Stage: 'stage',
    ControlStage: 'controlStage',
    Opacity: 'opacity',
    RevealStage: 'revealStage',
    WordGridInfo: 'wordGridInfo',
    WordExampleInfo: 'wordExampleInfo',
    VocabularyInfo: 'vocabularyInfo',
    SubmenuInfo: 'submenuInfo',
    SynopsisInfo: 'synopsisInfo'
};
