import { useCallback } from 'react';
import useModal from 'components/common/Modal/useModal';
import { useMusicModal } from 'components/common/MusicPlayer/MusicModal';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';

export const useAudioCommand = () => {
    const [, { openModal:openMusicModal, closeModal }] = useMusicModal();
    const [, { openModal:externalWindownModal, setModalComponents }] = useModal();

    const execute = useCallback(async ({ json: { src, audioInfo } }) => {
        closeModal();
        if(audioInfo){
            const component = ModalComponentGenerator.generate(ModalComponentType.MarkerWithSound, { src, audioInfo });
            setModalComponents(component);
            externalWindownModal();
        }else{
            openMusicModal({ src, audioInfo });
        }

    }, [closeModal, externalWindownModal, openMusicModal, setModalComponents]);

    return { execute };
};
