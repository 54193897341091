import React from 'react';

import classnames from 'classnames';
import styles from './index.module.scss';

const levels = [
  { label: '1', value: 0.5 },
  { label: '2', value: 0.75 },
  { label: '3', value: 1 },
  { label: '4', value: 1.25 },
  { label: '5', value: 1.5 },
  { label: '6', value: 2 }
];

const PlaybackRateControl = ({ value = 1, onChange = () => {} }) => {
  const clickHandler = value => e => {
    onChange(value);
  };

  return (
    <div className={styles.playbackRateControl}>
      {levels.map(level => (
        <div
          key={level.value}
          className={classnames(styles.level, {
            [styles.active]: value === level.value
          })}
          onClick={clickHandler(level.value)}
        >
          {level.label}
        </div>
      ))}
    </div>
  );
};

export default PlaybackRateControl;
