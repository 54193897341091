import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

const WORDING_MAP = {
  MeaningSet: '字義教學',
  WordsSet: '語詞教學',
  IdiomSet: '生字成語',
  GlyphSet: '字音字形'
};

const WordsMeaningButtonGroup = ({
  activeIndex = null,
  onChange,
  data
}) =>  (
    <div className={styles.buttonGroup}>
      {
        Object.entries(data).map((item,index) => 
        <button 
          key={item[0]}
          className={classnames({ [styles.active] : index === activeIndex })}
          onClick={() =>onChange({
            index,
            value: item[1]
          })}
          disabled={item[1].length < 1}
        >
          {WORDING_MAP[item[0]]}
        </button>)
      }
    </div>
  )
export default WordsMeaningButtonGroup;