import url from 'url';
import { fileExpressHost, fileExpressPort } from 'repositories/Repository';

import { IExtendedResourceRepository } from './IExtendedResourceRepository';

function FileExtendedResourceRepository() {}
FileExtendedResourceRepository.prototype = Object.create(
  IExtendedResourceRepository
);

FileExtendedResourceRepository.prototype.getVideoSrc = ({
  bookId,
  filename
}) => {
  return url.format({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `media/${bookId}/video/${filename}.mp4`,
    slashes: true
  });
};
FileExtendedResourceRepository.prototype.getAudioSrc = ({
  bookId,
  filename
}) => {
  return url.format({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `media/${bookId}/audio/${filename}.mp3`,
    slashes: true
  });
};

FileExtendedResourceRepository.prototype.getAudioVTTSrc = ({
  bookId,
  audioInfo
}) => {
  return url.format({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `media/${bookId}/audio/${audioInfo}.vtt`,
    slashes: true
  });
};

FileExtendedResourceRepository.prototype.getSubtitleSrc = ({
  bookId,
  filename
}) => {
  return url.format({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `media/${bookId}/video/${filename}.vtt`,
    slashes: true
  });
};
FileExtendedResourceRepository.prototype.getInternalHtmlSrc = ({
  bookId,
  filename
}) => {
  return url.format({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `data/${bookId}/${filename}.html`,
    slashes: true
  });
};
FileExtendedResourceRepository.prototype.getDataSrc = ({
  bookId,
  pathName
}) => {
  return url.format({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `data/${bookId}/${pathName}`,
    slashes: true
  });
};

FileExtendedResourceRepository.prototype.getInternalWindowHtml = async ({
  bookId,
  filename
}) => {
  const res = await fetch(
    url.format({
      protocol: 'http:',
      hostname: fileExpressHost,
      port: fileExpressPort,
      pathname: `data/${bookId}/${filename}.json`,
      slashes: true
    })
  );
  const json = await res.json();
  return json.html;
};

FileExtendedResourceRepository.prototype.getJSONContent = async ({
  bookId,
  pathName
}) => {
  const res = await fetch(
    url.format({
      protocol: 'http:',
      hostname: fileExpressHost,
      port: fileExpressPort,
      pathname: `data/${bookId}/${pathName}.json`,
      slashes: true
    })
  );
  const json = await res.json();
  return json;
};

FileExtendedResourceRepository.prototype.getMathJaxPath = () => {
  return 'http://localhost:54321/mathjax/MathJax.js';
};

export default new FileExtendedResourceRepository();
