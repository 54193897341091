import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';

export const useSubmenuCommand = () => {
    const [, readerDispatch] = useStore(StoreTypes.reader);

    const execute = useCallback(async ({ json: { submenuInfo }, target }) => {
        try {
            readerDispatch({ type: types.SET_SUBMENU_INFO, submenuInfo: { itemList: JSON.parse(submenuInfo), target } })
        } catch (err) {
            console.error('MenuCommand execute error', err);
        }
    }, [readerDispatch]);

    return { execute };
};
