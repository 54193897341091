import { useState, useReducer, useCallback, useEffect } from 'react';
import { fabric } from 'fabric';

import { CanvasReducer, initState } from 'reducers/canvasReducer';
import * as types from 'constants/actionTypes';
import { PainterMode } from 'constants/painterModes';
import { useCanvasEvents } from 'customHooks/canvas';
import { EventBusType, useEvent } from 'events/EventBus';
import { PainterEvent, ReaderToolsEvent } from 'events/EventTypes';


const canvas = new fabric.Canvas('whiteboard-canvas', {
  isDrawingMode: true,
  skipTargetFind: true,
  selection: true
});

const eventBusType = EventBusType.ExtendedContent;

export const ExtendedContentEvent = elementRef => {
  const [canvasState, canvasDispatch] = useReducer(CanvasReducer, { ...initState, canvas, painterMode: PainterMode.Painting });

  const [{ canvasHeight, canvasWidth, isContentInit }, setSize] = useState({ isContentInit: false });

  useEffect(() => {
    if(!elementRef.current) return;
    const canvasWidth = elementRef.current.clientWidth;
    const canvasHeight = elementRef.current.clientHeight;

    setSize({
        canvasWidth,
        canvasHeight,
        isContentInit: true
    })
}, [setSize,elementRef])


useCanvasEvents({ eventBusType, canvasState, canvasDispatch });

const dragEventHandler = useCallback(() => {
  canvasDispatch({ type: types.CANVAS_INACTIVATE });
}, []);

const painterEventHandler = useCallback(({ painterMode, painterToolType }) => {
  canvasDispatch({
      type: types.CANVAS_CHANGE_PAINTER_MODE,
      painterMode,
      painterToolType
  });
}, []);

const eraseAllEventHandler = useCallback(() => {
  canvasDispatch({ type: types.CANVAS_ERASE_ALL });
}, []);

const changeBrushTypeHandler = useCallback(({ brushType }) => {
  canvasDispatch({ type: types.CHANGE_DRAWING_BRUSH, changeDrawingBrush: brushType });
}, [canvasDispatch]);

const changeBrushWidthHandler = useCallback(({ lineWidth }) => {
  canvasDispatch({ type: types.CANVAS_DRAWING_BRUSH_LINE_WIDTH, changeLineWidth: lineWidth });
}, [canvasDispatch]);

const changeBrushColorHandler = useCallback(({ color }) => {
  canvasDispatch({ type: types.CANVAS_CHANGE_COLOR, changeColorRgb: color, changeColorHex: color });
}, [canvasDispatch]);

const changePainterTypeHandler = useCallback(({ painterType }) => {
  canvasDispatch({ type: types.CANVAS_CHANGE_PAINTER_TYPE, painterType });
}, [canvasDispatch]);

const fillTypeChangeHandler = useCallback(({ fillType }) =>{
  canvasDispatch({ type: types.CANVAS_CHANGE_SHAPE_FILL_TYPE, fillType });
},[canvasDispatch]);


useEvent({ eventBusType, event: ReaderToolsEvent.ClickDragEvent }, dragEventHandler);
useEvent([
    { eventBusType, event: ReaderToolsEvent.ClickPainterEvent },
    { eventBusType, event: ReaderToolsEvent.ClickSelectEvent },
    { eventBusType, event: ReaderToolsEvent.ClickEraserEvent }
], painterEventHandler);
useEvent({ eventBusType, event: ReaderToolsEvent.ClickEraseAllEvent }, eraseAllEventHandler);
useEvent({ eventBusType, event: PainterEvent.ChangeBrushTypeEvent }, changeBrushTypeHandler);
useEvent({ eventBusType, event: PainterEvent.ChangeBrushWidthEvent }, changeBrushWidthHandler);
useEvent({ eventBusType, event: PainterEvent.ChangeBrushColorEvent }, changeBrushColorHandler);
useEvent({ eventBusType, event: PainterEvent.ChangePainterTypeEvent }, changePainterTypeHandler);
useEvent({ eventBusType, event: PainterEvent.ChangeShapeFillTypeEvent }, fillTypeChangeHandler);

  return [
      { 
        canvasState,
        canvasHeight,
        canvasWidth,
        isContentInit
      },
      canvasDispatch
  ]
}