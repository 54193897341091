import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import useModal from 'components/common/Modal/useModal';
import Repository from 'repositories/Repository';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';

const { ExtendedResourceRepository } = Repository;

export const useVocabularyCommand = () => {
    const [{ bookId }] = useStore(StoreTypes.books);
    const [, { openModal, setModalComponents }] = useModal();

    const defaultInfo = JSON.stringify({ word:'' });
    const execute = useCallback(async ({ json: { src, vocabularyInfo = defaultInfo } }) => {
        const { word } = JSON.parse(vocabularyInfo);
        try {
            const vocabularyResource = await ExtendedResourceRepository.getJSONContent({ bookId, pathName: src });
            const component = ModalComponentGenerator.generate(ModalComponentType.Vocabulary, { vocabularyResource, word });
            setModalComponents(component);
            openModal();
        } catch (e) {
            console.error('VocabularyCommand execute error', e);
        }
    }, [bookId, openModal, setModalComponents]);

    return { execute };
};
