import React, { useRef, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { BookFlipType } from 'constants/flipTypes';
import { ReaderZoomType } from 'constants/ReaderTools';
import { ReaderToolsEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';
import * as types from 'constants/actionTypes';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";


//const TransformRegex = /translate3d\(([0-9-.]+)px, ([0-9-.]+)px, [0-9-.]+px\) scale\(([0-9-.]+)\)/;

export const PinchPanContainer = ({ index, LRFlip, children }) => {
  const [{ offsetX, offsetY, scale, isMarkModeShow }, readerDispatch] = useStore(StoreTypes.reader);
  const [{ s, x, y }, setObj] = useState({ s: 1, x: 0, y: 0 })
  const ref = useRef(null);
  const updateScaleTimeout = useRef(null);

  const updateScaleInfo = useCallback(({ offsetX, offsetY, scale }) => {
    if (updateScaleTimeout.current) {
      clearTimeout(updateScaleTimeout.current)
    }
    updateScaleTimeout.current = setTimeout(() => {
      readerDispatch({ type: types.SET_SCALE_INFO, offsetX, offsetY, scale });
      updateScaleTimeout.current = null;
    }, 250);
  }, [readerDispatch]);

  /*
  因為改縮放套件，新的縮放套件可以回傳scale offsetX offsetY所以註解掉
  useEffect(() => {
      if (!ref.current) return;
      var observer = new MutationObserver(function(mutations) {
          mutations.forEach(mutation => {
              const match = mutation.target.style.transform.match(TransformRegex);
              if (match.length > 0) {
                  updateScaleInfo({ offsetX: match[1], offsetY: match[2], scale: match[3] });
              }
          });

      });
      observer.observe(ref.current, {
          attributes: true,
          attributeFilter: ['style']
      });
  });
  */

  const handler = useCallback((event) => {
    event.stopImmediatePropagation();
    return true;
  }, []);

  useEffect(() => {
    if (ref.current) {
      if (isMarkModeShow) {
        ref.current.onmousemove = handler
        ref.current.onwheel = handler
      } else {
        ref.current.onmousemove = null
        ref.current.onwheel = null
      }
    }
  }, [isMarkModeShow, handler]);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.cursor = `inherit`;
      //ref.current.style.transform = `translate3d(${offsetX}px, ${offsetY}px, 0px) scale(${scale})`;
    }
    offsetX !== undefined && setObj({ s: scale, x: offsetX, y: offsetY })
  }, [scale, offsetX, offsetY]);

  const wheelHandler = (e) => {
    //console.log("----wheelHandler-----", e)
    EventBus.emit({ event: ReaderToolsEvent.SetReaderZoomEvent, payload: { type: ReaderZoomType.WheelZoom } })
    setObj({ s: e.scale, x: e.positionX, y: e.positionY })
    updateScaleInfo({ offsetX: x, offsetY: y, scale: s });
  }

  const panningHandler = (e) => {
    //console.log("----panningHandler-----", e)
    setObj({ s: e.scale, x: e.positionX, y: e.positionY })
    updateScaleInfo({ offsetX: x, offsetY: y, scale: s });
  }

  return (
    <div className={styles.pinchPanContainer}>
      <TransformWrapper dbClickEnabled={false} onPanning={(e) => { panningHandler(e) }} onWheel={(e) => { wheelHandler(e) }} enableWheel={true} minScale={1} maxScale={3} scale={s} positionX={x} positionY={y}>
        {({
          zoomIn,
          zoomOut,
          resetTransform,
          positionX,
          positionY,
          scale,
          previousScale,
          limitToBounds,
          zoomingEnabled,
          panningEnabled,
          transformEnabled,
          pinchEnabled,
          enableZoomedOutPanning,
          disabled,
          dbClickEnabled,
        }) => (
            <TransformComponent>
              <div ref={v => ref.current = v}
                className={classnames(styles.swipeContainer, { [styles.reverse]: LRFlip === BookFlipType.RIGHT_TO_LEFT, [styles.markMode]: isMarkModeShow })}
                key={index}
              >
                {children}
              </div>
            </TransformComponent>
          )}
      </TransformWrapper>
    </div>
  );
};
