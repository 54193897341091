import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import useModal from 'components/common/Modal/useModal';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';

export const useVideoCommand = () => {
    const [{ bookId }] = useStore(StoreTypes.books);
    const [, { openModal, setModalComponents }] = useModal();

    const execute = useCallback(async ({ json: { videoInfo } }) => {
        try {
            const component = ModalComponentGenerator.generate(ModalComponentType.Video, { bookId, videoInfo: JSON.parse(videoInfo) })
            setModalComponents(component);
            openModal();
        } catch (err) {
            console.error('VideoCommand execute error', err);
        }
    }, [bookId, openModal, setModalComponents]);

    return { execute };
};
