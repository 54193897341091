import React from 'react';
import styles from './index.module.scss';
import ToolsPosition from 'components/Tools/ToolsPosition';
import FullWidth from 'components/Tools/FullWidth';

export const ReaderOptionPanel = () => {
    return (
        <div>
            <ToolsPosition />
            <div className={styles.division_line} />
            <FullWidth />
        </div>
    );
};
