import React from 'react';
import styles from './index.module.scss';
import { ReaderZoomType } from 'constants/ReaderTools';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';

// import rightTop from "assets/icon/four-zoom-in/right-top.svg";
// import rightBottom from "assets/icon/four-zoom-in/right-bottom.svg";
// import leftTop from "assets/icon/four-zoom-in/left-top.svg";
// import leftBottom from "assets/icon/four-zoom-in/left-bottom.svg";

const FourZoomIn = () => {
    const map = {
        [ReaderZoomType.RightTop]: "assets/icon/four-zoom-in/right-top.svg",
        [ReaderZoomType.RightBottom]: "assets/icon/four-zoom-in/right-bottom.svg",
        [ReaderZoomType.LeftTop]: "assets/icon/four-zoom-in/left-top.svg",
        [ReaderZoomType.LeftBottom]: "assets/icon/four-zoom-in/left-bottom.svg"
    }

    return (
        <div className={styles['four-zoom-in']}>
            <div className={styles.box}>
                <img src={map[ReaderZoomType.LeftTop]} alt="" title="左上角" onClick={() => EventBus.emit({ event: ReaderToolsEvent.SetReaderZoomEvent, payload: { type: ReaderZoomType.LeftTop } })} />
                <img src={map[ReaderZoomType.RightTop]} alt="" title="右上角" onClick={() => EventBus.emit({ event: ReaderToolsEvent.SetReaderZoomEvent, payload: { type: ReaderZoomType.RightTop } })} />
            </div>
            <div className={styles.box}>
                <img src={map[ReaderZoomType.LeftBottom]} alt="" title="左下角" onClick={() => EventBus.emit({ event: ReaderToolsEvent.SetReaderZoomEvent, payload: { type: ReaderZoomType.LeftBottom } })} />
                <img src={map[ReaderZoomType.RightBottom]} alt="" title="右下角" onClick={() => EventBus.emit({ event: ReaderToolsEvent.SetReaderZoomEvent, payload: { type: ReaderZoomType.RightBottom } })} />
            </div>
        </div>
    )
}

export default FourZoomIn;