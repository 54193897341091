import React, { useCallback, useState } from 'react';
import Input from 'components/common/Input';
import styles from './index.module.scss';
import DialogModule from 'components/Dialogs';
import Button from '@material-ui/core/Button';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';
import { ExtraFieldsForEditor } from 'constants/canvas';
import { ContentTypeDisplayMap } from '../index';

const dialogStatusTypes = {
    add: 'add',
    editor: 'editor'
}

export const SubmenuPropertyForm = ({ object }) => {
    const submenuInfo = object.submenuInfo ? JSON.parse(object.submenuInfo) : {
        list: []
    };
    const list = submenuInfo.list;
    const [isDialog, setDialog] = useState(false);
    const [nameInput, setNameInput] = useState("");
    const [srcInput, setSrcInput] = useState("");
    const [typeInput, setTypeInput] = useState(InteractiveObjectContentType.ExternalWindow);
    const [listItemIndex, setListItemIndex] = useState(0);
    const [dialogType, setDialogType] = useState("");

    const dialogContent = <div>
        {"Name"}
        <Input className={styles.menuPropertyInput} value={nameInput} onChange={(e) => nameChangeHandler(e.target.value)} />
        {"Link"}
        <Input className={styles.menuPropertyInput} value={srcInput} onChange={(e) => linkChangeHandler(e.target.value)} />
        {"Type"}
        <select id='type' value={typeInput} onChange={e => typeChangeHandler(e.target.value)}>
            {[InteractiveObjectContentType.ExternalWindow, InteractiveObjectContentType.Video].map((type, index) => {
                return <option key={index} value={type}>{ContentTypeDisplayMap[type]}</option>;
            })}
        </select>
    </div>;

    const dialogAction = <div>
        <Button
            onClick={() => {
                setDialog(false)
            }}
            color="primary"
        >
            取消
        </Button>
        <Button
            onClick={() => {
                setDialog(false)
                if (!nameInput) return;
                if (dialogType === dialogStatusTypes.add) {
                    const item = { name: nameInput, src: srcInput ? srcInput : 0, contentType: typeInput || InteractiveObjectContentType.ExternalWindow };
                    if (typeInput === InteractiveObjectContentType.Video) {
                        item.videoInfo = JSON.stringify({ src: item.src, subtitles: [] });
                    }
                    list.push(item);
                } else {
                    list[listItemIndex].name = nameInput;
                    list[listItemIndex].src = srcInput ? srcInput : 0;
                    list[listItemIndex].contentType = typeInput || InteractiveObjectContentType.ExternalWindow;
                    if (list[listItemIndex].contentType === InteractiveObjectContentType.Video) {
                        list[listItemIndex].videoInfo = JSON.stringify({ src: list[listItemIndex].src, subtitles: [] });
                    } else {
                        delete list[listItemIndex].videoInfo;
                    }
                }
                setDialogType("")
                list.sort((a, b) => a.time - b.time)
                inputTextHandler("list", list);
            }}
            color="primary" autoFocus
        >
            確定
        </Button>
    </div>;

    const nameChangeHandler = ((value) => {
        setNameInput(value)
    });

    const linkChangeHandler = ((value) => {
        setSrcInput((value))
    });

    const typeChangeHandler = value => setTypeInput(value);

    const inputTextHandler = useCallback((property, value) => {
        submenuInfo[property] = value;
        EventBus.emit({ event: CanvasEvent.ModifyCanvasObjectPropertyEvent, payload: { object, property: ExtraFieldsForEditor.SubmenuInfo, value: JSON.stringify(submenuInfo) } });
    }, [submenuInfo, object]);

    const addListClick = useCallback(() => {
        setNameInput("")
        setSrcInput("")
        setDialogType(dialogStatusTypes.add)
        setDialog(true)
    }, [])

    const delListItemClick = useCallback((index) => {
        list.splice(index, 1)
        list.sort((a, b) => a.time - b.time)
        inputTextHandler("list", list);
    }, [inputTextHandler, list])

    const editorListItemClick = useCallback((index) => {
        setNameInput(list[index].name)
        setSrcInput(list[index].src)
        setTypeInput(list[index].contentType)
        setListItemIndex(index)
        setDialogType(dialogStatusTypes.editor)
        setDialog(true)
    }, [list])

    const listItemChangeHandler = useCallback((property, value, index) => {
        list[index][property] = (value)
        list.sort((a, b) => a.time - b.time)
        inputTextHandler("list", list);
    }, [inputTextHandler, list])

    return (<div>
        <hr></hr>
        <DialogModule
            open={isDialog}
            setOpen={setDialog}
            content={dialogContent}
            action={dialogAction}
        />
        <label></label><Button size="small" variant="contained" color="primary" onClick={() => { addListClick() }}>增加</Button>
        {
            list && list.map((lists, index) =>
                <div key={index} className={styles.list}>
                    {"Name"}
                    <Input disabled className={styles.videoInfoInput} value={lists.name} onChange={(e) => listItemChangeHandler("name", e.target.value, index)} />
                    {'src'}
                    <Input disabled className={styles.videoInfoInput} value={lists.src} onChange={(e) => listItemChangeHandler("src", e.target.value, index)} />
                    <Button size="small" variant="contained" color="default" onClick={() => { delListItemClick(index) }} >刪除</Button>{" "}
                    <Button size="small" variant="contained" color="default" onClick={() => { editorListItemClick(index) }} >修改</Button>
                </div>
            )
        }
    </div>);
}
