import React, { useCallback, useMemo } from 'react';
import Desktop from './Desktop';
import Mobile from './Mobile';

import { useStore, StoreTypes } from 'context';
import { useMedia } from 'customHooks/media';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { SideBarType } from 'constants/ReaderTools';
import { PainterMode } from 'constants/painterModes';

const ReaderTools = ({className})=> {
    const device = useMedia();
    const router = useStore(StoreTypes.router);
    

    const toggleBookmarkHandler = useCallback(() => {
            EventBus.emit({
                event: ReaderToolsEvent.ToggleBookmarkEvent
            });
        },[]);
    
    const toggleSideBarHandler = useCallback(
        sideBarType => e => {
            EventBus.emit({
                event: ReaderToolsEvent.ToggleSideBarEvent,
                payload: { sideBarType }
            });
        },
        []
    );


    const changePainterMode = useCallback(({ event, painterMode }) => e => {
        EventBus.emit({ event, payload: { painterMode } });
    }, []);

    const goBackToShelfHandler = useCallback(() => router.history.push('/bookshelf'),[router]);

    const eventHandlers = useMemo(()=>{
        return {
            toggleBookmarkHandler,
            toggleLeftSideBarHandler: toggleSideBarHandler(SideBarType.LEFT),
            clickDragHandler:()=> EventBus.emit({ event: ReaderToolsEvent.ClickDragEvent }),
            selectHandler:changePainterMode({ event: ReaderToolsEvent.ClickSelectEvent, painterMode: PainterMode.Selection }),
            painterHandler:changePainterMode({ event: ReaderToolsEvent.ClickPainterEvent, painterMode: PainterMode.Painting }),
            eraserHandler:changePainterMode({ event: ReaderToolsEvent.ClickEraserEvent, painterMode: PainterMode.Eraser }),
            goBackToShelfHandler
        }
    },[changePainterMode, goBackToShelfHandler, toggleBookmarkHandler, toggleSideBarHandler])

    

    return (
        // <>
        //  { 
        //         device === 'desktop' ?  
        //         <Desktop className={className} eventHandlers={eventHandlers}/> : 
        //         <Mobile className={className} eventHandlers={eventHandlers} /> 
        //     }
        // </>

        // just using Desktop for RWD, so comment Mobile
        <Desktop className={className} eventHandlers={eventHandlers}/> 
    )
}


export default ReaderTools;