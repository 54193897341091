import { useCallback } from 'react';
import { useFlipBook } from 'customHooks/canvas';
import { useStore, StoreTypes } from 'context';

export const useGoPageCommand = () => {
    const [{ isDoublePageMode }] = useStore(StoreTypes.reader);
    const flipBook = useFlipBook();
    const execute = useCallback(async ({ json: { pageIndex } }) => {
        const newPageIndex = Math.max(parseInt(isDoublePageMode ? Math.floor((pageIndex - 1) / 2) : pageIndex - 1), 0);
        flipBook({ pageIndex: newPageIndex, keepCanvas: true });
    }, [flipBook, isDoublePageMode]);

    return { execute };
};
