import React, { useRef, useEffect } from 'react';
import ReaderTools from 'components/ReaderTools';
import ReaderView from 'components/ReaderView';
import Modal from 'components/common/Modal';
import useModal from 'components/common/Modal/useModal';
import ActivityWork from 'components/Activity/ActivityWork';
import { useStore, StoreTypes } from 'context';
import * as actionTypes from 'constants/actionTypes';
import { AnnotationType } from 'constants/annotationTypes';
import { Roles } from 'constants/role';
import { useFetchBooks } from 'customHooks/book';
import { useCanvasEvents } from 'customHooks/canvas';
import { usePullAnnotations } from 'customHooks/reader';
import { debounce } from 'util/debounce';
import { useBookCanvasEventHandler } from 'events/eventHandlers/BookCanvasEventHandler';
import { EventBus } from 'events/EventBus';
import { ReaderEvent, ReaderToolsEvent } from 'events/EventTypes';
import styles from './index.module.scss';
import classnames from 'classnames';
import { Indicator } from 'components/Indicator';
import * as types from 'constants/actionTypes';
import ActivityPanel from 'components/Activity/ActivityPanel';
import { useReadAnnotations } from 'customHooks/db';
import { UserEvent } from 'events/EventTypes';
import Submenu from 'components/Submenu';

const Reader = props => {
  const bookId = props.match.params.bookId;
  let urlParams = new URLSearchParams(props.location.search);
  let paramsRole = urlParams.get('role');
  let paramsActivityId = urlParams.get('activityId');
  let paramsTeacherId = urlParams.get('teacherId');
  let paramsInteractiveObjectId = urlParams.get('interactiveObjectId');

  const [canvasState, canvasDispatch] = useStore(StoreTypes.canvas);
  const [{ submenuInfo, isDoublePageMode, pageIndex, readerToolDirection, indicatorInfo: { isActive: isIndicatorActive, message: indicatorMessage } }, readerDispatch] = useStore(StoreTypes.reader);
  const [{ annotationId, annotationType, activityInfo: { attendeeNumber } }, annotationDispatch] = useStore(StoreTypes.annotation);
  const [{ books, bookContent }, bookDispatch] = useStore(StoreTypes.books);
  const [{ role, token }, userDispatch] = useStore(StoreTypes.user);

  const { isLoading, setLoading, fetchBooks, fetchBook, getBookCatalog } = useFetchBooks();
  const [{ isShowing, components }, { openModal, closeModal }] = useModal();
  const { addCanvasImage } = useCanvasEvents({ canvasState, canvasDispatch });
  const { pullInteractiveMetaObjects } = usePullAnnotations();

  const imageFileHandlerRef = useRef(null);
  useBookCanvasEventHandler({ canvasState, canvasDispatch, imageFileHandlerRef });

  const { readAnnotations } = useReadAnnotations();

  useEffect(() => {
    console.log(annotationId, "------paramsActivityId------", paramsActivityId);
    if (!annotationId && !paramsActivityId && !paramsInteractiveObjectId) {
      EventBus.emit({
        event: UserEvent.GetUserIdentityEvent,
        payload: {
          callback: (async (result) => {
            let type = AnnotationType.GUEST;
            switch (result) {
              case Roles.GUEST:
                type = AnnotationType.GUEST
                break;
              case Roles.TEACHER:
                type = AnnotationType.CLASS_PREPARATION
                break;
              case Roles.EDITOR:
                type = AnnotationType.INTERACTIVE_OBJECT
                break;
              default:
                break;
            }
            //撈出indexdb裡面的備課資料，取第一筆進書
            let payload = {
              bookId,
              annotationType: type,
              name: "未命名",
              marks: []
            };
            let results = await readAnnotations({ bookId, annotationType: type });
            if (results.length > 0) {
              const latestAnnotation = results.sort((a, b) => {
                return b.updatedAt - a.updatedAt;
              })[0];
              payload.annotationId = latestAnnotation.id;
              payload.name = latestAnnotation.name;
              payload.marks = latestAnnotation.marks;
              payload.annotations = latestAnnotation.annotations;
            }
            EventBus.emit({
              event: ReaderEvent.CreateAndEnterAnnotationEvent,
              payload
            });
          })
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paramsRole === Roles.STUDENT) {
      userDispatch({ type: actionTypes.UPDATE_USER_INFO, role: paramsRole });
      annotationDispatch({
        type: types.UPDATE_ANNOTATION_INFO,
        annotationType: AnnotationType.ACTIVITY
      });
      annotationDispatch({ type: types.TOGGLE_ACTIVITY_PANEL, enable: true });
    }
    if (books.length === 0) {
      (async () => {
        const fetchedBooks = await fetchBooks({ token });
        if (bookId) {
          bookDispatch({ type: actionTypes.SET_BOOK_ID, payload: bookId });
          let pages = [];
          if (isDoublePageMode) {
            pages = [pageIndex * 2, pageIndex * 2 + 1];
          } else {
            pages = [pageIndex];
          }
          pages = pages.filter(page => !bookContent[page]);
          if (pages.length > 0) {
            fetchBook({ givenBooks: fetchedBooks, bookId, pages });
          }
        }
      })();
    } else {
      setLoading(false);
    }
    getBookCatalog({ bookId });
    return () => {
      bookDispatch({ type: types.RESET_BOOK_CONTENT });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const book = books.find(book => book.bookId === bookId);
    if (book && role === Roles.EDITOR) {
      (async () => {
        readerDispatch({ type: types.SET_INDICATOR_INFO, indicatorInfo: { isActive: true } });
        const result = await pullInteractiveMetaObjects({ id: paramsInteractiveObjectId, book });
        if (result) {
          annotationDispatch({ type: types.UPDATE_ANNOTATION_INFO, annotationId: paramsInteractiveObjectId, annotationType: AnnotationType.INTERACTIVE_OBJECT });
          EventBus.emit({ event: ReaderEvent.RefreshCanvasEvent, payload: { result } });
        }
        readerDispatch({ type: types.SET_INDICATOR_INFO, indicatorInfo: { isActive: false } });
      })();
    }
  }, [annotationDispatch, bookId, books, paramsInteractiveObjectId, pullInteractiveMetaObjects, readerDispatch, role]);

  const handleImageFileChange = e => {
    const reader = new FileReader();
    reader.onload = function(event) {
      const image = new Image();
      image.src = event.target.result;
      image.onload = function() {
        addCanvasImage({ image });
      };
    };
    reader.readAsDataURL(e.target.files[0]);
    imageFileHandlerRef.current.value = '';	// reset after read file
  };

  useEffect(() => {
    const resizeHandler = debounce(e => {
      const windowHeight = e.target.innerHeight;
      const windowWidth = e.target.innerWidth;
      if (windowHeight > windowWidth) {
        EventBus.emit({ event: ReaderToolsEvent.TogglePageModeEvent, payload: { isDoublePageMode: false } });
      } else if (windowHeight < windowWidth) {
        EventBus.emit({ event: ReaderToolsEvent.TogglePageModeEvent, payload: { isDoublePageMode: true } });
      }
    }, 100);
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    }
  }, [isDoublePageMode]);

  return !isLoading && (
    <div className={classnames(styles.reader, styles[readerToolDirection])}>
      {isIndicatorActive && <Indicator message={indicatorMessage} />}
      <ReaderView className={readerToolDirection} />
      <ReaderTools className={readerToolDirection} />
      <Modal
        isShowing={isShowing}
        closeModal={closeModal}
        openModal={openModal}
      >{components}</Modal>
      <input type='file' onChange={handleImageFileChange} style={{ display: 'none' }} ref={imageFileHandlerRef} />
      {attendeeNumber && <ActivityWork />}
      {annotationType === AnnotationType.ACTIVITY && <ActivityPanel paramsActivityId={paramsActivityId} paramsTeacherId={paramsTeacherId} />}
      {submenuInfo && <Submenu submenuInfo={submenuInfo} />}
    </div>
  );
};

export default Reader;
