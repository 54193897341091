import { useCallback, useEffect } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { UserEvent } from 'events/EventTypes';
import { useEvent } from 'events/EventBus';
import { Roles } from 'constants/role';
import { API } from 'api';

export const useUserEventHandler = () => {
    const [{ isLogin }, userDispatcher] = useStore(StoreTypes.user);
    const [, canvasDispatch] = useStore(StoreTypes.canvas);

    const setRoleToLocaleUpperCase = (identity) => {
        let role = null;
        switch (identity) {
            case '教師':
                role = Roles.TEACHER;
                break;
            case '編輯':
                role = Roles.EDITOR;
                break;
            case '學生':
                role = Roles.STUDENT;
                break;
            case 'guest':
            default:
                role = Roles.GUEST;
                break;
        }
        return role;
    }

    const initUserInfo = useCallback(async (token) => {
        try {
            if (token) {
                const options = { headers: { Authorization: token } }
                const result = await API.cloudFuncGet("https://asia-northeast1-oneclass-onemall.cloudfunctions.net/users/profile", options)
                const { identity, name } = result.data.profile
                const role = setRoleToLocaleUpperCase(identity)
                userDispatcher({
                    type: types.UPDATE_USER_INFO,
                    isLogin: true,
                    userId: result.data.id,
                    token,
                    name: name,
                    role
                });

                if (role === Roles.EDITOR) {
                    canvasDispatch({ type: types.CANVAS_TURN_ON_EXTRA_FIELDS_FOR_EDITOR });
                }
                return role;
            } else {
                userDispatcher({
                    type: types.UPDATE_USER_INFO,
                    name: "guest",
                    role: Roles.GUEST
                });
                return Roles.GUEST;
            }
        } catch (e) {
            userDispatcher({
                type: types.UPDATE_USER_INFO,
                name: "guest",
                role: Roles.GUEST
            });
        }
    }, [canvasDispatch, userDispatcher]);

    const fetchToken = useCallback((async (callback) => {
        let token = await new Promise((resolve, reject) => {
            window.checkNaniOneClass((result) => {
                resolve(result)
            });//
        });
        if (!token) {
            token = window.getNani_token() && JSON.parse(window.getNani_token()).jwt
        }
        const role = await initUserInfo(token);
        callback && callback(role);
    }), [initUserInfo]);

    useEffect(() => {
        fetchToken();
    }, [fetchToken])

    const getUserIdentityHandler = useCallback(({ callback }) => {
        fetchToken(callback);
    }, [fetchToken])

    const loginHandler = useCallback(async () => {
        if (isLogin) {
            try {
                window.logoutNaniOneClass();
            } catch (e) {
                window.nanilogout();
                window.NanilCoollogout();
            }
            userDispatcher({ type: types.RESET_USER_INFO });
        } else {
            let token = await new Promise((resolve, reject) => {
                window.loginNaniOneClass((result) => {
                    resolve(result)
                });//
            });
            if (!token) {
                token = await new Promise((resolve, reject) => {
                    window.getLoginNaniCool((result) => {
                        resolve(result)
                    });
                });
            }
            const role = await initUserInfo(token);
        }
    }, [initUserInfo, isLogin, userDispatcher]);

    useEvent({ event: UserEvent.LoginEvent }, loginHandler);
    useEvent({ event: UserEvent.GetUserIdentityEvent }, getUserIdentityHandler);
};
