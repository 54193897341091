import React from 'react';
import styles from './index.module.scss';


// empty for now
const Whiteboard = () => (
  <div className={styles.whiteboard}></div>
);


export default Whiteboard;