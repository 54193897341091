import React, { useCallback, useState } from 'react';
import Input from 'components/common/Input';
import styles from './index.module.scss';
import DialogModule from 'components/Dialogs';
import Button from '@material-ui/core/Button';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';

const videoCaptionLanguage = {
    English: '英文字幕',
    Chinese: '中文字幕'
}

const dialogStatusTypes = {
    add: 'add',
    editor: 'editor'
}

export const VideoComponent = ({ object }) => {
    const videoInfo = object.videoInfo ? JSON.parse(object.videoInfo) : {
        src: "",
        seekList: [],
        subtitles: [{
            "language": "en",
            "src": ""
        }, {
            "language": "zh-TW",
            "src": ""
        }]
    };
    const [en, ch] = videoInfo.subtitles;
    const seekList = videoInfo.seekList;
    const [isVideoDialog, setIsVideoDialog] = useState(false);
    const [videoDialogTitleInput, setVideoDialogTitleInput] = useState("");
    const [videoDialogTimeInput, setVideoDialogTimeInput] = useState(0);
    const [listItemIndex, setListItemIndex] = useState(0);
    const [dialogType, setDialogType] = useState("");

    const VideoListItemDialogContent = <div>
        {"段落"}
        <Input className={styles.videoInfoInput} value={videoDialogTitleInput} onChange={(e) => dialogTitleChangeHandler(e.target.value)} />
        {'時間'}
        <Input className={styles.videoInfoInput} type="number" value={videoDialogTimeInput} onChange={(e) => dialogTimeChangeHandler(e.target.value)} />

    </div>;
    const VideoListItemDialogAction = <div>
        <Button
            onClick={() => {
                setIsVideoDialog(false)
            }}
            color="primary"
        >
            取消
        </Button>
        <Button
            onClick={() => {
                setIsVideoDialog(false)
                if (!videoDialogTitleInput) return;
                if (dialogType === dialogStatusTypes.add) {
                    seekList.push({ title: videoDialogTitleInput, time: videoDialogTimeInput ? videoDialogTimeInput : 0 })
                } else {
                    seekList[listItemIndex].title = videoDialogTitleInput
                    seekList[listItemIndex].time = videoDialogTimeInput ? videoDialogTimeInput : 0
                }
                setDialogType("")
                seekList.sort((a, b) => a.time - b.time)
                inputTextHandler("seekList", seekList);
            }}
            color="primary" autoFocus
        >
            確定
        </Button>
    </div>;

    const dialogTitleChangeHandler = ((value) => {
        setVideoDialogTitleInput(value)
    })

    const dialogTimeChangeHandler = ((value) => {
        setVideoDialogTimeInput(value)
    })

    const inputTextHandler = useCallback((property, value) => {
        videoInfo[property] = value;
        EventBus.emit({ event: CanvasEvent.ModifyCanvasObjectPropertyEvent, payload: { object, property: "videoInfo", value: JSON.stringify(videoInfo) } });
    }, [object, videoInfo]);

    const srcChangeHandler = useCallback((value) => {
        inputTextHandler("src", value);
    }, [inputTextHandler]);

    const captionSrcChangeHandler = useCallback((language, value) => {
        videoInfo.subtitles.find((subtitle) => subtitle.language === language).src = value;
        inputTextHandler("subtitles", videoInfo.subtitles);
    }, [inputTextHandler, videoInfo]);

    const addListClick = useCallback(() => {
        setVideoDialogTitleInput("")
        setVideoDialogTimeInput(0)
        setDialogType(dialogStatusTypes.add)
        setIsVideoDialog(true)
    }, [])

    const delListItemClick = useCallback((index) => {
        seekList.splice(index, 1)
        seekList.sort((a, b) => a.time - b.time)
        inputTextHandler("seekList", seekList);
    }, [inputTextHandler, seekList])

    const editorListItemClick = useCallback((index) => {
        setVideoDialogTitleInput(seekList[index].title)
        setVideoDialogTimeInput(seekList[index].time)
        setListItemIndex(index)
        setDialogType(dialogStatusTypes.editor)
        setIsVideoDialog(true)
    }, [seekList])

    const listItemChangeHandler = useCallback((property, value, index) => {
        seekList[index][property] = value
        seekList.sort((a, b) => a.time - b.time)
        inputTextHandler("seekList", seekList);
    }, [inputTextHandler, seekList])

    return (<div>
        <hr></hr>
        <DialogModule
            open={isVideoDialog}
            setOpen={setIsVideoDialog}
            content={VideoListItemDialogContent}
            action={VideoListItemDialogAction}
        />
        <Input className={styles.videoInfoInput} label={"Src"} value={videoInfo.src} onChange={(e) => srcChangeHandler(e.target.value)} />
        <Input className={styles.videoInfoInput} label={videoCaptionLanguage.English} value={en.src} onChange={(e) => captionSrcChangeHandler("en", e.target.value)} />
        <Input className={styles.videoInfoInput} label={videoCaptionLanguage.Chinese} value={ch.src} onChange={(e) => captionSrcChangeHandler("zh-TW", e.target.value)} />
        <label></label><Button size="small" variant="contained" color="primary" onClick={() => { addListClick() }}>增加</Button>
        {
            seekList && seekList.map((list, index) =>
                <div className={styles.list}>
                    {"段落"}
                    <Input disabled className={styles.videoInfoInput} value={list.title} onChange={(e) => listItemChangeHandler("title", e.target.value, index)} />
                    {'時間'}
                    <Input disabled className={styles.videoInfoInput} type="number" value={list.time} onChange={(e) => listItemChangeHandler("time", e.target.value, index)} />
                    <Button size="small" variant="contained" color="default" onClick={() => { delListItemClick(index) }} >刪除</Button>{" "}
                    <Button size="small" variant="contained" color="default" onClick={() => { editorListItemClick(index) }} >修改</Button>
                </div>
            )
        }
    </div>);
}
