import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { ReaderZoomType } from 'constants/ReaderTools';
import { API } from 'api';
import { AnnotationType } from 'constants/annotationTypes';
import { useReadAnnotations, useCreateAnnotation, useUpdateAnnotation } from 'customHooks/db';
import { mergeCanvasJSON } from 'util/svg';
import { ReaderToolsEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';

export const usePullAnnotations = () => {
    const [{ token }] = useStore(StoreTypes.user);
    const { readAnnotationById } = useReadAnnotations();
    const createAnnotation = useCreateAnnotation();
    const updateAnnotation = useUpdateAnnotation();

    const pullInteractiveMetaObjects = useCallback(async ({ id, book }) => {
        const sliceSize = 10;
        let pages = book.pageInfos.map(pageInfo => pageInfo.pageIndex);
        let groupedPages = pages.reduce((acc, page) => {
            if (acc.length === 0 || acc[acc.length - 1].length >= sliceSize) {
                acc.push([page]);
            } else {
                acc[acc.length - 1].push(page);
            }
            return acc;
        }, []);
        let remoteUpdatedAt = null;
        const remoteRecord = await groupedPages.reduce((promiseChain, group) => {
            return promiseChain.then(result => {
                return API.postJson(`${process.env.REACT_APP_API_DOMAIN}/getInteractiveMetaObjects`, { token: { jwt: token }, interactiveObjectId: id, pages: group })
                    .then(res => {
                        if (res.status === 'success') {
                            remoteUpdatedAt = res.content.updatedAt;
                            return result.concat(res.content.interactiveMetaObjects);
                        }
                        return result;
                    });
            });
        }, Promise.resolve([]));
        const localRecord = await readAnnotationById({ id });
        if (localRecord) {
            if (localRecord.lastSyncedAt >= remoteUpdatedAt) {
                // local record is up-to-date, just use it
                return localRecord;
            } else if (!localRecord.isDirty) {
                // local is older and not modified for a while, overwrite with remote record
                return updateAnnotation(localRecord.id, { annotations: remoteRecord, lastSyncedAt: remoteUpdatedAt });
            } else {
                // local is modified but there is newer record from remote, merge
                let newAnnotations = remoteRecord.map(record => {
                    let localAnnotation = localRecord.annotations.find(v => v.pageIndex === record.pageIndex);
                    if (localAnnotation) {
                        return {
                            pageIndex: record.pageIndex,
                            annotation: JSON.stringify(mergeCanvasJSON(JSON.parse(record.annotation), JSON.parse(localAnnotation.annotation)))
                        };
                    } else {
                        return record;
                    }
                });
                return updateAnnotation(localRecord.id, { annotations: newAnnotations, lastSyncedAt: remoteUpdatedAt });
            }
        } else {
            // no local record, create with remote record
            return createAnnotation({ id, bookId: book.bookId, annotations: remoteRecord, type: AnnotationType.INTERACTIVE_OBJECT, lastSyncedAt: remoteUpdatedAt });
        }
    }, [token, readAnnotationById, updateAnnotation, createAnnotation]);

    return { pullInteractiveMetaObjects };
};

export const useUpdateFullWidthInfo = () => {
    const [{ isDoublePageMode, fullWidthInfo: { mode } }, readerDispatch] = useStore(StoreTypes.reader);
    const [{ books, bookId, style: { width, height } }] = useStore(StoreTypes.books);
    const book = books.find(book => book.bookId === bookId);

    const updateFullWidthInfo = useCallback(() => {
        if (!book || !width || !height) return;
        const { width: bookWidth, height: bookHeight } = book;
        let fullWidthInfo = { scale: 1, offset: 0 };
        if (mode && isDoublePageMode) {
            const bookScale = (bookWidth * 2) / bookHeight;
            const readerScale = width / height;
            if (bookScale < readerScale) {
                const factor = height / bookHeight;
                const offset = (width - bookWidth * 2 * factor) / 2;
                const scale = width / (bookWidth * 2 * factor);
                fullWidthInfo = { scale, offset };
            }
        }

        readerDispatch({ type: types.SET_FULL_WIDTH_INFO, fullWidthInfo });
    }, [book, height, isDoublePageMode, mode, readerDispatch, width]);

    return updateFullWidthInfo;
};

export const useUpdateReaderScaleInfo = () => {
    const [{ zoomType, fullWidthInfo: { offset } }, readerDispatch] = useStore(StoreTypes.reader);
    const [{ style: { width, height } }] = useStore(StoreTypes.books);

    const updateReaderScaleInfo = useCallback(() => {
        if (!width || !height) return;
        let offsetX;
        let offsetY;
        let scale = 2;
        switch (zoomType) {
            case ReaderZoomType.LeftTop:
                offsetX = -offset;
                offsetY = 0;
                break;
            case ReaderZoomType.RightTop:
                offsetX = -((width) * scale / 2) + offset;
                offsetY = 0;
                break;
            case ReaderZoomType.LeftBottom:
                offsetX = -offset
                offsetY = -(height * scale / 2);;
                break;
            case ReaderZoomType.RightBottom:
                offsetX = -(width * scale / 2) + offset;
                offsetY = -(height * scale / 2);
                break;
            case ReaderZoomType.OriginZoom:
                offsetX = 0;
                offsetY = 0;
                scale = 1;
                break;
            default:
                break;
        }
        readerDispatch({ type: types.SET_SCALE_INFO, offsetX, offsetY, scale });
    }, [height, offset, readerDispatch, width, zoomType]);

    return updateReaderScaleInfo;
};

export const useUpdateAreaZoomInfo = () => {
    const [{ fullWidthInfo: { offset, scale, mode }, isDoublePageMode }, readerDispatch] = useStore(StoreTypes.reader);
    const [{ style: { width, height }, bookContent }] = useStore(StoreTypes.books);

    const updateAreaZoomInfo = useCallback((rect) => {
        if (!rect || !rect.height) return;

        // make sure valus in rect is always from left-top to right-bottom
        if (rect.originWidth < 0) {
            rect.originX += rect.originWidth;
            rect.originWidth = rect.originWidth * -1;
        }
        if (rect.originHeight < 0) {
            rect.originY += rect.originHeight;
            rect.originHeight = rect.originHeight * -1;
        }

        const pageContentWidth = isDoublePageMode ? bookContent[0].width * 2 : bookContent[0].width;
        const pageContentHeight = bookContent[0].height;
        const pageScale = (height / pageContentHeight)
        const gapLF = ((width - pageContentWidth * pageScale) / 2)
        const gapTB = ((height - pageContentHeight * pageScale) / 2)

        let newScale = (height) / rect.originHeight;
        if (width/height < rect.originWidth/rect.originHeight) {
            newScale = (width) / rect.originWidth
            //console.log(`width:${width}, rect.originWidth:${rect.originWidth}, height:${height}, rect.originHeight:${rect.originHeight}, newScale:${newScale}`);
        }
        else {
            //console.log(`height:${height}, rect.originHeight:${rect.originHeight}, width:${width}, rect.originWidth:${rect.originWidth}, newScale:${newScale}`);
        }
        if(newScale>=5){
            newScale=5
        }
        if (mode) {
            const viewPageWidth = pageContentWidth * pageScale;
            rect.originX = gapLF + (rect.originX) * viewPageWidth / width;
            //rect.originX = rect.originX - rect.originWidth * (1- viewPageWidth / width) / 2;
            rect.originWidth = rect.originWidth * viewPageWidth / width;
        }
        const centerX = rect.originX + rect.originWidth * 0.5;
        const centerY = rect.originY + rect.originHeight * 0.5;
        
        const offsetX = -(centerX * newScale) + width / 2;
        const offsetY = -(centerY * newScale) + height / 2;
        
        //console.log(`gapLF:${gapLF}, gapTB:${gapTB}, offsetX:${offsetX}, offsetY:${offsetY}, rect.originX:${rect.originX}, rect.originY:${rect.originY}, rect.originWidth:${rect.originWidth}, rect.originHeight:${rect.originHeight}`);
        EventBus.emit({ event: ReaderToolsEvent.SetReaderZoomEvent, payload: { type: ReaderZoomType.AreaZoom } })
        readerDispatch({ type: types.SET_SCALE_INFO, offsetX, offsetY, scale: newScale });
    }, [bookContent, height, isDoublePageMode, mode, readerDispatch, width]);
    
    return updateAreaZoomInfo;
};

export const useUpdateAreaZoomForPageButton = () => {
    const [{ fullWidthInfo: { offset } }, readerDispatch] = useStore(StoreTypes.reader);
    const [{ style: { width } }] = useStore(StoreTypes.books);

    const updateAreaZoomForPageButton = useCallback((rect) => {
        if (!rect || !rect.height) return;
        const scale = 2;
        const offsetX = rect.originX < width / 2 ? -offset : -((width) * scale / 2) + offset;
        const offsetY = -(rect.originY * scale);
        EventBus.emit({ event: ReaderToolsEvent.SetReaderZoomEvent, payload: { type: ReaderZoomType.AreaZoom } })
        readerDispatch({ type: types.SET_SCALE_INFO, offsetX, offsetY, scale: scale });
    }, [offset, readerDispatch, width]);

    return updateAreaZoomForPageButton;
};


