import React, { useCallback, useEffect } from 'react';
import Input from 'components/common/Input';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import Button from '@material-ui/core/Button';
import { EventBus } from 'events/EventBus';
import { CanvasEvent, ReaderEvent } from 'events/EventTypes';
import { SideToolContent } from 'constants/sideToolContents';

const areaZoomType = {
    x: 'x',
    y: 'y',
    width: 'width',
    height: 'height',
}

export const AreaZoomComponent = ({ object }) => {
    const [{ drawAreaInfo }] = useStore(StoreTypes.reader);
    const [{ sideToolContent }] = useStore(StoreTypes.sideTool);
    const areaZoomInfo = object.areaZoomInfo ? JSON.parse(object.areaZoomInfo) : { x: 0, y: 0, width: 100, height: 100 }
    const { x, y, width, height } = areaZoomInfo;

    const changeAreaZoomInfo = useCallback((property, value) => {
        areaZoomInfo[property] = value;
        EventBus.emit({ event: CanvasEvent.ModifyCanvasObjectPropertyEvent, payload: { object, property: "areaZoomInfo", value: JSON.stringify(areaZoomInfo) } });
    }, [areaZoomInfo, object]);

    const openDrawAreaHandler = useCallback(() => {
        EventBus.emit({ event: ReaderEvent.DrawAreaEvent, payload: { isDrawArea: true } });
    }, [])

    useEffect(() => {
        sideToolContent === SideToolContent.CanvasObjectPropertyViewer && drawAreaInfo&&EventBus.emit({ event: CanvasEvent.ModifyCanvasObjectPropertyEvent, payload: { object, property: "areaZoomInfo", value: JSON.stringify(drawAreaInfo) } });
    }, [drawAreaInfo, object, sideToolContent])

    return (<div>
        <hr></hr>
        <Input className={styles.areaZoomInput} label={areaZoomType.x} value={x} onChange={(e) => changeAreaZoomInfo(areaZoomType.x, e.target.value)} />
        <Input className={styles.areaZoomInput} label={areaZoomType.y} value={y} onChange={(e) => changeAreaZoomInfo(areaZoomType.y, e.target.value)} />
        <Input className={styles.areaZoomInput} label={areaZoomType.width} value={width} onChange={(e) => changeAreaZoomInfo(areaZoomType.width, e.target.value)} />
        <Input className={styles.areaZoomInput} label={areaZoomType.height} value={height} onChange={(e) => changeAreaZoomInfo(areaZoomType.height, e.target.value)} />
        <Button className={styles.areaButton} variant="contained" color="primary" onClick={(e) => openDrawAreaHandler()} >繪制區塊</Button>

    </div>);
}