import React from 'react';
import Input from 'components/common/Input';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import styles from './index.module.scss';


export const VocabularyComponent = ({ object }) => {

  let vocabularyInfo = object.vocabularyInfo ? JSON.parse(object.vocabularyInfo) : {  word: '' }

  const changeHandler = infoType => e => {
    vocabularyInfo[infoType] = e.target.value;
    EventBus.emit({ event: CanvasEvent.ModifyCanvasObjectPropertyEvent, payload: { object, property: "vocabularyInfo", value: JSON.stringify(vocabularyInfo) } });
};

  return (
    <>
      <Input className={styles.vocabularyInput} label="Word" value={vocabularyInfo.word} onChange={changeHandler('word')} />
    </>
  )
};