import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { InteractiveObjectEvent } from 'events/EventTypes';
import { preserveSVGAspectRatio } from 'util/book';

export const InteractiveObjectView = ({ svgString, pageIndex, bookInfo, interactiveObjectState, setInteractiveObjectState }) => {
    const [{ isDoublePageMode, isMarkModeShow }] = useStore(StoreTypes.reader);
    const ref = useRef(null);
    const { width, height, LRFlip } = bookInfo;
    const { userAgent } = navigator;

    useEffect(() => {
        const node = ReactDOM.findDOMNode(ref.current);
        if (!node) return;
        const groups = node.querySelectorAll('g');
        groups.forEach(group => {
            if (group.id) {
                group.style.cursor = `pointer`;
                const handler = (event) => {
                    event.preventDefault();
                    EventBus.emit({
                        event: InteractiveObjectEvent.ClickInteractiveObjectEvent,
                        payload: { id: group.id, pageIndex, interactiveObjectState, setInteractiveObjectState, target: event.target }
                    });
                };

                if (/Android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
                    group.ontouchend = handler;
                } else {
                    group.onpointerup = handler;
                }
            }
        });
    }, [interactiveObjectState, pageIndex, setInteractiveObjectState, userAgent]);

    useEffect(() => {
        const node = ReactDOM.findDOMNode(ref.current);
        if (!node) return;
        for (let item of interactiveObjectState.state) {
            item.pageIndex.forEach(pageIndex => {
                const groups = node.querySelectorAll('g');
                if (groups) {
                    for (let displayIndex in item.display) {
                        const display = item.display[displayIndex];
                        for (let group of groups) {
                            if (group.id === display.id) {
                                for (let child of group.children) {
                                    if (child.style) {
                                        child.style.opacity = display.opacity;
                                    }
                                }
                                break;
                            }
                        }
                    }
                    for (let stageIndex in item.stage) {
                        const stage = item.stage[stageIndex];
                        let phases = [];
                        for (let group of groups) {
                            if (stage.phase.includes(group.id)) {
                                phases.push(group);
                            }
                        }
                        phases.forEach((phase, i) => {
                            const phaseIndex = stage.phase.indexOf(phase.id);
                            if (~phaseIndex) {
                                const element = phase.children[0];
                                element.style.opacity = stage.revealStage ^ (phaseIndex >= stage.current);
                            }
                        });
                    }
                    for (let imageIndex in item.image) {
                        const image = item.image[imageIndex];
                        for (let group of groups) {
                            if (group.id === image.id) {
                                const element = group.children[0];
                                element.setAttributeNS('http://www.w3.org/1999/xlink', 'href', image.src);
                                // replace image from item.src
                                break;
                            }
                        }
                    }
                }
            });
        }
    }, [interactiveObjectState]);

    return (
        <div className={classnames(styles.svgContent, styles[isMarkModeShow ? 'canvasObjectSavedSvgContentDisable' : 'canvasObjectSavedSvgContentEnable'])} ref={ref} dangerouslySetInnerHTML={{ __html: preserveSVGAspectRatio({ svg: svgString, width, height, LRFlip, pageIndex, isDoublePageMode }) }} />
    );
};
