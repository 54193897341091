import { useCallback } from 'react';
import useModal from 'components/common/Modal/useModal';
import { useStore, StoreTypes } from 'context';
import Repository from 'repositories/Repository';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';

const { ExtendedResourceRepository } = Repository;

export const useWordGridCommand = () => {
    const [{ bookId }] = useStore(StoreTypes.books);
    const [, { openModal, setModalComponents }] = useModal();

    const execute = useCallback(async ({ json: { wordGridInfo } }) => {
        const { src, word } = JSON.parse(wordGridInfo);
        try {
            const response = await ExtendedResourceRepository.getJSONContent({ bookId, pathName: src });
            const component = ModalComponentGenerator.generate(ModalComponentType.WordGrid, { wordGridResource: Object.values(response), defaultWord: word });
            setModalComponents(component);
            openModal();
        } catch (e) {
            console.error('WordGridCommand execute error', e);
        }
    }, [openModal, setModalComponents, bookId]);

    return { execute };
};
