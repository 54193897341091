import React, { useState, useCallback, useEffect, useRef } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';
// import FontAwesome from 'react-fontawesome';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { groupPages } from 'util/book';
import { EventBus } from 'events/EventBus';
import { ReaderEvent } from 'events/EventTypes';
import BookPage from 'components/BookPage';
import { InteractiveObjectView } from 'components/InteractiveObjectView';
import { PinchPanContainer } from 'components/PinchPanContainer';
import { useFetchBooks } from 'customHooks/book';
import { useReaderStrategyDecider } from 'customHooks/Strategies/ReaderStrategies';
import { BookFlipType } from 'constants/flipTypes';
import { useMedia } from 'customHooks/media';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const getCanvasSvg = str => {
  str = str.replace('<svg', '<svg preserveAspectRatio="xMidYMid"');
  return str;
};

const InitInteractiveObjectState = { version: 0, state: [] };

const BookView = ({ book }) => {
  const [bookData, setBookData] = useState({});
  const [{ isActive, canvasSVG }] = useStore(StoreTypes.canvas);
  const [{ isDoublePageMode, isMarkModeShow, pageIndex, scale }] = useStore(
    StoreTypes.reader
  );
  const [{ bookContent, interactiveObjectSVG }] = useStore(StoreTypes.books);
  const [interactiveObjectState, setInteractiveObjectState] = useState(
    InitInteractiveObjectState
  );
  const decider = useReaderStrategyDecider();
  const strategy = decider.getReaderStrategy();
  const bookViewRef = useRef(null);
  const { fetchBook } = useFetchBooks();
  const { bookId, pageInfos, LRFlip } = book;
  const bookRef = useRef();
  const device = useMedia();

  const setPageIndex = useCallback(pageIndex => {
    EventBus.emit({
      event: ReaderEvent.SetPageIndexEvent,
      payload: { pageIndex }
    });
  }, []);

  // const prevPage = useCallback(() => {
  //     EventBus.emit({
  //         event: ReaderEvent.ClickPreviousPageEvent,
  //         payload: {
  //             pageIndex: Math.max(pageIndex - 1, 0),
  //             isDoublePageMode,
  //             convertToSVG: true
  //         }
  //     });
  // }, [pageIndex, isDoublePageMode]);

  // const nextPage = useCallback(() => {
  //     const max = isDoublePageMode
  //         ? Math.floor((pageInfos.length - 1) / 2)
  //         : pageInfos.length - 1;
  //     EventBus.emit({
  //         event: ReaderEvent.ClickNextPageEvent,
  //         payload: {
  //             pageIndex: Math.min(pageIndex + 1, max),
  //             isDoublePageMode,
  //             convertToSVG: true
  //         }
  //     });
  // }, [isDoublePageMode, pageIndex, pageInfos.length]);

  useEffect(() => {
    const data = groupPages(bookContent, isDoublePageMode);
    setBookData(data);
    // reset interactive object state
    setInteractiveObjectState(InitInteractiveObjectState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LRFlip, bookContent, isDoublePageMode]);

  const slideRenderer = useCallback(
    ({ index, key }) => {
      const page = bookData[index];
      if (!page && Math.abs(index - pageIndex) < 3) {
        let pages = [];
        if (isDoublePageMode) {
          pages = [index * 2, index * 2 + 1];
        } else {
          pages = [index];
        }

        fetchBook({ bookId, pages });
      }

      return page ? (
        <PinchPanContainer key={key} index={page[0].pageIndex} LRFlip={LRFlip}>
          {page.map(item => {
            return item ? (
              <div className={styles.page} key={item.pageIndex} ref={bookRef}>
                <BookPage
                  content={item.svg}
                  bookInfo={book}
                  pageIndex={item.pageIndex}
                />

                {/* 新增的svg層  start */}
                {interactiveObjectSVG[item.pageIndex] && (
                  <InteractiveObjectView
                    svgString={interactiveObjectSVG[item.pageIndex]}
                    pageIndex={item.pageIndex}
                    bookInfo={book}
                    interactiveObjectState={interactiveObjectState}
                    setInteractiveObjectState={setInteractiveObjectState}
                  />
                )}
                {/* 新增的svg層  end */}
              </div>
            ) : (
              <div className={styles.page} key="blank" />
            );
          })}

          {canvasSVG[index] && !isActive && (
            <div
              className={classnames(styles.svgContent, styles.canvasSvgContent)}
              dangerouslySetInnerHTML={{
                __html: getCanvasSvg(canvasSVG[index])
              }}
            />
          )}
        </PinchPanContainer>
      ) : (
        <div key={key} />
      );
    },
    [
      LRFlip,
      book,
      bookData,
      bookId,
      canvasSVG,
      fetchBook,
      interactiveObjectSVG,
      interactiveObjectState,
      isActive,
      isDoublePageMode,
      pageIndex
    ]
  );

  const bookPageRenderer = useCallback(
    ({ index, key }) => {
      const page = bookData[index];
      let pages = [];
      const threshold = 3;
      if (isDoublePageMode) {
        let startPage = Math.max(0, index * 2 - threshold * 2);
        let endPage = Math.min(
          index * 2 + 1 + threshold * 2,
          pageInfos.length - 1
        );
        for (let i = startPage; i <= endPage; ++i) {
          pages.push(i);
        }
      } else {
        let startPage = Math.max(0, index - threshold);
        let endPage = Math.min(index + threshold, pageInfos.length - 1);
        for (let i = startPage; i <= endPage; ++i) {
          pages.push(i);
        }
      }
      pages = pages.filter(page => !bookContent[page]);
      if (pages.length > 0) {
        fetchBook({ bookId, pages });
      }

      return page ? (
        <PinchPanContainer key={key} index={page[0].pageIndex} LRFlip={LRFlip}>
          {page.map(item => {
            return item ? (
              <div className={styles.page} key={item.pageIndex} ref={bookRef}>
                <BookPage
                  content={item.svg}
                  bookInfo={book}
                  pageIndex={item.pageIndex}
                />

                {/* 新增的svg層  start */}
                {interactiveObjectSVG[item.pageIndex] && (
                  <InteractiveObjectView
                    svgString={interactiveObjectSVG[item.pageIndex]}
                    pageIndex={item.pageIndex}
                    bookInfo={book}
                    interactiveObjectState={interactiveObjectState}
                    setInteractiveObjectState={setInteractiveObjectState}
                  />
                )}
                {/* 新增的svg層  end */}
              </div>
            ) : (
              <div className={styles.page} key="blank" />
            );
          })}

          {canvasSVG[index] && !isActive && (
            <div
              className={classnames(styles.svgContent, styles.canvasSvgContent)}
              dangerouslySetInnerHTML={{
                __html: getCanvasSvg(canvasSVG[index])
              }}
            />
          )}
        </PinchPanContainer>
      ) : (
        <div key={key} />
      );
    },
    [
      LRFlip,
      book,
      bookContent,
      bookData,
      bookId,
      canvasSVG,
      fetchBook,
      interactiveObjectSVG,
      interactiveObjectState,
      isActive,
      isDoublePageMode,
      pageInfos.length
    ]
  );

  return (
    <div className={styles.bookViewContainer}>
      <div className={styles.bookView} ref={bookViewRef}>
        {book && bookPageRenderer({ index: pageIndex, key: pageIndex })
        // <VirtualizeSwipeableViews
        //     containerStyle={{ height: '100%' }}
        //     style={{ height: '100%' }}
        //     animateTransitions={false}
        //     disabled={!strategy || !strategy.isReaderSwipeable() || scale > 1}
        //     index={pageIndex}
        //     onChangeIndex={setPageIndex}
        //     slideCount={pageInfos.length}
        //     slideRenderer={slideRenderer}
        //     enableMouseEvents={device !== 'desktop'}
        //     axis={LRFlip === BookFlipType.LEFT_TO_RIGHT ? 'x' : 'x-reverse'}
        // />
        }
      </div>
      {/* {strategy && strategy.isReaderSwitchPageAvailable() && <div>
                <FontAwesome
                    name='angle-left'
                    size='4x'
                    className={classnames(styles.ctrlButton, styles.left)}
                    onClick={LRFlip === BookFlipType.LEFT_TO_RIGHT ? prevPage : nextPage}
                />
                <FontAwesome
                    name='angle-right'
                    size='4x'
                    className={classnames(styles.ctrlButton, styles.right)}
                    onClick={LRFlip === BookFlipType.LEFT_TO_RIGHT ? nextPage : prevPage}
                />
            </div>} */}
    </div>
  );
};

export default BookView;
