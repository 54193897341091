import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import styles from './index.module.scss';
import {
  faBackward,
  faForward,
  faHeadphones,
  faPlay,
  faPause,
  faTimesCircle,
  faClosedCaptioning,
  faVolumeMute,
  faVolumeDown,
  faVolumeUp,
  faUndo,
  faExpand,
  faEnvelope,
  faTools,
  faAngleLeft,
  faAngleRight,
  faChevronLeft,
  faChevronRight,
  faSearchPlus,
  faHome,
  faLongArrowAltRight,
  faTimes,
  faPencilAlt
} from '@fortawesome/free-solid-svg-icons';

const fontawesomeIconMap = {
  backward: faBackward,
  forward: faForward,
  headphones: faHeadphones,
  play: faPlay,
  pause: faPause,
  searchPlus: faSearchPlus,
  times: faTimes,
  timesCircle: faTimesCircle,
  closedCaptioning: faClosedCaptioning,
  volumeMute: faVolumeMute,
  volumeDown: faVolumeDown,
  volumeUp: faVolumeUp,
  undo: faUndo,
  expand: faExpand,
  envelope: faEnvelope,
  tools: faTools,
  angleLeft: faAngleLeft,
  angleRight: faAngleRight,
  chevronLeft: faChevronLeft,
  chevronRight: faChevronRight,
  home: faHome,
  longArrowAltRight: faLongArrowAltRight,
  pencilAlt: faPencilAlt
};

const PenLineType = {
  PenStraight: 'assets/icon/pen/straight.svg',
  PenCurve: 'assets/icon/pen/curve.svg'
};

const LineType = {
  Straight: 'assets/icon/line/straight.svg',
  Arrow: 'assets/icon/line/arrow.svg',
  Dotted: 'assets/icon/line/dotted.svg'
};

const Shape = {
  RightTriangle: 'assets/icon/shape/right-triangle.svg',
  IsoscelesTriangle: 'assets/icon/shape/isosceles-triangle.svg',
  Circle: 'assets/icon/shape/circle.svg',
  Rectangle: 'assets/icon/shape/rectangle.svg'
};

const Vocabulary = {
  AudioPlay: 'assets/icon/vocabulary/audioPlay.svg',
  SwitchLang: 'assets/icon/vocabulary/switchLang.svg'
};

export const Stamps = {
  Btn1: 'assets/icon/stamp/btn/Btn-01.svg',
  Btn2: 'assets/icon/stamp/btn/Btn-02.svg',
  Btn3: 'assets/icon/stamp/btn/Btn-03.svg',
  Btn4: 'assets/icon/stamp/btn/Btn-04.svg',
  Btn5: 'assets/icon/stamp/btn/Btn-05.svg',
  Btn6: 'assets/icon/stamp/btn/Btn-06.svg',
  Btn8: 'assets/icon/stamp/btn/Btn-08.svg',
  Btn9: 'assets/icon/stamp/btn/Btn-09.svg',
  Btn10: 'assets/icon/stamp/btn/Btn-10.svg',
  Btn11: 'assets/icon/stamp/btn/Btn-11.svg',
  Btn12: 'assets/icon/stamp/btn/Btn-12.svg',
  Btn13: 'assets/icon/stamp/btn/Btn-13.svg',
  Btn14: 'assets/icon/stamp/btn/Btn-14.svg',
  Btn15: 'assets/icon/stamp/btn/Btn-15.svg',
  Btn16: 'assets/icon/stamp/btn/Btn-16.svg',
  Btn17: 'assets/icon/stamp/btn/Btn-17.svg',
  Btn18: 'assets/icon/stamp/btn/Btn-18.svg',
  Btn19: 'assets/icon/stamp/btn/Btn-19.svg',
  Btn20: 'assets/icon/stamp/btn/Btn-20.svg',
  Btn21: 'assets/icon/stamp/btn/Btn-21.svg',
  Btn22: 'assets/icon/stamp/btn/Btn-22.svg',
  Btn23: 'assets/icon/stamp/btn/Btn-23.svg',
  Btn24: 'assets/icon/stamp/btn/Btn-24.svg',
  Btn25: 'assets/icon/stamp/btn/Btn-25.svg',
  Btn26: 'assets/icon/stamp/btn/Btn-26.svg',
  Btn27: 'assets/icon/stamp/btn/Btn-27.svg',
  Btn28: 'assets/icon/stamp/btn/Btn-28.svg',
  Btn29: 'assets/icon/stamp/btn/Btn-29.svg',
  Btn30: 'assets/icon/stamp/btn/Btn-30.svg',
  Btn31: 'assets/icon/stamp/btn/Btn-31.svg',
  Btn32: 'assets/icon/stamp/btn/Btn-32.svg',
  Btn33: 'assets/icon/stamp/btn/Btn-33.svg',
  Btn34: 'assets/icon/stamp/btn/Btn-34.svg',
  Btn35: 'assets/icon/stamp/btn/Btn-35.svg',
  Btn36: 'assets/icon/stamp/btn/Btn-36.svg',
  Btn37: 'assets/icon/stamp/btn/Btn-37.svg',
  Btn38: 'assets/icon/stamp/btn/Btn-38.svg',
  Btn39: 'assets/icon/stamp/btn/Btn-39.svg',
  Btn40: 'assets/icon/stamp/btn/Btn-40.svg',
  Btn41: 'assets/icon/stamp/btn/Btn-41.svg',
  Btn42: 'assets/icon/stamp/btn/Btn-42.svg',
  Btn43: 'assets/icon/stamp/btn/Btn-43.svg',
  Btn44: 'assets/icon/stamp/btn/Btn-44.svg',
  Btn45: 'assets/icon/stamp/btn/Btn-45.svg',
  Btn46: 'assets/icon/stamp/btn/Btn-46.svg',
  Btn47: 'assets/icon/stamp/btn/Btn-47.svg',
  Btn48: 'assets/icon/stamp/btn/Btn-48.svg',
  Btn49: 'assets/icon/stamp/btn/Btn-49.svg',
  Btn50: 'assets/icon/stamp/btn/Btn-50.svg',
  Btn51: 'assets/icon/stamp/btn/Btn-51.svg',
  Btn52: 'assets/icon/stamp/btn/Btn-52.svg',
  Btn53: 'assets/icon/stamp/btn/Btn-53.svg',
  Btn54: 'assets/icon/stamp/btn/Btn-54.svg',
  Btn55: 'assets/icon/stamp/btn/Btn-55.svg',
  Btn56: 'assets/icon/stamp/btn/Btn-56.svg',
  Btn57: 'assets/icon/stamp/btn/Btn-57.svg',
  Btn58: 'assets/icon/stamp/btn/Btn-58.svg',
  Btn59: 'assets/icon/stamp/btn/Btn-59.svg',
  Btn60: 'assets/icon/stamp/btn/Btn-60.svg',
  Btn61: 'assets/icon/stamp/btn/Btn-61.svg',
  Btn62: 'assets/icon/stamp/btn/Btn-62.svg',
  Btn63: 'assets/icon/stamp/btn/Btn-63.svg',
  Btn64: 'assets/icon/stamp/btn/Btn-64.svg',
  Btn65: 'assets/icon/stamp/btn/Btn-65.svg',
  Btn66: 'assets/icon/stamp/btn/Btn-66.svg',
  Btn67: 'assets/icon/stamp/btn/Btn-67.svg',
  Btn68: 'assets/icon/stamp/btn/Btn-68.svg',
  Btn69: 'assets/icon/stamp/btn/Btn-69.svg',
  Btn70: 'assets/icon/stamp/btn/Btn-70.svg',
  Btn71: 'assets/icon/stamp/btn/Btn-71.svg',
  Btn72: 'assets/icon/stamp/btn/Btn-72.svg',
  Btn73: 'assets/icon/stamp/btn/Btn-73.svg',
  Btn74: 'assets/icon/stamp/btn/Btn-74.svg',
  Btn75: 'assets/icon/stamp/btn/Btn-75.svg',
  Btn76: 'assets/icon/stamp/btn/Btn-76.svg',
  Btn77: 'assets/icon/stamp/btn/Btn-77.svg',
  Btn78: 'assets/icon/stamp/btn/Btn-78.svg',
  Btn79: 'assets/icon/stamp/btn/Btn-79.svg',
  Btn80: 'assets/icon/stamp/btn/Btn-80.svg',
  Btn81: 'assets/icon/stamp/btn/Btn-81.svg',
  Btn82: 'assets/icon/stamp/btn/Btn-82.svg',
  Btn83: 'assets/icon/stamp/btn/Btn-83.svg',
  Btn84: 'assets/icon/stamp/btn/Btn-84.svg',
  Btn85: 'assets/icon/stamp/btn/Btn-85.svg',
  Btn86: 'assets/icon/stamp/btn/Btn-86.svg',
  Btn87: 'assets/icon/stamp/btn/Btn-87.svg',
  Btn88: 'assets/icon/stamp/btn/Btn-88.svg',
  Btn89: 'assets/icon/stamp/btn/Btn-89.svg',
  Btn90: 'assets/icon/stamp/btn/Btn-90.svg',
  Btn91: 'assets/icon/stamp/btn/Btn-91.svg',
  Btn92: 'assets/icon/stamp/btn/Btn-92.svg',
  Btn93: 'assets/icon/stamp/btn/Btn-93.svg',
  Btn94: 'assets/icon/stamp/btn/Btn-94.svg',
  Btn95: 'assets/icon/stamp/btn/Btn-95.svg',
  Btn96: 'assets/icon/stamp/btn/Btn-96.svg',
  Btn97: 'assets/icon/stamp/btn/Btn-97.svg',
  Btn98: 'assets/icon/stamp/btn/Btn-98.svg',
  Btn99: 'assets/icon/stamp/btn/Btn-99.svg',
  Btn100: 'assets/icon/stamp/btn/Btn-100.svg',
  Btn101: 'assets/icon/stamp/btn/Btn-101.svg',
  Btn102: 'assets/icon/stamp/btn/Btn-102.svg',
  Btn103: 'assets/icon/stamp/btn/Btn-103.svg',
  Btn104: 'assets/icon/stamp/btn/Btn-104.svg',
  Btn105: 'assets/icon/stamp/btn/Btn-105.svg',
  Btn106: 'assets/icon/stamp/btn/Btn-106.svg',
  Btn107: 'assets/icon/stamp/btn/Btn-107.svg',
  Btn108: 'assets/icon/stamp/btn/Btn-108.svg',
  Btn109: 'assets/icon/stamp/btn/Btn-109.svg',
  Btn110: 'assets/icon/stamp/btn/Btn-110.svg',
  Btn111: 'assets/icon/stamp/btn/Btn-111.svg',
  Btn112: 'assets/icon/stamp/btn/Btn-112.svg',
  Btn113: 'assets/icon/stamp/btn/Btn-113.svg',
  Btn114: 'assets/icon/stamp/btn/Btn-114.svg',
  Btn115: 'assets/icon/stamp/btn/Btn-115.svg',
  Btn116: 'assets/icon/stamp/btn/Btn-116.svg',
  Btn117: 'assets/icon/stamp/btn/Btn-117.svg',
  Btn118: 'assets/icon/stamp/btn/Btn-118.svg',
  Btn119: 'assets/icon/stamp/btn/Btn-119.svg',
  Btn120: 'assets/icon/stamp/btn/Btn-120.svg',
  Btn121: 'assets/icon/stamp/btn/Btn-121.svg',
  Btn122: 'assets/icon/stamp/btn/Btn-122.svg',
  Btn123: 'assets/icon/stamp/btn/Btn-123.svg',
  Btn124: 'assets/icon/stamp/btn/Btn-124.svg',
  Btn125: 'assets/icon/stamp/btn/Btn-125.svg',
  Btn126: 'assets/icon/stamp/btn/Btn-126.svg',
  Btn127: 'assets/icon/stamp/btn/Btn-127.svg',
  Btn12770: 'assets/icon/stamp/btn/Btn-127-70.svg',
  Btn128: 'assets/icon/stamp/btn/Btn-128.svg',
  Btn12870: 'assets/icon/stamp/btn/Btn-128-70.svg',
  Btn129: 'assets/icon/stamp/btn/Btn-129.svg',
  Btn130: 'assets/icon/stamp/btn/Btn-130.svg',
  Btn131: 'assets/icon/stamp/btn/Btn-131.svg',
  Btn132: 'assets/icon/stamp/btn/Btn-132.svg',
  Btn133: 'assets/icon/stamp/btn/Btn-133.svg',
  Btn134: 'assets/icon/stamp/btn/Btn-134.svg',
  Btn135: 'assets/icon/stamp/btn/Btn-135.svg',
  Btn136: 'assets/icon/stamp/btn/Btn-136.svg',
  Btn137: 'assets/icon/stamp/btn/Btn-137.svg',
  Btn138: 'assets/icon/stamp/btn/Btn-138.svg',
  Btn139: 'assets/icon/stamp/btn/Btn-139.svg',
  Btn140: 'assets/icon/stamp/btn/Btn-140.svg',
  Btn141: 'assets/icon/stamp/btn/Btn-141.svg',
  Btn142: 'assets/icon/stamp/btn/Btn-142.svg',
  Btn143: 'assets/icon/stamp/btn/Btn-143.svg',
  Btn144: 'assets/icon/stamp/btn/Btn-144.svg',
  Btn145: 'assets/icon/stamp/btn/Btn-145.svg',
  Btn146: 'assets/icon/stamp/btn/Btn-146.svg',
  Btn147: 'assets/icon/stamp/btn/Btn-147.svg',
  Btn148: 'assets/icon/stamp/btn/Btn-148.svg',
  Btn149: 'assets/icon/stamp/btn/Btn-149.svg',
  Btn150: 'assets/icon/stamp/btn/Btn-150.svg',
  Btn151: 'assets/icon/stamp/btn/Btn-151.svg',
  Btn152: 'assets/icon/stamp/btn/Btn-152.svg',
  Btn153: 'assets/icon/stamp/btn/Btn-153.svg',
  Btn154: 'assets/icon/stamp/btn/Btn-154.svg',
  Btn155: 'assets/icon/stamp/btn/Btn-155.svg',
  Btn156: 'assets/icon/stamp/btn/Btn-156.svg',
  Btn157: 'assets/icon/stamp/btn/Btn-157.svg',
  Btn158: 'assets/icon/stamp/btn/Btn-158.svg',
  Btn159: 'assets/icon/stamp/btn/Btn-159.svg',
  Btn160: 'assets/icon/stamp/btn/Btn-160.svg',
  Btn161: 'assets/icon/stamp/btn/Btn-161.svg',
  Btn162: 'assets/icon/stamp/btn/Btn-162.svg',
  Btn163: 'assets/icon/stamp/btn/Btn-163.svg',
  Btn164: 'assets/icon/stamp/btn/Btn-164.svg',
  Btn165: 'assets/icon/stamp/btn/Btn-165.svg',
  Btn166: 'assets/icon/stamp/btn/Btn-166.svg',
  Btn167: 'assets/icon/stamp/btn/Btn-167.svg',
  Btn168: 'assets/icon/stamp/btn/Btn-168.svg',
  Btn169: 'assets/icon/stamp/btn/Btn-169.svg',
  Btn170: 'assets/icon/stamp/btn/Btn-170.svg',
  Btn171: 'assets/icon/stamp/btn/Btn-171.svg',
  Btn172: 'assets/icon/stamp/btn/Btn-172.svg',
  Btn173: 'assets/icon/stamp/btn/Btn-173.svg',
  Btn174: 'assets/icon/stamp/btn/Btn-174.svg',
  Btn175: 'assets/icon/stamp/btn/Btn-175.svg',
  Btn176: 'assets/icon/stamp/btn/Btn-176.svg',
  Btn177: 'assets/icon/stamp/btn/Btn-177.svg',
  Btn178: 'assets/icon/stamp/btn/Btn-178.svg',
  Btn179: 'assets/icon/stamp/btn/Btn-179.svg',
  Btn180: 'assets/icon/stamp/btn/Btn-180.svg',
  Btn181: 'assets/icon/stamp/btn/Btn-181.svg',
  Btn182: 'assets/icon/stamp/btn/Btn-182.svg',
  Btn183: 'assets/icon/stamp/btn/Btn-183.svg',
  Btn184: 'assets/icon/stamp/btn/Btn-184.svg',
  Btn185: 'assets/icon/stamp/btn/Btn-185.svg',
  Btn186: 'assets/icon/stamp/btn/Btn-186.svg',
  Btn187: 'assets/icon/stamp/btn/Btn-187.svg',
  Btn188: 'assets/icon/stamp/btn/Btn-188.svg',
  Btn189: 'assets/icon/stamp/btn/Btn-189.svg',
  Btn190: 'assets/icon/stamp/btn/Btn-190.svg',
  Btn191: 'assets/icon/stamp/btn/Btn-191.svg',
  Btn192: 'assets/icon/stamp/btn/Btn-192.svg',
  Btn193: 'assets/icon/stamp/btn/Btn-193.svg',
  Btn194: 'assets/icon/stamp/btn/Btn-194.svg',
  Btn195: 'assets/icon/stamp/btn/Btn-195.svg',
  Btn196: 'assets/icon/stamp/btn/Btn-196.svg',
  Btn197: 'assets/icon/stamp/btn/Btn-197.svg',
  Btn198: 'assets/icon/stamp/btn/Btn-198.svg',
  Btn199: 'assets/icon/stamp/btn/Btn-199.svg',
  Btn200: 'assets/icon/stamp/btn/Btn-200.svg',
  Btn201: 'assets/icon/stamp/btn/Btn-201.svg',
  Btn202: 'assets/icon/stamp/btn/Btn-202.svg'
};

const Aligns = {
  HorizontalCenter: 'assets/icon/tools/Horizontal-center.svg',
  HorizontalLeft: 'assets/icon/tools/Horizontal-left.svg',
  HorizontalRight: 'assets/icon/tools/Horizontal-right.svg',
  VerticalBottom: 'assets/icon/tools/vertical-bottom.svg',
  VerticalCenter: 'assets/icon/tools/vertical-center.svg',
  VerticalTop: 'assets/icon/tools/vertical-top.svg'
};

const BookShelf = {
  Editor: 'assets/icon/editor.svg'
}

export const IconMap = {
  OnePage: 'assets/icon/one-page.svg',
  DoublePage: 'assets/icon/double-page.svg',
  Painting: 'assets/icon/painting.svg',
  Highlighter: 'assets/icon/highlighter.svg',
  Shape: 'assets/icon/shape.svg',
  Line: 'assets/icon/line.svg',
  DeleteAll: 'assets/icon/delete-all.svg',
  Delete: 'assets/icon/delete.svg',
  AllScreen: 'assets/icon/all-screen.svg',
  IntervalZoomIn: 'assets/icon/interval-zoom-in.svg',
  Whiteboard: 'assets/icon/whiteboard.svg',
  NumberPicker: 'assets/icon/select-number.svg',
  Draw: 'assets/icon/draw.svg',
  MouseHand: 'assets/icon/mousehand.svg',
  Mark: 'assets/icon/mark.svg',
  Select: 'assets/icon/select.svg',
  Img: 'assets/icon/image.svg',
  Text: 'assets/icon/text.svg',
  SVG: 'assets/icon/SVG.svg',
  Setup: 'assets/icon/setup.svg',
  Export: 'assets/icon/export.svg',
  Close: 'assets/icon/close.svg',
  Content: 'assets/icon/content.svg',
  ContentBack: 'assets/icon/content-back.svg',
  ArrowRight: 'assets/icon/arrow-right.svg',
  ArrowLeft: 'assets/icon/arrow-left.svg',
  PageSercher: 'assets/icon/page-searcher.svg',
  Bookmark: 'assets/icon/bookmark.svg',
  Bookcase: 'assets/icon/bookcase.svg',
  Save: 'assets/icon/save.svg',
  Extend: 'assets/icon/extend.svg',
  // stamp
  ...Stamps,
  ...Aligns,
  ...Vocabulary,
  ...Shape,
  ...LineType,
  ...PenLineType,
  ...BookShelf
};

const Icon = ({ className, name, type = 'img' }) => (
  <>
    {type === 'img' ? (
      <img
        className={classnames(styles.svg, className)}
        src={IconMap[name]}
        alt=""
      />
    ) : (
        <FontAwesomeIcon icon={fontawesomeIconMap[name]} />
      )}
  </>
);

export default Icon;
