import React, { useCallback, useState, useRef } from 'react';
import Button from 'components/Button/index';
import Icon from 'components/Icon';
import PainterPanel from 'components/Painter/PainterPanel';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent, PainterEvent } from 'events/EventTypes';
import { PainterMode } from 'constants/painterModes';
import { PainterToolType } from 'constants/painterTypes';
import useModal from 'components/common/Modal/useModal';
import ContextMenu from 'components/ContextMenu';
import useContextMenu from 'customHooks/contextMenu';

import styles from './index.module.scss';

const PanelState = {
  None: 'None',
  Pen: 'Pen',
  Highlighter: 'Highlighter',
  Shape: 'Shape',
  Line: 'Line'
};

const useExtendedContentToolsEvent = ({ eventBusType, canvasState }) => {
  const { painterToolType, painterTool, fillType } = canvasState;

  const eventHandler = useCallback(
    ({ event, painterMode, painterToolType }) => {
      EventBus.emit({
        eventBusType,
        event,
        payload: { painterMode, painterToolType }
      });
    },
    [eventBusType]
  );

  const clickDragEvent = () =>
    eventHandler({
      event: ReaderToolsEvent.ClickDragEvent
    });

  const clickSelectEvent = () =>
    eventHandler({
      event: ReaderToolsEvent.ClickSelectEvent,
      painterMode: PainterMode.Selection
    });

  const clickPainterEvent = PainterToolType => {
    eventHandler({
      event: ReaderToolsEvent.ClickPainterEvent,
      painterMode: PainterMode.Painting,
      painterToolType: PainterToolType
    });
  };

  const clickEraseAllEvent = () =>
    eventHandler({ event: ReaderToolsEvent.ClickEraseAllEvent });

  const clickEraserEvent = () =>
    eventHandler({
      event: ReaderToolsEvent.ClickEraserEvent,
      painterMode: PainterMode.Eraser
    });

  return [
    { painterToolType, painterTool, fillType },
    {
      clickDragEvent,
      clickSelectEvent,
      clickPainterEvent,
      clickEraseAllEvent,
      clickEraserEvent
    }
  ];
};

export const ExtendedContentContextMenu = ({ eventBusType, canvasState }) => {
  const [
    _,
    {
      clickDragEvent,
      clickSelectEvent,
      clickPainterEvent,
      clickEraseAllEvent,
      clickEraserEvent
    }
  ] = useExtendedContentToolsEvent({ eventBusType, canvasState });

  const contextMenuRef = useRef();

  const [isMenuVisible] = useContextMenu(contextMenuRef);

  return (
    <ContextMenu.Box ref={contextMenuRef} isVisible={isMenuVisible}>
      <ContextMenu.Item onClick={clickDragEvent}>滑鼠</ContextMenu.Item>
      <ContextMenu.Item onClick={clickSelectEvent}>選取</ContextMenu.Item>
      <ContextMenu.Item onClick={() => clickPainterEvent(PainterToolType.Pen)}>
        畫筆
      </ContextMenu.Item>
      <ContextMenu.Item
        onClick={() => clickPainterEvent(PainterToolType.Highlighter)}
      >
        螢光筆
      </ContextMenu.Item>
      <ContextMenu.Item onClick={clickEraseAllEvent}>全部刪除</ContextMenu.Item>
      <ContextMenu.Item onClick={clickEraserEvent}>部分刪除</ContextMenu.Item>
    </ContextMenu.Box>
  );
};

export const ExtendedContentTools = ({ eventBusType, canvasState }) => {
  const [, { closeModal }] = useModal();
  const [panelState, setPanelState] = useState(PanelState.None);
  const panelCloseHandler = () => setPanelState(PanelState.None);
  const [
    { painterToolType, painterTool, fillType },
    {
      clickDragEvent,
      clickSelectEvent,
      clickPainterEvent,
      clickEraseAllEvent,
      clickEraserEvent
    }
  ] = useExtendedContentToolsEvent({ eventBusType, canvasState });

  const onColorChange = color => {
    EventBus.emit({
      eventBusType,
      event: PainterEvent.ChangeBrushColorEvent,
      payload: { color }
    });
  };

  const onLineWidthChange = lineWidth => {
    EventBus.emit({
      eventBusType,
      event: PainterEvent.ChangeBrushWidthEvent,
      payload: { lineWidth }
    });
  };

  const onPainterHandler = useCallback(
    painterType => e => {
      EventBus.emit({
        eventBusType,
        event: PainterEvent.ChangePainterTypeEvent,
        payload: { painterType }
      });
    },
    [eventBusType]
  );

  const onBrushChange = useCallback(
    e => {
      EventBus.emit({
        eventBusType,
        event: PainterEvent.ChangeBrushTypeEvent,
        payload: { brushType: e.target.value }
      });
    },
    [eventBusType]
  );

  const onFillTypeChangeHandler = useCallback(
    fillType => {
      EventBus.emit({
        eventBusType,
        event: PainterEvent.ChangeShapeFillTypeEvent,
        payload: { fillType }
      });
    },
    [eventBusType]
  );

  return (
    <div className={styles.extended_content_tools}>
      {panelState === PanelState.Pen && (
        <PainterPanel.Pen
          className={styles.painterPanel}
          painterTool={painterTool[painterToolType]}
          onPanelClose={panelCloseHandler}
          onLineWidthChange={onLineWidthChange}
          onColorChange={onColorChange}
          onPainterHandler={onPainterHandler}
          onBrushChange={onBrushChange}
        />
      )}

      {panelState === PanelState.Highlighter && (
        <PainterPanel.Pen
          className={styles.painterPanel}
          painterTool={painterTool[painterToolType]}
          onPanelClose={panelCloseHandler}
          onLineWidthChange={onLineWidthChange}
          onColorChange={onColorChange}
          onPainterHandler={onPainterHandler}
          onBrushChange={onBrushChange}
        />
      )}

      {panelState === PanelState.Line && (
        <PainterPanel.Line
          className={styles.painterPanel}
          painterTool={painterTool[painterToolType]}
          onPanelClose={panelCloseHandler}
          onLineWidthChange={onLineWidthChange}
          onColorChange={onColorChange}
          onLineTypeChange={onPainterHandler}
        />
      )}

      {panelState === PanelState.Shape && (
        <PainterPanel.Shape
          className={styles.painterPanel}
          painterTool={painterTool[painterToolType]}
          onPanelClose={panelCloseHandler}
          onLineWidthChange={onLineWidthChange}
          onColorChange={onColorChange}
          onShapeChange={onPainterHandler}
          onFillTypeChange={onFillTypeChangeHandler}
          shapeFillTypeValue={fillType}
        />
      )}
      <div className={styles.closebox}>
        <Button title="關閉" onClick={closeModal}>
          <Icon name="Close" />
          關閉
        </Button>
      </div>
      <div className={styles.toolbox}>
        <div className={styles.toolBtn}>
          <Button onClick={clickDragEvent} title="滑鼠">
            <Icon name="MouseHand" />
            <div className={styles.text}>滑鼠</div>
          </Button>
        </div>
        <div className={styles.toolBtn}>
          <Button onClick={clickSelectEvent} title="選取">
            <Icon name="Select" />
            <div className={styles.text}>選取</div>
          </Button>
        </div>
        <div className={styles.toolBtn}>
          <Button
            onClick={() => {
              clickPainterEvent(PainterToolType.Pen);
              setPanelState(PanelState.Pen);
            }}
            title="畫筆"
          >
            <Icon name="Painting" />
            <div className={styles.text}>畫筆</div>
          </Button>
        </div>
        <div className={styles.toolBtn}>
          <Button
            onClick={() => {
              clickPainterEvent(PainterToolType.Highlighter);
              setPanelState(PanelState.Highlighter);
            }}
            title="螢光筆"
          >
            <Icon name="Highlighter" />
            <div className={styles.text}>螢光筆</div>
          </Button>
        </div>
        <div className={styles.toolBtn}>
          <Button
            onClick={() => {
              clickPainterEvent(PainterToolType.Shape);
              setPanelState(PanelState.Shape);
            }}
            title="形狀"
          >
            <Icon name="Shape" />
            <div className={styles.text}>形狀</div>
          </Button>
        </div>
        <div className={styles.toolBtn}>
          <Button
            onClick={() => {
              clickPainterEvent(PainterToolType.Line);
              setPanelState(PanelState.Line);
            }}
            title="線段"
          >
            <Icon name="Line" />
            <div className={styles.text}>線段</div>
          </Button>
        </div>
        <div className={styles.toolBtn}>
          <Button onClick={clickEraseAllEvent} title="全部刪除">
            <Icon name="DeleteAll" />
            <div className={styles.text}>全部刪除</div>
          </Button>
        </div>
        <div className={styles.toolBtn}>
          <Button onClick={clickEraserEvent} title="部分刪除">
            <Icon name="Delete" />
            <div className={styles.text}>部分刪除</div>
          </Button>
        </div>
      </div>
      <div className={styles.closebox}>
        <div className={styles.toolBtn}>
          <Button title="關閉" onClick={closeModal}>
            <Icon name="Close" />
            關閉
          </Button>
        </div>
      </div>
    </div>
  );
};
