import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

const Button = ({ className, children, onClick, title = '', width }) => (
  <button
    title={title}
    type="button"
    style={{ width }}
    className={classnames(styles.button, className)}
    onClick={onClick}
  >
    {children}
  </button>
);
export default Button;
