import React from 'react';
import Icon from 'components/Icon';
import styles from './index.module.scss';
const SeekList = ({ seekList, onChange }) => {
  return (
    <div className={styles.seekList}>
      {seekList.map(item => (
        <div
          key={item.title}
          className={styles.seekListItem}
          onClick={() => onChange(item.time)}
        >
          <Icon type="text" name="angleRight" />
          <div className={styles.time}>{item.time}</div>
          <div className={styles.title}>{item.title}</div>
        </div>
      ))}
    </div>
  );
};

export default SeekList;
