import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import * as types from 'constants/actionTypes';
import { Roles } from 'constants/role';

export const initState = {
    userId: '',
    name: '',
    isLogin: false,
    role: Roles.GUEST,
    token: null
};

const updateUserInfo = (state, action) => {
    const {
        userId = state.userId,
        name = state.name,
        role = state.role,
        isLogin = state.isLogin,
        token = state.token,
    } = action;
    return { ...state, userId, name, role, isLogin, token };
};

const userReducer = (state, action) => {
    switch (action.type) {
        case types.UPDATE_USER_INFO:
            return updateUserInfo(state, action);
        case types.RESET_USER_INFO:
            return { ...initState };
        default:
            return state;
    }
};

const useUserReducer = () => useEnhanceReducer(userReducer, initState);

export default useUserReducer;
