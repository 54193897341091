import React, { useEffect, useRef } from 'react';
import Icon from 'components/Icon';
import classnames from 'classnames';
import styles from './index.module.scss';

const AudioButton = ({ className, src, children, autoPlay = false }) => {
  const audioRef = useRef();

  useEffect(() => {
    audioRef.current = new Audio(src);
    return () => {
      audioRef.current = null;
    };
  }, [src, audioRef]);

  useEffect(() => {
    if (autoPlay) {
      audioRef.current.play();
    }
  }, [autoPlay]);

  const clickHandler = () => {
    audioRef.current.play();
  };

  return (
    <button
      className={classnames(styles.audioButton, className)}
      onClick={clickHandler}
    >
      {children ? children : <Icon type="text" name="headphones" />}
    </button>
  );
};

export default AudioButton;
