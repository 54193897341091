import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const DialogModule = props => {
    const { open,setOpen,content,action } = props;
    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                {content}
            </DialogContent>
            <DialogActions>
                {action}
            </DialogActions>
        </Dialog>
    );
};

export default DialogModule;
