import CloudBookContentRepository from './BookContentRepositories/CloudBookContentRepository';
import FileBookContentRepository from './BookContentRepositories/FileBookContentRepository';
import CloudExtendedResourceRepository from './ExtendedResourceRepositories/CloudExtendedResourceRepository';
import FileExtendedResourceRepository from './ExtendedResourceRepositories/FileExtendedResourceRepository';

export const fileExpressHost = process.env.REACT_APP_EXPRESS_HOST || 'localhost';
export const fileExpressPort = process.env.REACT_APP_EXPRESS_PORT || 54321;

const Repository = {
    BookContentRepository: window.electron ? FileBookContentRepository : CloudBookContentRepository,
    ExtendedResourceRepository: window.electron ? FileExtendedResourceRepository : CloudExtendedResourceRepository,
};

export default Repository;
