import { StaticHtmlModalComponent } from './StaticHtmlModalComponent';
import { IFrameModalComponent } from './IFrameModalComponent';
import { VocabularyModalComponent } from './VocabularyModalComponent';
import { VideoModalComponent } from './VideoModalComponent';
import { WordGridModalComponent } from './WordGridModalComponent';
import { WordExampleModalComponent } from './WordExampleModalComponent';
import { WhiteboardModalComponent } from './WhiteboardComponent';
import { NumberPickerModalComponent } from './NumberPickerModalComponent';
import { SynopsisModalComponent } from './SynopsisComponent';
import { MarkerWithSoundComponent } from './MarkerWithkSoundComponent';

export const ModalComponentType = {
    StaticHtml: 'staticHtml',
    IFrame: 'iframe',
    Vocabulary: 'vocabulary',
    Video: 'video',
    WordGrid: 'wordGrid',
    WordExample: 'wordExample',
    Whiteboard: 'whiteboard',
    NumberPicker: 'numberPicker',
    Synopsis: 'Synopsis',
    MarkerWithSound: 'MarkerWithSound'

};

export const ModalComponentGenerator = {
    generate: (type, payload) => {
        console.log("---------", type, payload)
        let component = null;
        switch (type) {
            case ModalComponentType.StaticHtml:
                component = StaticHtmlModalComponent(payload);
                break;
            case ModalComponentType.IFrame:
                component = IFrameModalComponent(payload);
                break;
            case ModalComponentType.Vocabulary:
                component = VocabularyModalComponent(payload);
                break;
            case ModalComponentType.Video:
                component = VideoModalComponent(payload);
                break;
            case ModalComponentType.WordGrid:
                component = WordGridModalComponent(payload);
                break;
            case ModalComponentType.WordExample:
                component = WordExampleModalComponent(payload);
                break;
            case ModalComponentType.Whiteboard:
                component = WhiteboardModalComponent();
                break;
            case ModalComponentType.NumberPicker:
                component = NumberPickerModalComponent();
                break;
            case ModalComponentType.Synopsis:
                component = SynopsisModalComponent(payload);
                break;
            case ModalComponentType.MarkerWithSound:
                component = MarkerWithSoundComponent(payload);
                break;
            default:
                throw new Error(`ModalComponentType ${type} is not supported`);
        }
        return component;
    }
};
