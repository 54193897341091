import url from 'url';
import { replaceBookPage } from 'util/book';

import { fileExpressHost, fileExpressPort } from 'repositories/Repository';

import { IBookContentRepository } from './IBookContentRepository';

function FileBookContentRepository() { };
FileBookContentRepository.prototype = Object.create(IBookContentRepository);

FileBookContentRepository.prototype.fetchBooks = async () => {
    const res = await fetch(url.format({
        protocol: 'http:',
        hostname: fileExpressHost,
        port: fileExpressPort,
        pathname: 'books',
        slashes: true
    }));
    const books = await res.json();
    return books || [];
};

FileBookContentRepository.prototype.getPurchasedProducts = async () => {
    const res = await fetch(url.format({
        protocol: 'http:',
        hostname: fileExpressHost,
        port: fileExpressPort,
        pathname: 'books',
        slashes: true
    }));
    const books = await res.json();
    return books || [];
};

FileBookContentRepository.prototype.getBookContent = async ({ book, pages = [] }) => {
    const fetchingPages = book.pageInfos.filter(page => pages.length === 0 || pages.includes(page.pageIndex));
    const res = await fetch(url.format({
        protocol: 'http:',
        hostname: fileExpressHost,
        port: fileExpressPort,
        pathname: `page/${book.bookId}`,
        query: { pages: fetchingPages.map(page => page.pageIndex) },
        slashes: true
    }));
    const json = await res.json();
    let results = fetchingPages.map(page => {
        return replaceBookPage({
            page,
            pageUrl: url.format({
                protocol: 'http:',
                hostname: fileExpressHost,
                port: fileExpressPort,
                pathname: `resource/${book.bookId}/`,
                slashes: true,
            }),
            str: json[page.pageIndex]
        });
    });

    return results;
};

FileBookContentRepository.prototype.getInteractiveObjects = async ({ interactiveObjectId, pages }) => {
    let interactiveObjects = {
        interactiveObjectJSON: {},
        interactiveObjectSVG: {}
    };
    const res = await fetch(url.format({
        protocol: 'http:',
        hostname: fileExpressHost,
        port: fileExpressPort,
        pathname: `interactive-object/${interactiveObjectId}`,
        query: { pages },
        slashes: true,
    }));
    const objects = await res.json();
    objects.forEach(({ pageIndex, json, svg }) => {
        interactiveObjects.interactiveObjectJSON[pageIndex] = json.reduce((acc, v) => {
            acc[v.id] = v;
            return acc;
        }, {});
        interactiveObjects.interactiveObjectSVG[pageIndex] = svg;
    });
    return interactiveObjects;
};

FileBookContentRepository.prototype.getThumbnailUrls = ({ bookId, pages }) => {
    return pages.reduce((acc, page) => {
        acc[page] = url.format({
            protocol: 'http:',
            hostname: fileExpressHost,
            port: fileExpressPort,
            pathname: `thumbnail/${bookId}`,
            query: { page },
            slashes: true,
        });
        return acc;
    }, {});
};

FileBookContentRepository.prototype.getCoverUrls = ({ bookId }) => {
    return url.format({
        protocol: 'http:',
        hostname: fileExpressHost,
        port: fileExpressPort,
        pathname: `books/${bookId}/cover`,
        slashes: true,
    });
};

FileBookContentRepository.prototype.getBookCatalog = async ({ bookId }) => {
    try {
        const res = await fetch(url.format({
            protocol: 'http:',
            hostname: fileExpressHost,
            port: fileExpressPort,
            pathname: `books/${bookId}/catalog`,
            slashes: true
        }));
        const result = await res.json();
        return result.data;
    } catch (err) {
        console.error('getBookCatalog error', err);
    }
};

export default new FileBookContentRepository();
