import React, { useEffect, useState } from 'react';
import path from 'path';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { groupPages } from 'util/book';
import { EventBus } from 'events/EventBus';
import { ReaderEvent } from 'events/EventTypes';
import { BookFlipType } from 'constants/flipTypes';
import Repository from 'repositories/Repository';
import Input from 'components/common/Input';
import PropTypes from "prop-types";

const { BookContentRepository } = Repository;
const PageSearcher = ({ vertical, horizontal }) => {
  const [{ books, bookId, catalog }] = useStore(StoreTypes.books);
  const [{ isDoublePageMode }] = useStore(StoreTypes.reader);
  const book = books.find(item => item.bookId === bookId);
  const [data, setData] = useState({});
  const [dataList, setDataList] = useState({});
  const [thumbnailUrls, setThumbnailUrls] = useState([]);
  const [inputText, setInputText] = useState("");

  useEffect(() => {
    if (!book) {
      return;
    }
    const { pageInfos } = book;
    const pages = pageInfos.reduce((acc, item) => {
      acc[item.pageIndex] = path.basename(item.html, path.extname(item.html));
      return acc;
    }, {});
    const imgList = getLabel(groupPages(pages, isDoublePageMode))
    setData(imgList);
    setDataList(imgList)
    const results = BookContentRepository.getThumbnailUrls({ bookId, pages: Object.values(pages) });
    setThumbnailUrls(results);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [book, bookId, isDoublePageMode]);

  const getLabel = (data) => {
    return Object.values(data).map((pages, index) => {
      const labels = []
      for (let page of pages) {
        const label = (catalog.getLabel(page - 1)).toString()
        labels.push({ label, page, index })
      }
      return labels;
    })
  }

  const setImg = (pages) => {
    let pagesElement = []
    if (pages.length < 2) {
      pagesElement.push(<div key={pages[0].page}>
        <img key={pages[0].page} src={thumbnailUrls[pages[0].page]} alt='' />
      </div>)
    } else {
      for (let page of pages) {
        const pageIndex = parseInt(page.page)
        pagesElement.push(<div key={page.page}>
          <img key={pageIndex} src={thumbnailUrls[pageIndex]} alt='' />
        </div>)
      }
    }
    return pagesElement
  }

  const searchPage = (value) => {
    setInputText(value)
    setDataList(Object.values(data).filter((pages) => {
      if (pages.some((obj) => obj.label.indexOf(value) > -1)) {
        return pages
      }
    }))
  }

  return (
    <div className={classnames(styles.pageSearcher)}>
      <Input className={styles.input} placeholder="輸入頁數" value={inputText} onChange={(e) => {
        searchPage(e.target.value)
      }} />
      <div className={classnames(styles.thumbnailWrapper, {
        [styles.vertical]: vertical,
        [styles.horizontal]: !vertical || horizontal,
        [styles.rightToLeft]: book.LRFlip === BookFlipType.RIGHT_TO_LEFT,
        [styles.doublePage]: isDoublePageMode
      })}>
        {
          Object.values(dataList).map((pages, index) => (
            <div key={index} className={classnames(styles.thumbnailGroup)}>
              {
                pages.length > 1 && pages[0].label ?
                  <>
                    {
                      pages[0].label === pages[1].label ? <div className={styles.title}>{pages[0].label}</div> :
                        <div className={styles.title}>
                          <span>{pages[0].label}</span>
                          <span>{pages[1].label}</span>
                        </div>
                    }
                  </> : <div className={styles.title}>{pages[0].label}</div>
              }
              <div key={index}
                className={styles.thumbnail}
                onClick={() => EventBus.emit({ event: ReaderEvent.ClickBookThumbnailEvent, payload: { pageIndex: pages[0].index } })}
              >
                {
                  setImg(pages)
                }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}
PageSearcher.propTypes = {
  classes: PropTypes.object.isRequired
};

export default PageSearcher;
