import React, { useEffect, useState } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import styles from './index.module.scss';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFetchBooks } from 'customHooks/book';
import { ReaderEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';
import { Roles } from 'constants/role';
import DialogModule from 'components/Dialogs/'
import { AnnotationActivityTab } from 'components/AnnotationActivityTab/';
import { AnnotationType } from 'constants/annotationTypes';
import Repository from 'repositories/Repository';

import Snackbar from '@material-ui/core/Snackbar';
import Header from 'components/Header';
import Footer from 'components/Footer';

const TabAnnotationType = [
    AnnotationType.CLASS_PREPARATION,
    AnnotationType.ACTIVITY
];

export const Bookshelf = () => {
    const [, readerDispatch] = useStore(StoreTypes.reader);
    const [{ books }, bookDispatch] = useStore(StoreTypes.books);
    const [{ role, token }] = useStore(StoreTypes.user);
    const [{ annotationId }, annotationDispatch] = useStore(StoreTypes.annotation);
    const [selectBookID, setSelectBookID] = useState("");
    const { getPurchasedProducts } = useFetchBooks();
    const [inputDialogOpen, setInputDialogOpen] = useState(false);
    const [inputText, setInputText] = useState("");
    const [tab, setTab] = React.useState(0);
    const [isAnnotationTabOpen, setAnnotationTabOpen] = useState(false);
    const router = useStore(StoreTypes.router);
    const { BookContentRepository } = Repository;

    useEffect(() => {
        readerDispatch({ type: types.SET_BOOK_PAGE_INDEX, pageIndex: 0 });
        annotationDispatch({ type: types.RESET_ANNOTATION_INFO });
    }, [annotationDispatch, readerDispatch]);

    useEffect(() => {
        (async () => {
            const books = await getPurchasedProducts({ token });
            if (books.length === 0) {
                // TODO: Request User Login
            }
        })();
    }, [annotationDispatch, getPurchasedProducts, token]);

    const goBook = (bookId, interactiveObjectId) => {
        router.history.push({ pathname: `/${bookId}`, search: interactiveObjectId ? `?interactiveObjectId=${interactiveObjectId}` : '' });
    };

    const openBook = ({ bookId, interactiveObjectId }) => {
        setSelectBookID(bookId);
        bookDispatch({ type: types.SET_BOOK_ID, payload: bookId });
        if (role === Roles.EDITOR) {
            annotationDispatch({
                type: types.UPDATE_ANNOTATION_INFO,
                annotationId: interactiveObjectId,
                annotationType: AnnotationType.INTERACTIVE_OBJECT
            });
            goBook(bookId, interactiveObjectId);
            // } else if (role === Roles.GUEST) {
            //     EventBus.emit({
            //         event: ReaderEvent.CreateAndEnterAnnotationEvent,
            //         payload: {
            //             bookId,
            //             annotationType: AnnotationType.GUEST,
            //             name: "未命名",
            //             callback: (err) => {
            //                 if (!err) {
            //                     goBook(bookId);
            //                 }
            //             }
            //         }
            //     });
        } else {
            setAnnotationTabOpen(true);
        }
    };

    const confirmHandler = (annotationId) => () => {
        EventBus.emit({
            event: ReaderEvent.CreateAndEnterAnnotationEvent,
            payload: {
                bookId: selectBookID,
                annotationType: role === Roles.TEACHER ? TabAnnotationType[tab] : AnnotationType.GUEST,
                annotationId,
                name: (inputText === "") ? "未命名" : inputText,
                callback: (err) => {
                    if (err) {
                        setState(prev => {
                            return {
                                ...prev,
                                open: true
                            }
                        });
                    } else {
                        goBook(selectBookID);
                    }
                }
            }
        });
    };

    //備課與活動選單
    const TabDialogContent = <AnnotationActivityTab id="alert-dialog-description" tab={tab} setTab={setTab} TabAnnotationType={role === Roles.TEACHER ? TabAnnotationType : [AnnotationType.GUEST]} setInputText={setInputText} />;
    const TabDialogAction = <div>
        <Button onClick={() => {
            setInputDialogOpen(true)
        }} color="primary"
            style={{
                border: "solid",
                color: "blue",
                marginRight: 5,
                borderWidth: 2
            }}
        >
            新增
        </Button>
        <Button
            style={{
                border: "solid",
                color: "blue",
                borderWidth: 2
            }}
            onClick={confirmHandler(annotationId)} color="primary" autoFocus
        >
            確定
        </Button>
    </div>;

    //輸入名稱彈窗
    const InputDialogContent = <TextField
        id="outlined-name"
        label="輸入名稱"
        value={inputText}
        onChange={(e) => {
            setInputText(e.target.value)
        }}
        margin="normal"
        variant="outlined"
    />
    const InputDialogAction = (
        <Button
            className={styles.dialogButton}
            onClick={confirmHandler(null)}
            color="primary"
            autoFocus
            style={{
                border: "solid",
                color: "blue",
                borderWidth: 2
            }}
        >
            >
                確定
        </Button>
    )

    const [state, setState] = React.useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
    });

    const { vertical, horizontal, open } = state;

    function handleClose() {
        setState({ ...state, open: false });
    }

    return (
        <>
            <Header />
            <div className={styles.index}>
                <div className={styles.container}>
                    <ul className={styles['list-container']}>
                        {books.length > 0 ? books.map(book =>
                            (<li className={styles['list-item']} key={book.id} onClick={() => openBook(book)}>
                                {/* <img className={styles['list-img']} src={book.thumbnail} alt=""/> */}
                                <div className={styles['list-img']} style={{ backgroundImage: `url(${BookContentRepository.getCoverUrls({ bookId: book.bookId })}),url(/assets/img/cover.jpg)` }}></div>
                                <div className={styles['list-title']}>{book.display_name}</div>
                            </li>)
                        ) :
                            (
                                <div className={styles.default}>
                                    <div className={styles.title}>目前尚未有任何書本</div>
                                    <div className={styles.sub_title}>請前往書城訂購您要的書本</div>
                                    <div className={styles.go_box}>
                                        <a href="https://mall.oneclass.com.tw/" target="_blank" rel="noopener noreferrer" className={styles.go_onemall}>前往書城</a>
                                    </div>
                                </div>
                            )
                        }
                    </ul>
                    {
                        (role === Roles.TEACHER || role === Roles.GUEST) && (<div>
                            <DialogModule
                                open={isAnnotationTabOpen}
                                setOpen={setAnnotationTabOpen}
                                content={TabDialogContent}
                                action={TabDialogAction}
                            />
                            <DialogModule
                                open={inputDialogOpen}
                                setOpen={setInputDialogOpen}
                                content={InputDialogContent}
                                action={InputDialogAction}
                            />
                        </div>)
                    }

                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        key={`${vertical},${horizontal}`}
                        open={open}
                        onClose={handleClose}
                    >
                        <Button variant="contained" style={{ backgroundColor: "#f50057", color: "#ccc", width: 200, height: 70, fontSize: 32 }} >
                            請登入
                    </Button>
                    </Snackbar>
                </div>
            </div>
            <Footer />
        </>
    );
};
