import React from 'react';
import styles from './index.module.scss';

const reg = /i|ɪ|e|ɛ|æ|ɑ|o|ɔ|u|ᴜ|ʌ|ə|ɚ|ɝ|aɪ|aᴜ|ɔɪ/g;

const VocabularyInfo = ({ kkContent, partOfSpeech }) => (
  <div className={styles.vocabularyInfo}>
    <div className={styles.kk} 
      dangerouslySetInnerHTML={{__html:kkContent.replace(reg, word =>`<span>${word}</span>`)}}
    />
    {
      partOfSpeech && 
      <div className={styles.partOfSpeech}>
        {partOfSpeech}
      </div>
    }
  </div>
);

export default VocabularyInfo;