export const LayoutType = {
    PlainText: 'PlainText',
    Topic: 'Topic',
    TopicColorRed: 'TopicColorRed',
    TopicColorGreen: 'TopicColorGreen',
    WordSection: 'WordSection',
    Fill: 'Fill'
};

export const Layout = {
    [LayoutType.PlainText]: 'layout/plainText/plainText.css',
    [LayoutType.Topic]: 'layout/topic/topic.css',
    [LayoutType.TopicColorRed]: 'layout/topic/topicColorRed.css',
    [LayoutType.TopicColorGreen]: 'layout/topic/topicColorGreen.css',
    [LayoutType.WordSection]: 'layout/wordSection/wordSection.css',
    [LayoutType.Fill]: 'layout/fill/fill.css'
};

export const Script = {
    [LayoutType.Topic]: 'script/topic.js',
    [LayoutType.TopicColorRed]: 'script/topic.js',
    [LayoutType.TopicColorGreen]: 'script/topic.js',
    [LayoutType.WordSection]: 'script/wordSection.js',
    [LayoutType.Fill]: 'script/fill.js',
};

export const LayoutDisplayName = {
    [LayoutType.PlainText]: '純文字型',
    [LayoutType.Topic]: '題目型',
    [LayoutType.TopicColorRed]: '題目型 - 紅色',
    [LayoutType.TopicColorGreen]: '題目型 - 綠色',
    [LayoutType.WordSection]: '直書型',
    [LayoutType.Fill]: '填充型',
};
