import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import { Roles } from 'constants/role';
import { AnnotationType } from 'constants/annotationTypes';
import { useActiveTeacherStrategy } from './ActiveTeacherStrategy';
import { useInactiveTeacherStrategy } from './InactiveTeacherStrategy';
import { useActiveStudentStrategy } from './ActiveStudentStrategy';
import { useInactiveStudentStrategy } from './InactiveStudentStrategy';
import { usePrepareClassStrategy } from './PrepareClassStrategy';
import { useEditInteractiveObjectStrategy } from './EditInteractiveObjectStrategy';
import { useGuestStrategy } from './GuestStrategy';

export const useReaderStrategyDecider = () => {
    const [{ role }] = useStore(StoreTypes.user);
    const [{ annotationType, activityInfo: { activityTarget, attendeeNumber } }] = useStore(StoreTypes.annotation);
    const activeTeacherStrategy = useActiveTeacherStrategy();
    const inactiveTeacherStrategy = useInactiveTeacherStrategy();
    const activeStudentStrategy = useActiveStudentStrategy();
    const inactiveStudentStrategy = useInactiveStudentStrategy();
    const prepareClassStrategy = usePrepareClassStrategy();
    const editInteractiveObjectStrategy = useEditInteractiveObjectStrategy();
    const guestStrategy = useGuestStrategy();

    const getReaderStrategy = useCallback(() => {
        if (annotationType === AnnotationType.ACTIVITY && activityTarget) {
            if (role === Roles.TEACHER) {
                if (activityTarget === attendeeNumber) {
                    return activeTeacherStrategy;
                } else {
                    return inactiveTeacherStrategy;
                }
            } else if (role === Roles.STUDENT) {
                if (activityTarget === attendeeNumber) {
                    return activeStudentStrategy;
                } else {
                    return inactiveStudentStrategy;
                }
            }
        } else if (annotationType === AnnotationType.CLASS_PREPARATION) {
            if (role === Roles.TEACHER) {
                return prepareClassStrategy;
            }
        } else if (annotationType === AnnotationType.INTERACTIVE_OBJECT) {
            return editInteractiveObjectStrategy;
        }else if (annotationType === AnnotationType.GUEST) {
            return guestStrategy
        }
    }, [
            activeStudentStrategy,
            activeTeacherStrategy,
            activityTarget,
            annotationType,
            attendeeNumber,
            editInteractiveObjectStrategy,
            inactiveStudentStrategy,
            inactiveTeacherStrategy,
            guestStrategy,
            prepareClassStrategy,
            role
        ]
    );

    return { getReaderStrategy };
};
