import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import * as types from 'constants/actionTypes';

const isMobile = () => {

  try { document.createEvent('TouchEvent'); return true; }

  catch (e) { return false; }

}

export const initState = {
  windowHeight: window.innerHeight,
  windowWidth: window.innerWidth,
  isMobile: isMobile(),
  modal: {
    isShowing: false,
    components: null
  },
  musicModal: {
    isShowing: false,
    src: null,
    fixed: null,
    x: null,
    y: null,
    subtitles: []
  }
};

const globalReducer = (state, action) => {
  switch (action.type) {
    case types.WINDOW_RESIZE:
      const { windowHeight, windowWidth } = action.payload;
      return { ...state, windowHeight, windowWidth }
    case types.OPEN_MODAL:
      return { ...state, modal: { ...state.modal, isShowing: true } }
    case types.CLOSE_MODAL:
      return { ...state, modal: { ...state.modal, isShowing: false } }
    case types.SET_MODAL_COMPONENTS:
      return { ...state, modal: { ...state.modal, components: action.payload.components } }
    case types.OPEN_MUSIC_MODAL:
      return { ...state, musicModal: { ...state.musicModal, isShowing: true, ...action.payload } }
    case types.CLOSE_MUSIC_MODAL:
      return { ...state, musicModal: { ...state.musicModal, isShowing: false } }
    default:
      return state;
  }
};

const useGlobalReducer = () => useEnhanceReducer(globalReducer, initState);

export default useGlobalReducer;
