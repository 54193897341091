import React, { useState } from 'react';
import ControlButton from 'components/common/Player/ControlButton';
import Icon from 'components/Icon';
import classnames from 'classnames';
import styles from './index.module.scss';

const VolumeBar = ({ state, controls, onChange }) => {
  const [isShowVolumeBar, setVolumeBarState] = useState(false);

  const changeHandler = e => {
    onChange(Number(e.target.value));
  };

  return (
    <div className={styles.volumeBarWrapper}>
      <ControlButton
        onClick={
          (state.volume > 0 && !state.muted) || !state.muted
            ? controls.mute
            : controls.unmute
        }
        onMouseEnter={() => setVolumeBarState(true)}
        onMouseLeave={() => setVolumeBarState(false)}
      >
        {(state.volume === 0 || state.muted) && (
          <Icon type="text" name="volumeMute" />
        )}
        {state.volume > 0 && state.volume < 0.7 && !state.muted && (
          <Icon type="text" name="volumeDown" />
        )}
        {state.volume >= 0.7 && !state.muted && (
          <Icon type="text" name="volumeUp" />
        )}
      </ControlButton>

      <div
        className={classnames(styles.volumeBar, {
          [styles.active]: isShowVolumeBar
        })}
        onMouseEnter={() => setVolumeBarState(true)}
        onMouseLeave={() => setVolumeBarState(false)}
      >
        <div
          className={styles.volumeUsage}
          style={{ width: `${(state.volume / 1) * 100}%` }}
        />
        <input
          type="range"
          onChange={changeHandler}
          className={styles.range}
          min="0"
          max="1"
          step="0.02"
          defaultValue="0"
        />
      </div>
    </div>
  );
};

export default VolumeBar;
