import React, { useState } from 'react';
import styles from './index.module.scss';

import { EventBus } from 'events/EventBus';
import { UserEvent } from 'events/EventTypes';
import { useStore, StoreTypes } from 'context';
import classnames from 'classnames';

const Header = () => {
  const [{ isLogin, name }] = useStore(StoreTypes.user);
  const [loginBtn, setLoginBtn] = useState(false);
  const menus = [
    {
      title: '書城',
      url: "https://mall.oneclass.com.tw/"
    }
  ];

  return (
    <div>
      <header>
        <div className={styles.top}>
          <div className={styles.container}>
            <div className={styles.logoBox}>
              <img className="logo" src="img/logo.png" alt="onebook" />
            </div>
            <div className={styles.funBox}>
              {/* <div className={styles.search_box}>
                                <input className={styles.search} type="text" placeholder="Search…" />
                            </div> */}
              <div className={styles.iconBox}>
                {/* <div className={styles.book}></div> */}
                {/* <div className={styles.member}></div> */}
                {/* {<div className={styles.login} onClick={() => {
                                    EventBus.emit({ event: UserEvent.LoginEvent });
                                }}>{isLogin ? "登出" : "登入"}</div>} */}

                {
                  !window.electron && <div className={styles.loginBox}>
                    <div className={styles.dropdownBox}>
                      <div className={styles.login} onClick={e => {
                        if (isLogin) {
                          setLoginBtn(!loginBtn)
                        } else {
                          EventBus.emit({ event: UserEvent.LoginEvent });
                        }

                      }}> {isLogin ? `${name}▼` : "登入"}</div>
                      <div className={classnames(styles.dropdownMenu, styles[loginBtn ? 'active' : ''])} >
                        <div className={styles.dropdownItem} onClick={() => {
                          window.updatePersonalDataNaniOneClass();
                        }}>修改資料</div>
                        <div className={styles.dropdownItem} onClick={() => {
                          setLoginBtn(false)
                          EventBus.emit({ event: UserEvent.LoginEvent });
                        }}>登出</div>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className={styles['version-info']}>Version: {process.env.REACT_APP_VERSION || '0.0.1'}</div>
            </div>
          </div>
        </div>

        {
          !window.electron && <div className={styles.bottom}>
            <div className={styles.container}>
              <ul className={styles.list_box}>
                {menus.map(list => {
                  return (
                    <li className={styles.list}><a href={list.url} target="_blank" rel="noopener noreferrer">{list.title}</a></li>
                  );
                })}
              </ul>
              {/* <div className={styles.sopping_car}>
                            <div className={styles.car}></div>
                        </div> */}
            </div>
          </div>
        }
      </header >
      <div className={styles.mat}></div>
    </div>
  );
}
export default Header;
