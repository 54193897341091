import React from 'react';
import useMouse from 'customHooks/mouse';
import { timeFormatTommss } from 'util/timeFormater';
import classnames from 'classnames';
import styles from './index.module.scss';

const ProcessBar = ({className,currentTime,endTime,onChange}) => {

  const processBarRef = React.useRef(null); 
  const {elX, elW} = useMouse(processBarRef);


  const changeHandler = e=>{
    const {value} = e.target;
    onChange(endTime * value/100)
  }

  return (
    <div ref={processBarRef} className={classnames(styles.processBar,className)}>
      <div className={styles.processUsage} style={{width:`${currentTime/endTime * 100}%`}}></div>
      <input type="range" onChange={changeHandler} className={styles.range} min="0" max="100" step="1" defaultValue="0" />
      <div className={styles.timeTooptips} style={{left:`${elX}px`}}>
        { timeFormatTommss(endTime * elX / elW) }
      </div>
    </div>
  )
}


export default ProcessBar;