import { useCallback, useEffect } from 'react';
import path from 'path';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { SideToolContent } from 'constants/sideToolContents';
import { ReaderEvent, ReaderToolsEvent, PainterEvent, ActivityEvent, InteractiveObjectEvent } from 'events/EventTypes';
import { useUpdateFullWidthInfo, useUpdateReaderScaleInfo, useUpdateAreaZoomInfo, useUpdateAreaZoomForPageButton } from 'customHooks/reader';
import { useConvertJSONToSVG, useSaveCanvasJSON, useFlushAnnotations, useFlipBook, useExportInteractiveObjects } from 'customHooks/canvas';
import { SideBarType, ReaderZoomType } from 'constants/ReaderTools';
import { useEvent } from 'events/EventBus';
import { EventBus } from 'events/EventBus';
import { useInteractiveObjectContentCommandFactory } from 'customHooks/InteractiveObjectContentCommands/commandFactory';
import { AnnotationType } from 'constants/annotationTypes';
import { useReadAnnotations, useUpdateAnnotation, useDeleteAnnotation, useCreateAnnotation, useCreateReaderToolSettings, useReadReaderToolSettings } from 'customHooks/db';
import uuid from 'util/uuid';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export const useReaderEventHandler = () => {
  const reducers = useStore();
  const [{ pageIndex, isDoublePageMode, fullWidthInfo: { mode: fullWidthMode } }, readerDispatch] = reducers[StoreTypes.reader];
  const [, canvasDispatch] = reducers[StoreTypes.canvas];
  const [{ books, bookId, catalog, interactiveObjectJSON }, bookDispatch] = reducers[StoreTypes.books];
  const [{ annotationId, marks }, annotationDispatch] = reducers[StoreTypes.annotation];
  const [{ sideToolContent }, sideToolDispatch] = reducers[StoreTypes.sideTool];
  const router = reducers[StoreTypes.router];
  const convertJSONToSVG = useConvertJSONToSVG();
  const saveCanvasJSON = useSaveCanvasJSON();
  const flipBook = useFlipBook();
  const flushAnnotations = useFlushAnnotations();
  const CommandFactory = useInteractiveObjectContentCommandFactory();
  const exportInteractiveObjects = useExportInteractiveObjects();
  const book = books.find(book => book.bookId === bookId);
  const { readAnnotationById, readAnnotations } = useReadAnnotations();
  const createReaderToolSettings = useCreateReaderToolSettings();
  const readReaderToolSettings = useReadReaderToolSettings();
  const updateAnnotation = useUpdateAnnotation();
  const createAnnotation = useCreateAnnotation();
  const deleteAnnotation = useDeleteAnnotation();
  const updateFullWidthInfo = useUpdateFullWidthInfo();
  const updateReaderScaleInfo = useUpdateReaderScaleInfo();
  const updateAreaZoomInfo = useUpdateAreaZoomInfo();
  const updateAreaZoomForPageButton = useUpdateAreaZoomForPageButton();

  const setPageIndexHandler = useCallback(async ({ pageIndex, convertToSVG }) => {
    flipBook({ convertToSVG, keepCanvas: true, pageIndex });
    // reset scaling
    readerDispatch({ type: types.SET_SCALE_INFO, offsetX: 0, offsetY: 0, scale: 1 });
    EventBus.emit({
      event: ActivityEvent.SendActivityInfoToFirebaseEvent,
      payload: {
        pageIndex: pageIndex,
      }
    });

    await updateAnnotation(annotationId, { pageIndex: isDoublePageMode ? pageIndex * 2 : pageIndex });
  }, [annotationId, flipBook, isDoublePageMode, readerDispatch, updateAnnotation]);

  const previousPageEventHandler = useCallback(async ({ convertToSVG }) => {
    setPageIndexHandler({ pageIndex: Math.max(pageIndex - 1, 0), convertToSVG })
  }, [pageIndex, setPageIndexHandler])

  const nextPageEventHandler = useCallback(async ({ pageInfos, convertToSVG }) => {
    const max = isDoublePageMode
      ? Math.floor((pageInfos.length - 1) / 2)
      : pageInfos.length - 1;
    setPageIndexHandler({ pageIndex: Math.min(pageIndex + 1, max), convertToSVG })
  }, [isDoublePageMode, pageIndex, setPageIndexHandler])

  const togglePageModeHandler = useCallback(async ({ isDoublePageMode: newPageMode }) => {
    if (newPageMode !== isDoublePageMode) {
      const { pageInfos } = book;
      const newPageIndex = newPageMode ?
        Math.max(Math.floor(pageIndex / 2), 0) :
        Math.min(pageIndex * 2, pageInfos.length);

      readerDispatch({
        type: types.SET_BOOK_PAGE_INDEX,
        pageIndex: newPageIndex
      });
      readerDispatch({
        type: types.SWITCH_BOOK_PAGES_SHOW,
        isDoublePageMode: newPageMode
      });

      await flushAnnotations({ currentPageIndex: newPageIndex, pageMode: newPageMode });

      canvasDispatch({ type: types.CANVAS_INACTIVATE });
    }
  }, [isDoublePageMode, book, pageIndex, readerDispatch, flushAnnotations, canvasDispatch]);

  const reimportSVG = useCallback(async () => {
    const canvasSVG = await convertJSONToSVG({
      keepCanvas: true,
      toSVG: true,
    });
    canvasDispatch({ type: types.CANVAS_IMPORT_SVG, canvasSVG });
    canvasDispatch({ type: types.CANVAS_INACTIVATE });
  }, [canvasDispatch, convertJSONToSVG]);

  const clickDragHandler = useCallback(async () => {
    await reimportSVG();

    readerDispatch({ type: types.SET_MARK_MODE, isMarkModeShow: false })
    readerDispatch({ type: types.SET_MARK_TOOLS_SHOW, isMarkToolsShow: false })
  }, [reimportSVG, readerDispatch]);

  const changeStampStatusHandler = useCallback(({ painterMode, stampType }) => {
    canvasDispatch({ type: types.CANVAS_CHANGE_STAMP_STATUS, painterMode, stampType });
  }, [canvasDispatch]);

  const toggleSideBarHandler = useCallback(({ sideBarType }) => {
    readerDispatch({
      type: sideBarType === SideBarType.LEFT ? types.TOGGLE_LEFT_BAR : types.TOGGLE_RIGHT_BAR
    });
  }, [readerDispatch]);


  const togglePageSearcherHandler = useCallback(() => {
    readerDispatch({
      type: types.TOGGLE_PAGE_SEARCHER
    });
  }, [readerDispatch])


  const clickReaderOptionPanelHandler = useCallback(() => {
    canvasDispatch({ type: types.CANVAS_INACTIVATE });
    sideToolDispatch({
      type: types.SET_SIDE_TOOL_CONTENT,
      sideToolContent: (sideToolContent !== SideToolContent.ReaderOptionPanel) ? SideToolContent.ReaderOptionPanel : SideToolContent.None,
    });
  }, [canvasDispatch, sideToolContent, sideToolDispatch]);

  const clickStampPanelHandler = useCallback(async () => {
    canvasDispatch({ type: types.CANVAS_INACTIVATE });
    await reimportSVG();
    sideToolDispatch({
      type: types.SET_SIDE_TOOL_CONTENT,
      sideToolContent: (sideToolContent !== SideToolContent.StampPanel) ? SideToolContent.StampPanel : SideToolContent.None,
    });
  }, [canvasDispatch, reimportSVG, sideToolContent, sideToolDispatch]);

  const setObjectPropertyHandler = useCallback(({ objectProperty }) => {
    sideToolDispatch({
      type: types.SET_CANVAS_OBJECT_PROPERTY,
      objectProperty,
    });
  }, [sideToolDispatch]);

  const toggleBookmarkHandler = useCallback(() => {
    readerDispatch({
      type: types.TOGGLE_BOOKMARK
    });
  }, [readerDispatch]);

  const LoadReaderToolSettingsHandler = useCallback(async () => {
    const response = await readReaderToolSettings(bookId)
    if (response) {
      response.painterTool && canvasDispatch({ type: types.SET_CANVAS_PAINTER_TOOL, painterTool: response.painterTool });
      response.fullWidthMode !== undefined && readerDispatch({ type: types.SET_FULL_WIDTH_INFO, fullWidthInfo: { mode: response.fullWidthMode } });
    }
  }, [bookId, canvasDispatch, readReaderToolSettings, readerDispatch])

  const changePainterModeHandler = useCallback(({ painterMode, painterToolType }) => {
    canvasDispatch({
      type: types.CANVAS_CHANGE_PAINTER_MODE,
      painterMode,
      painterToolType
    });
  }, [canvasDispatch]);

  const changeBrushColorHandler = useCallback(({ color }) => {
    canvasDispatch({ type: types.CANVAS_CHANGE_COLOR, changeColorRgb: color, changeColorHex: color });
  }, [canvasDispatch]);

  const changeBrushWidthHandler = useCallback(({ lineWidth }) => {
    canvasDispatch({ type: types.CANVAS_DRAWING_BRUSH_LINE_WIDTH, changeLineWidth: lineWidth });
  }, [canvasDispatch]);

  const changeBrushTypeHandler = useCallback(({ brushType }) => {
    canvasDispatch({ type: types.CHANGE_DRAWING_BRUSH, changeDrawingBrush: brushType });
  }, [canvasDispatch]);

  const changeShapeFillTypeHandler = useCallback(({ fillType }) => {
    canvasDispatch({ type: types.CANVAS_CHANGE_SHAPE_FILL_TYPE, fillType });
  }, [canvasDispatch])

  const changePainterTypeHandler = useCallback(({ painterType }) => {
    canvasDispatch({ type: types.CANVAS_CHANGE_PAINTER_TYPE, painterType });
  }, [canvasDispatch]);

  const changeReaderToolStyleHandler = useCallback(({ color }) => {
    readerDispatch({ type: types.CHANGE_READER_TOOL_STYLE, color });
  }, [readerDispatch]);

  const changeReaderToolDirectionHandler = useCallback((direction) => {
    readerDispatch({ type: types.CHANGE_READER_TOOL_DIRECTION, direction });
  }, [readerDispatch])

  const RefreshCanvasHandler = useCallback(async ({ result, size }) => {
    canvasDispatch({
      type: types.CANVAS_RESTORE_FROM_DB,
      annotations: result.annotations
    });
    await flushAnnotations({
      annotations: result.annotations.reduce((acc, v) => {
        acc[v.pageIndex] = v.annotation
        return acc;
      }, {}),
      currentPageIndex: result.pageIndex,
      size
    });
  }, [canvasDispatch, flushAnnotations]);

  const clickInteractiveObjectHandler = useCallback(async ({ id, pageIndex, interactiveObjectState, setInteractiveObjectState, target }) => {
    const obj = interactiveObjectJSON[pageIndex];
    if (obj && obj[id]) {
      const command = CommandFactory.createCommand(obj[id]);
      command && command.execute({ json: obj[id], pageIndex, isDoublePageMode, interactiveObjectState, setInteractiveObjectState, target });
    }
  }, [CommandFactory, interactiveObjectJSON, isDoublePageMode]);

  const exportInteractiveObjectEventHandler = useCallback(async () => {
    readerDispatch({ type: types.SET_INDICATOR_INFO, indicatorInfo: { isActive: true } });
    const pageIndices = [];
    if (isDoublePageMode) {
      pageIndices.push.apply(pageIndices, [pageIndex * 2, pageIndex * 2 + 1])
    } else {
      pageIndices.push.apply(pageIndices, [pageIndex])
    }
    await exportInteractiveObjects(pageIndices);
    readerDispatch({ type: types.SET_INDICATOR_INFO, indicatorInfo: { isActive: false } });
  }, [exportInteractiveObjects, isDoublePageMode, pageIndex, readerDispatch]);

  const createAndEnterAnnotationEventHandler = useCallback(async ({ annotationId, bookId, annotationType, annotations, name, pageIndex = 0, callback }) => {
    let createNewAnnotation = false;
    if (!annotationId) {
      createNewAnnotation = true;
      annotationId = uuid();
    }
    // if (annotationType === AnnotationType.GUEST) {
    //     const annotations = await readAnnotations({ bookId, annotationType });
    //     if (annotations.length > 0) {
    //         annotationId = annotations[0].id;
    //         createNewAnnotation = false;
    //     }
    // }
    if (createNewAnnotation) {
      await createAnnotation({
        id: annotationId,
        bookId,
        name,
        type: annotationType,
        pageIndex,
        annotations: annotations || []
      });
    }

    if (annotationType === AnnotationType.ACTIVITY) {
      return EventBus.emit({
        event: createNewAnnotation ? ActivityEvent.CreatActivityEvent : ActivityEvent.EnterActivityEvent,
        payload: {
          activityId: annotationId,
          annotationType,
          bookId,
          annotations,
          name,
          callback
        }
      });
    }
    // enter
    annotationDispatch({
      type: types.UPDATE_ANNOTATION_INFO,
      annotationId,
      annotationType,
      name
    });
    callback && callback();
  }, [annotationDispatch, createAnnotation]);

  const clickBookmarkEventHandler = useCallback(({ pageIndex, bookId: targetBookId }) => {
    if (bookId === targetBookId) {
      readerDispatch({
        type: types.SET_BOOK_PAGE_INDEX,
        pageIndex: isDoublePageMode ? Math.floor(pageIndex / 2) : pageIndex
      });
    } else {
      router.history.replace('/');
      setTimeout(() => {
        bookDispatch({ type: types.SET_BOOK_ID, payload: targetBookId });
        annotationDispatch({ type: types.UPDATE_ANNOTATION_INFO, annotationType: AnnotationType.GUEST });
        router.history.replace({ pathname: `/${targetBookId}` });
      }, 500);
    }
  }, [annotationDispatch, bookDispatch, bookId, isDoublePageMode, readerDispatch, router.history]);

  const gobackCatalogEventHandler = () => {
    let index = catalog.getCatalogIndex(isDoublePageMode, pageIndex);
    readerDispatch({
      type: types.SET_BOOK_PAGE_INDEX,
      pageIndex: isDoublePageMode ? (Math.floor(index / 2)) : index
    });
  }

  const clickOpenActivityEventHandler = useCallback(async ({ annotationId }) => {
    readerDispatch({ type: types.SET_INDICATOR_INFO, indicatorInfo: { isActive: true } });
    let result = await readAnnotationById({ id: annotationId });
    createAndEnterAnnotationEventHandler({
      ...result,
      annotationId: null,
      annotationType: AnnotationType.ACTIVITY,
      callback: (err) => readerDispatch({ type: types.SET_INDICATOR_INFO, indicatorInfo: { isActive: false } })
    });
  }, [createAndEnterAnnotationEventHandler, readAnnotationById, readerDispatch]);

  const OnMarkModeEventHandler = useCallback(async () => {
    readerDispatch({
      type: types.SET_MARK_MODE,
      isMarkModeShow: true
    })
    readerDispatch({
      type: types.SET_MARK_TOOLS_SHOW,
      isMarkToolsShow: false
    })
    await reimportSVG();
  }, [readerDispatch, reimportSVG])

  const SelectMarkEventHandler = useCallback(({ markObject }) => {
    SetMarkToolsEnableEventHandler({ enable: true })
    annotationDispatch({ type: types.SET_MARK_OBJECT, markObject })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotationDispatch])

  const SetMarkToolsEnableEventHandler = useCallback(({ enable }) => {
    readerDispatch({ type: types.SET_MARK_TOOLS_SHOW, isMarkToolsShow: enable })
  }, [readerDispatch])

  const SaveMarkEventHandler = useCallback(async ({ markObject, markId, pageIndex }) => {
    annotationDispatch({ type: types.ADD_MARK_OBJECTS, markObject: { text: markObject.text, id: markId, pageIndex } })
    await updateAnnotation(annotationId, { marks: [...marks, { text: markObject.text, id: markId, pageIndex }] });
  }, [annotationDispatch, annotationId, marks, updateAnnotation])

  const SaveReaderToolSettingsEventHandler = useCallback(async (payload) => {
    await createReaderToolSettings(bookId, payload);
  }, [bookId, createReaderToolSettings])

  const clickEraseAllEventHandler = useCallback(() => {
    confirmAlert({
      title: '全部刪除',
      message: '是否刪除當前物件',
      buttons: [
        {
          label: '確定',
          onClick: async () => {
            canvasDispatch({ type: types.CANVAS_ACTIVATE });
            canvasDispatch({ type: types.CANVAS_ERASE_ALL });
            setTimeout(() => { saveCanvasJSON(); }, 0);
          }
        },
        {
          label: '取消'
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    })

  }, [canvasDispatch, saveCanvasJSON]);

  const SetReaderZoomEventHandler = useCallback(({ type }) => {
    readerDispatch({ type: types.SET_READER_ZOOM_TYPE, zoomType: type });
  }, [readerDispatch]);

  const areaZoomEventHandler = useCallback(({ rect }) => {
    updateAreaZoomInfo(rect)
  }, [updateAreaZoomInfo]);

  const AreaZoomForPageButtonEventHandler = useCallback(({ rect }) => {
    updateAreaZoomForPageButton(rect)
  }, [updateAreaZoomForPageButton]);

  const clickFullWidthEventHandler = useCallback(({ fullWidthMode }) => {
    EventBus.emit({
      event: ReaderToolsEvent.SaveReaderToolSettingsEvent,
      payload: { fullWidthMode }
    })
    readerDispatch({ type: types.SET_FULL_WIDTH_INFO, fullWidthInfo: { mode: fullWidthMode } });
  }, [readerDispatch]);

  const setReaderToolTypeEventHandler = useCallback(({ readerToolType }) => {
    readerDispatch({ type: types.SET_READER_TOOL_TYPE, readerToolType });
  })

  useEffect(() => updateFullWidthInfo(), [updateFullWidthInfo]);
  useEffect(() => updateReaderScaleInfo(), [updateReaderScaleInfo]);

  const setDrawAreaEventHandler = useCallback(({ isDrawArea }) => {
    readerDispatch({ type: types.SET_READER_ZOOM_TYPE, zoomType: ReaderZoomType.OriginZoom });
    readerDispatch({ type: types.TOGGLE_DRAW_AREA, isDrawArea })
  }, [readerDispatch]);

  const SaveAnnotationNameEventHandler = useCallback(async ({ annotationId, name }) => {
    await updateAnnotation(annotationId, { name });
  }, [updateAnnotation])

  const DeleteAnnotationEventHandler = useCallback(async ({ id }) => {
    await deleteAnnotation(id);
  }, [deleteAnnotation])

  const CopyAnnotationEventHandler = useCallback(async ({ id, bookId, name, type, pageIndex, annotations }) => {
    await createAnnotation({
      id: uuid(),
      bookId,
      name,
      type,
      pageIndex,
      annotations: annotations || []
    });
  }, [createAnnotation])

  useEvent([
    { event: ReaderEvent.SetPageIndexEvent },
    { event: ReaderEvent.ClickBookThumbnailEvent },
  ], setPageIndexHandler);

  useEvent({ event: ReaderEvent.ClickPreviousPageEvent }, previousPageEventHandler);
  useEvent({ event: ReaderEvent.ClickNextPageEvent }, nextPageEventHandler);
  useEvent({ event: ReaderEvent.DrawAreaEvent }, setDrawAreaEventHandler);

  useEvent({ event: ReaderEvent.SaveAnnotationNameEvent }, SaveAnnotationNameEventHandler);
  useEvent({ event: ReaderEvent.DeleteAnnotationEvent }, DeleteAnnotationEventHandler);
  useEvent({ event: ReaderEvent.CopyAnnotationEvent }, CopyAnnotationEventHandler);

  useEvent({ event: ReaderToolsEvent.TogglePageModeEvent }, togglePageModeHandler);
  useEvent({ event: ReaderToolsEvent.ClickDragEvent }, clickDragHandler);
  useEvent({ event: ReaderToolsEvent.ChangeStampStatusEvent }, changeStampStatusHandler);
  useEvent({ event: ReaderToolsEvent.ToggleSideBarEvent }, toggleSideBarHandler);
  useEvent({ event: ReaderToolsEvent.TogglePageSearcherEvent }, togglePageSearcherHandler);
  useEvent({ event: ReaderToolsEvent.ClickReaderOptionPanelEvent }, clickReaderOptionPanelHandler);
  useEvent({ event: ReaderToolsEvent.ClickStampPanelEvent }, clickStampPanelHandler);
  useEvent({ event: ReaderToolsEvent.SetObjectPropertyEvent }, setObjectPropertyHandler);
  useEvent({ event: ReaderToolsEvent.ToggleBookmarkEvent }, toggleBookmarkHandler);
  useEvent({ event: ReaderToolsEvent.ClickOpenActivityEvent }, clickOpenActivityEventHandler);
  useEvent({ event: ReaderToolsEvent.OnMarkModeEvent }, OnMarkModeEventHandler);
  useEvent({ event: ReaderToolsEvent.SelectMarkEvent }, SelectMarkEventHandler);
  useEvent({ event: ReaderToolsEvent.SetMarkToolsEnableEvent }, SetMarkToolsEnableEventHandler);
  useEvent({ event: ReaderToolsEvent.SaveMarkEvent }, SaveMarkEventHandler);
  useEvent({ event: ReaderToolsEvent.SetReaderZoomEvent }, SetReaderZoomEventHandler);
  useEvent({ event: ReaderEvent.AreaZoomEvent }, areaZoomEventHandler);
  useEvent({ event: ReaderEvent.AreaZoomForPageButtonEvent }, AreaZoomForPageButtonEventHandler);
  useEvent({ event: ReaderToolsEvent.ClickFullWidthEvent }, clickFullWidthEventHandler);
  useEvent({ event: ReaderToolsEvent.SaveReaderToolSettingsEvent }, SaveReaderToolSettingsEventHandler);
  useEvent([
    { event: ReaderToolsEvent.ClickSelectEvent },
    { event: ReaderToolsEvent.ClickPainterEvent },
    { event: ReaderToolsEvent.ClickEraserEvent },
    { event: ReaderToolsEvent.ClickInsertImageEvent },
    { event: ReaderToolsEvent.ClickInsertTextEvent },
  ], changePainterModeHandler);
  useEvent({ event: PainterEvent.LoadReaderToolSettingsEvent }, LoadReaderToolSettingsHandler);
  useEvent({ event: PainterEvent.ChangeBrushColorEvent }, changeBrushColorHandler);
  useEvent({ event: PainterEvent.ChangeBrushWidthEvent }, changeBrushWidthHandler);
  useEvent({ event: PainterEvent.ChangeBrushTypeEvent }, changeBrushTypeHandler);
  useEvent({ event: PainterEvent.ChangeShapeFillTypeEvent }, changeShapeFillTypeHandler);
  useEvent({ event: PainterEvent.ChangePainterTypeEvent }, changePainterTypeHandler);
  useEvent({ event: ReaderToolsEvent.ChangeReaderToolStyleEvent }, changeReaderToolStyleHandler);
  useEvent({ event: ReaderToolsEvent.ChangeReaderToolDirectionEvent }, changeReaderToolDirectionHandler);
  useEvent({ event: ReaderEvent.RefreshCanvasEvent }, RefreshCanvasHandler);
  useEvent({ event: InteractiveObjectEvent.ClickInteractiveObjectEvent }, clickInteractiveObjectHandler);
  useEvent({ event: InteractiveObjectEvent.ExportInteractiveObjectEvent }, exportInteractiveObjectEventHandler);
  useEvent({ event: ReaderEvent.CreateAndEnterAnnotationEvent }, createAndEnterAnnotationEventHandler);
  useEvent({ event: ReaderEvent.ClickBookmarkEvent }, clickBookmarkEventHandler);
  useEvent({ event: ReaderEvent.GobackCatalogEvent }, gobackCatalogEventHandler);
  useEvent({ event: ReaderToolsEvent.ClickEraseAllEvent }, clickEraseAllEventHandler);
  useEvent({ event: ReaderToolsEvent.SetReaderToolTypeEvent }, setReaderToolTypeEventHandler);
};
