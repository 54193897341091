import React from 'react';
import { timeFormatTommss } from 'util/timeFormater';

import styles from './index.module.scss';



const TimeInfo = ({currentTime,endTime}) =>{

  return (
    <div className={styles.timeInfo}>
      <span>{ timeFormatTommss(currentTime) }</span> / <span>{timeFormatTommss(endTime)}</span>
    </div>
  )
}

export default TimeInfo;