export const convertArrayToMap = (arr, key) => arr.reduce((acc, v) => {
    if (v[key]) {
        acc[v[key]] = v;
    }
    return acc;
}, {});

export const groupByArrayToMap = (arr, key) => {
    return arr.reduce((acc, v) => {
      (acc[v[key]] = acc[v[key]] || []).push(v);
      return acc;
    }, {});
  };
  