import { Stamps } from 'components/Icon';
const {
    Btn1,
    Btn2,
    Btn3,
    Btn4,
    Btn5,
    Btn6,
    Btn8,
    Btn9,
    Btn10,
    Btn11,
    Btn12,
    Btn13,
    Btn14,
    Btn15,
    Btn16,
    Btn17,
    Btn18,
    Btn19,
    Btn20,
    Btn21,
    Btn22,
    Btn23,
    Btn24,
    Btn25,
    Btn26,
    Btn27,
    Btn28,
    Btn29,
    Btn30,
    Btn31,
    Btn32,
    Btn33,
    Btn34,
    Btn35,
    Btn36,
    Btn37,
    Btn38,
    Btn39,
    Btn40,
    Btn41,
    Btn42,
    Btn43,
    Btn44,
    Btn45,
    Btn46,
    Btn47,
    Btn48,
    Btn49,
    Btn50,
    Btn51,
    Btn52,
    Btn53,
    Btn54,
    Btn55,
    Btn56,
    Btn57,
    Btn58,
    Btn59,
    Btn60,
    Btn61,
    Btn62,
    Btn63,
    Btn64,
    Btn65,
    Btn66,
    Btn67,
    Btn68,
    Btn69,
    Btn70,
    Btn71,
    Btn72,
    Btn73,
    Btn74,
    Btn75,
    Btn76,
    Btn77,
    Btn78,
    Btn79,
    Btn80,
    Btn81,
    Btn82,
    Btn83,
    Btn84,
    Btn85,
    Btn86,
    Btn87,
    Btn88,
    Btn89,
    Btn90,
    Btn91,
    Btn92,
    Btn93,
    Btn94,
    Btn95,
    Btn96,
    Btn97,
    Btn98,
    Btn99,
    Btn100,
    Btn101,
    Btn102,
    Btn103,
    Btn104,
    Btn105,
    Btn106,
    Btn107,
    Btn108,
    Btn109,
    Btn110,
    Btn111,
    Btn112,
    Btn113,
    Btn114,
    Btn115,
    Btn116,
    Btn117,
    Btn118,
    Btn119,
    Btn120,
    Btn121,
    Btn122,
    Btn123,
    Btn124,
    Btn125,
    Btn126,
    Btn127,
    Btn12770,
    Btn128,
    Btn12870,
    Btn129,
    Btn130,
    Btn131,
    Btn132,
    Btn133,
    Btn134,
    Btn135,
    Btn136,
    Btn137,
    Btn138,
    Btn139,
    Btn140,
    Btn141,
    Btn142,
    Btn143,
    Btn144,
    Btn145,
    Btn146,
    Btn147,
    Btn148,
    Btn149,
    Btn150,
    Btn151,
    Btn152,
    Btn153,
    Btn154,
    Btn155,
    Btn156,
    Btn157,
    Btn158,
    Btn159,
    Btn160,
    Btn161,
    Btn162,
    Btn163,
    Btn164,
    Btn165,
    Btn166,
    Btn167,
    Btn168,
    Btn169,
    Btn170,
    Btn171,
    Btn172,
    Btn173,
    Btn174,
    Btn175,
    Btn176,
    Btn177,
    Btn178,
    Btn179,
    Btn180,
    Btn181,
    Btn182,
    Btn183,
    Btn184,
    Btn185,
    Btn186,
    Btn187,
    Btn188,
    Btn189,
    Btn190,
    Btn191,
    Btn192,
    Btn193,
    Btn194,
    Btn195,
    Btn196,
    Btn197,
    Btn198,
    Btn199,
    Btn200,
    Btn201,
    Btn202
} = Stamps;

export const StampType = {
    Btn1: 'Btn1',
    Btn2: 'Btn2',
    Btn3: 'Btn3',
    Btn4: 'Btn4',
    Btn5: 'Btn5',
    Btn6: 'Btn6',
    Btn8: 'Btn8',
    Btn9: 'Btn9',
    Btn10: 'Btn10',
    Btn11: 'Btn11',
    Btn12: 'Btn12',
    Btn13: 'Btn13',
    Btn14: 'Btn14',
    Btn15: 'Btn15',
    Btn16: 'Btn16',
    Btn17: 'Btn17',
    Btn18: 'Btn18',
    Btn19: 'Btn19',
    Btn20: 'Btn20',
    Btn21: 'Btn21',
    Btn22: 'Btn22',
    Btn23: 'Btn23',
    Btn24: 'Btn24',
    Btn25: 'Btn25',
    Btn26: 'Btn26',
    Btn27: 'Btn27',
    Btn28: 'Btn28',
    Btn29: 'Btn29',
    Btn30: 'Btn30',
    Btn31: 'Btn31',
    Btn32: 'Btn32',
    Btn33: 'Btn33',
    Btn34: 'Btn34',
    Btn35: 'Btn35',
    Btn36: 'Btn36',
    Btn37: 'Btn37',
    Btn38: 'Btn38',
    Btn39: 'Btn39',
    Btn40: 'Btn40',
    Btn41: 'Btn41',
    Btn42: 'Btn42',
    Btn43: 'Btn43',
    Btn44: 'Btn44',
    Btn45: 'Btn45',
    Btn46: 'Btn46',
    Btn47: 'Btn47',
    Btn48: 'Btn48',
    Btn49: 'Btn49',
    Btn50: 'Btn50',
    Btn51: 'Btn51',
    Btn52: 'Btn52',
    Btn53: 'Btn53',
    Btn54: 'Btn54',
    Btn55: 'Btn55',
    Btn56: 'Btn56',
    Btn57: 'Btn57',
    Btn58: 'Btn58',
    Btn59: 'Btn59',
    Btn60: 'Btn60',
    Btn61: 'Btn61',
    Btn62: 'Btn62',
    Btn63: 'Btn63',
    Btn64: 'Btn64',
    Btn65: 'Btn65',
    Btn66: 'Btn66',
    Btn67: 'Btn67',
    Btn68: 'Btn68',
    Btn69: 'Btn69',
    Btn70: 'Btn70',
    Btn71: 'Btn71',
    Btn72: 'Btn72',
    Btn73: 'Btn73',
    Btn74: 'Btn74',
    Btn75: 'Btn75',
    Btn76: 'Btn76',
    Btn77: 'Btn77',
    Btn78: 'Btn78',
    Btn79: 'Btn79',
    Btn80: 'Btn80',
    Btn81: 'Btn81',
    Btn82: 'Btn82',
    Btn83: 'Btn83',
    Btn84: 'Btn84',
    Btn85: 'Btn85',
    Btn86: 'Btn86',
    Btn87: 'Btn87',
    Btn88: 'Btn88',
    Btn89: 'Btn89',
    Btn90: 'Btn90',
    Btn91: 'Btn91',
    Btn92: 'Btn92',
    Btn93: 'Btn93',
    Btn94: 'Btn94',
    Btn95: 'Btn95',
    Btn96: 'Btn96',
    Btn97: 'Btn97',
    Btn98: 'Btn98',
    Btn99: 'Btn99',
    Btn100: 'Btn100',
    Btn101: 'Btn101',
    Btn102: 'Btn102',
    Btn103: 'Btn103',
    Btn104: 'Btn104',
    Btn105: 'Btn105',
    Btn106: 'Btn106',
    Btn107: 'Btn107',
    Btn108: 'Btn108',
    Btn109: 'Btn109',
    Btn110: 'Btn110',
    Btn111: 'Btn111',
    Btn112: 'Btn112',
    Btn113: 'Btn113',
    Btn114: 'Btn114',
    Btn115: 'Btn115',
    Btn116: 'Btn116',
    Btn117: 'Btn117',
    Btn118: 'Btn118',
    Btn119: 'Btn119',
    Btn120: 'Btn120',
    Btn121: 'Btn121',
    Btn122: 'Btn122',
    Btn123: 'Btn123',
    Btn124: 'Btn124',
    Btn125: 'Btn125',
    Btn126: 'Btn126',
    Btn127: 'Btn127',
    Btn12770: 'Btn12770',
    Btn128: 'Btn128',
    Btn12870: 'Btn12870',
    Btn129: 'Btn129',
    Btn130: 'Btn130',
    Btn131: 'Btn131',
    Btn132: 'Btn132',
    Btn133: 'Btn133',
    Btn134: 'Btn134',
    Btn135: 'Btn135',
    Btn136: 'Btn136',
    Btn137: 'Btn137',
    Btn138: 'Btn138',
    Btn139: 'Btn139',
    Btn140: 'Btn140',
    Btn141: 'Btn141',
    Btn142: 'Btn142',
    Btn143: 'Btn143',
    Btn144: 'Btn144',
    Btn145: 'Btn145',
    Btn146: 'Btn146',
    Btn147: 'Btn147',
    Btn148: 'Btn148',
    Btn149: 'Btn149',
    Btn150: 'Btn150',
    Btn151: 'Btn151',
    Btn152: 'Btn152',
    Btn153: 'Btn153',
    Btn154: 'Btn154',
    Btn155: 'Btn155',
    Btn156: 'Btn156',
    Btn157: 'Btn157',
    Btn158: 'Btn158',
    Btn159: 'Btn159',
    Btn160: 'Btn160',
    Btn161: 'Btn161',
    Btn162: 'Btn162',
    Btn163: 'Btn163',
    Btn164: 'Btn164',
    Btn165: 'Btn165',
    Btn166: 'Btn166',
    Btn167: 'Btn167',
    Btn168: 'Btn168',
    Btn169: 'Btn169',
    Btn170: 'Btn170',
    Btn171: 'Btn171',
    Btn172: 'Btn172',
    Btn173: 'Btn173',
    Btn174: 'Btn174',
    Btn175: 'Btn175',
    Btn176: 'Btn176',
    Btn177: 'Btn177',
    Btn178: 'Btn178',
    Btn179: 'Btn179',
    Btn180: 'Btn180',
    Btn181: 'Btn181',
    Btn182: 'Btn182',
    Btn183: 'Btn183',
    Btn184: 'Btn184',
    Btn185: 'Btn185',
    Btn186: 'Btn186',
    Btn187: 'Btn187',
    Btn188: 'Btn188',
    Btn189: 'Btn189',
    Btn190: 'Btn190',
    Btn191: 'Btn191',
    Btn192: 'Btn192',
    Btn193: 'Btn193',
    Btn194: 'Btn194',
    Btn195: 'Btn195',
    Btn196: 'Btn196',
    Btn197: 'Btn197',
    Btn198: 'Btn198',
    Btn199: 'Btn199',
    Btn200: 'Btn200',
    Btn201: 'Btn201',
    Btn202: 'Btn202'
};

export const StampMap = {
    [StampType.Btn1]: Btn1,
    [StampType.Btn2]: Btn2,
    [StampType.Btn3]: Btn3,
    [StampType.Btn4]: Btn4,
    [StampType.Btn5]: Btn5,
    [StampType.Btn6]: Btn6,
    [StampType.Btn8]: Btn8,
    [StampType.Btn9]: Btn9,
    [StampType.Btn10]: Btn10,
    [StampType.Btn11]: Btn11,
    [StampType.Btn12]: Btn12,
    [StampType.Btn13]: Btn13,
    [StampType.Btn14]: Btn14,
    [StampType.Btn15]: Btn15,
    [StampType.Btn16]: Btn16,
    [StampType.Btn17]: Btn17,
    [StampType.Btn18]: Btn18,
    [StampType.Btn19]: Btn19,
    [StampType.Btn20]: Btn20,
    [StampType.Btn21]: Btn21,
    [StampType.Btn22]: Btn22,
    [StampType.Btn23]: Btn23,
    [StampType.Btn24]: Btn24,
    [StampType.Btn25]: Btn25,
    [StampType.Btn26]: Btn26,
    [StampType.Btn27]: Btn27,
    [StampType.Btn28]: Btn28,
    [StampType.Btn29]: Btn29,
    [StampType.Btn30]: Btn30,
    [StampType.Btn31]: Btn31,
    [StampType.Btn32]: Btn32,
    [StampType.Btn33]: Btn33,
    [StampType.Btn34]: Btn34,
    [StampType.Btn35]: Btn35,
    [StampType.Btn36]: Btn36,
    [StampType.Btn37]: Btn37,
    [StampType.Btn38]: Btn38,
    [StampType.Btn39]: Btn39,
    [StampType.Btn40]: Btn40,
    [StampType.Btn41]: Btn41,
    [StampType.Btn42]: Btn42,
    [StampType.Btn43]: Btn43,
    [StampType.Btn44]: Btn44,
    [StampType.Btn45]: Btn45,
    [StampType.Btn46]: Btn46,
    [StampType.Btn47]: Btn47,
    [StampType.Btn48]: Btn48,
    [StampType.Btn49]: Btn49,
    [StampType.Btn50]: Btn50,
    [StampType.Btn51]: Btn51,
    [StampType.Btn52]: Btn52,
    [StampType.Btn53]: Btn53,
    [StampType.Btn54]: Btn54,
    [StampType.Btn55]: Btn55,
    [StampType.Btn56]: Btn56,
    [StampType.Btn57]: Btn57,
    [StampType.Btn58]: Btn58,
    [StampType.Btn59]: Btn59,
    [StampType.Btn60]: Btn60,
    [StampType.Btn61]: Btn61,
    [StampType.Btn62]: Btn62,
    [StampType.Btn63]: Btn63,
    [StampType.Btn64]: Btn64,
    [StampType.Btn65]: Btn65,
    [StampType.Btn66]: Btn66,
    [StampType.Btn67]: Btn67,
    [StampType.Btn68]: Btn68,
    [StampType.Btn69]: Btn69,
    [StampType.Btn70]: Btn70,
    [StampType.Btn71]: Btn71,
    [StampType.Btn72]: Btn72,
    [StampType.Btn73]: Btn73,
    [StampType.Btn74]: Btn74,
    [StampType.Btn75]: Btn75,
    [StampType.Btn76]: Btn76,
    [StampType.Btn77]: Btn77,
    [StampType.Btn78]: Btn78,
    [StampType.Btn79]: Btn79,
    [StampType.Btn80]: Btn80,
    [StampType.Btn81]: Btn81,
    [StampType.Btn82]: Btn82,
    [StampType.Btn83]: Btn83,
    [StampType.Btn84]: Btn84,
    [StampType.Btn85]: Btn85,
    [StampType.Btn86]: Btn86,
    [StampType.Btn87]: Btn87,
    [StampType.Btn88]: Btn88,
    [StampType.Btn89]: Btn89,
    [StampType.Btn90]: Btn90,
    [StampType.Btn91]: Btn91,
    [StampType.Btn92]: Btn92,
    [StampType.Btn93]: Btn93,
    [StampType.Btn94]: Btn94,
    [StampType.Btn95]: Btn95,
    [StampType.Btn96]: Btn96,
    [StampType.Btn97]: Btn97,
    [StampType.Btn98]: Btn98,
    [StampType.Btn99]: Btn99,
    [StampType.Btn100]: Btn100,
    [StampType.Btn101]: Btn101,
    [StampType.Btn102]: Btn102,
    [StampType.Btn103]: Btn103,
    [StampType.Btn104]: Btn104,
    [StampType.Btn105]: Btn105,
    [StampType.Btn106]: Btn106,
    [StampType.Btn107]: Btn107,
    [StampType.Btn108]: Btn108,
    [StampType.Btn109]: Btn109,
    [StampType.Btn110]: Btn110,
    [StampType.Btn111]: Btn111,
    [StampType.Btn112]: Btn112,
    [StampType.Btn113]: Btn113,
    [StampType.Btn114]: Btn114,
    [StampType.Btn115]: Btn115,
    [StampType.Btn116]: Btn116,
    [StampType.Btn117]: Btn117,
    [StampType.Btn118]: Btn118,
    [StampType.Btn119]: Btn119,
    [StampType.Btn120]: Btn120,
    [StampType.Btn121]: Btn121,
    [StampType.Btn122]: Btn122,
    [StampType.Btn123]: Btn123,
    [StampType.Btn124]: Btn124,
    [StampType.Btn125]: Btn125,
    [StampType.Btn126]: Btn126,
    [StampType.Btn127]: Btn127,
    [StampType.Btn12770]: Btn12770,
    [StampType.Btn128]: Btn128,
    [StampType.Btn12870]: Btn12870,
    [StampType.Btn129]: Btn129,
    [StampType.Btn130]: Btn130,
    [StampType.Btn131]: Btn131,
    [StampType.Btn132]: Btn132,
    [StampType.Btn133]: Btn133,
    [StampType.Btn134]: Btn134,
    [StampType.Btn135]: Btn135,
    [StampType.Btn136]: Btn136,
    [StampType.Btn137]: Btn137,
    [StampType.Btn138]: Btn138,
    [StampType.Btn139]: Btn139,
    [StampType.Btn140]: Btn140,
    [StampType.Btn141]: Btn141,
    [StampType.Btn142]: Btn142,
    [StampType.Btn143]: Btn143,
    [StampType.Btn144]: Btn144,
    [StampType.Btn145]: Btn145,
    [StampType.Btn146]: Btn146,
    [StampType.Btn147]: Btn147,
    [StampType.Btn148]: Btn148,
    [StampType.Btn149]: Btn149,
    [StampType.Btn150]: Btn150,
    [StampType.Btn151]: Btn151,
    [StampType.Btn152]: Btn152,
    [StampType.Btn153]: Btn153,
    [StampType.Btn154]: Btn154,
    [StampType.Btn155]: Btn155,
    [StampType.Btn156]: Btn156,
    [StampType.Btn157]: Btn157,
    [StampType.Btn158]: Btn158,
    [StampType.Btn159]: Btn159,
    [StampType.Btn160]: Btn160,
    [StampType.Btn161]: Btn161,
    [StampType.Btn162]: Btn162,
    [StampType.Btn163]: Btn163,
    [StampType.Btn164]: Btn164,
    [StampType.Btn165]: Btn165,
    [StampType.Btn166]: Btn166,
    [StampType.Btn167]: Btn167,
    [StampType.Btn168]: Btn168,
    [StampType.Btn169]: Btn169,
    [StampType.Btn170]: Btn170,
    [StampType.Btn171]: Btn171,
    [StampType.Btn172]: Btn172,
    [StampType.Btn173]: Btn173,
    [StampType.Btn174]: Btn174,
    [StampType.Btn175]: Btn175,
    [StampType.Btn176]: Btn176,
    [StampType.Btn177]: Btn177,
    [StampType.Btn178]: Btn178,
    [StampType.Btn179]: Btn179,
    [StampType.Btn180]: Btn180,
    [StampType.Btn181]: Btn181,
    [StampType.Btn182]: Btn182,
    [StampType.Btn183]: Btn183,
    [StampType.Btn184]: Btn184,
    [StampType.Btn185]: Btn185,
    [StampType.Btn186]: Btn186,
    [StampType.Btn187]: Btn187,
    [StampType.Btn188]: Btn188,
    [StampType.Btn189]: Btn189,
    [StampType.Btn190]: Btn190,
    [StampType.Btn191]: Btn191,
    [StampType.Btn192]: Btn192,
    [StampType.Btn193]: Btn193,
    [StampType.Btn194]: Btn194,
    [StampType.Btn195]: Btn195,
    [StampType.Btn196]: Btn196,
    [StampType.Btn197]: Btn197,
    [StampType.Btn198]: Btn198,
    [StampType.Btn199]: Btn199,
    [StampType.Btn200]: Btn200,
    [StampType.Btn201]: Btn201,
    [StampType.Btn202]: Btn202
}
