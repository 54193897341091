import { useCallback } from 'react';

export const useControlDisplayCommand = () => {
    const execute = useCallback(async ({ json, pageIndex, isDoublePageMode, interactiveObjectState, setInteractiveObjectState }) => {
        let isOpen = false;
        const baseIndex = pageIndex - pageIndex % 2;
        let foundItem = null;

        for (let item of interactiveObjectState.state) {
            if (item.pageIndex.includes(pageIndex)) {
                foundItem = item;
                break;
            }
        }

        //如果Display是否存在
        if (!foundItem) {
            //創建新的display
            foundItem = {
                pageIndex: isDoublePageMode ? [baseIndex, baseIndex + 1] : [pageIndex],
                display: json.controlObject.split(",").map(objId => ({ id: objId, opacity: json.opacity || 1 }))
            };
            interactiveObjectState.state.push(foundItem);
        } else {
            //撈出不存在Display裡面的ID
            foundItem.display = json.controlObject.split(",")
                .filter((objId) => !foundItem.display || !foundItem.display.map(display => display.id).includes(objId))
                .map(objId => ({ id: objId, opacity: json.opacity || 1 }))
                .concat(foundItem.display || []);
        }

        //判斷暫存陣列裡面是否有opacity已顯示
        const controlledObjects = foundItem.display.filter(obj => json.controlObject.split(",").includes(obj.id));
        for (let display of controlledObjects) {
            if (display.opacity === 0) {
                isOpen = true;
                break;
            }
        }

        //isOpen如果true，opacity=0，如果是false就opacity=1
        for (let display of controlledObjects) {
            display.opacity = isOpen ? 1 : 0
        }

        setInteractiveObjectState({ ...interactiveObjectState, version: interactiveObjectState.version ? interactiveObjectState.version + 1 : 1 })
    }, []);

    return { execute };
};
